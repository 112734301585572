export const USERS = {
  INDEX: "/usuarios",
  LOGIN: "/usuarios/entrar",
  REGISTRATION: "/usuarios/cadastro/criar",
  HOME: "/usuarios/home",
  SHOW: "/usuarios/ver/:id",
  ESSENTIAL_FIELDS: "/usuarios/dados-basicos",
  WIZARD: "/usuarios/preencher-perfil",
  SETTINGS: "/usuarios/configuracoes",
  CANDIDATURES: {
    INDEX: "/usuarios/candidaturas",
    SHOW: "/usuarios/candidaturas/:id",
  },
  CERTIFICATES: {
    INDEX: "/usuarios/certificados",
  }
};

export const ACADEMY = {
  INDEX: "/academy",
  COURSE: {
    INDEX: "/academy/cursos",
    SHOW: "/academy/cursos/:id",
    OWNED: "/academy/cursos/meus",
    WATCH: "/academy/cursos/:id/assistir/:item_id/:matriculation_id",
    FINISHED: "/academy/cursos/:id/concluido/:matriculation_id",
    CERTIFICATE: "/academy/cursos/certificado/:id",
  },
  STUDY_TRACK: {
    INDEX: "/academy/trilhas-de-estudos",
    SHOW: "/academy/trilha-de-estudos/:id",
  }
};

export const TESTS = {
  INDEX: "/testes",
  START: "/testes/:id/comecar/:user_info_test_id",
  TAKING: "/testes/:id/fazendo/:user_info_test_id",
  FINISHED: "/testes/teste-finalizado",
};


export const COMPANIES = {
  INDEX: "/empresas",
  NEW: "empresas/cadastro/criar",
  SHOW: "/empresas/ver/:id",
  POST: "/empresas/cadastro",
};

export const BENEFITS = {
  INDEX: "/clube-de-beneficios",
};

export const CAREER_MENTORS = {
  INDEX: "/mentores-de-carreira",
};

export const ATTENDEES = {
  INDEX: "/agendas/convites",
  SHOW: "/agendas/convites/:id/*",
  ABOUT: "/agendas/convites/:id/sobre",
  CONFIRM: "/agendas/convites/:id/confirmar",
  DECLINE: "/agendas/convites/:id/recusar",
  RESCHEDULE: "/agendas/convites/:id/reagendar",
};

export const PERSONAL_DOCUMENTS = {
  INDEX: "/documentos-pessoais",
};

export const CATEGORIES = {
  INDEX: "/categoria-de-documentos",
};

export const CONVENTIONS = {
  PARTICIPATE: "/eventos/:uuid/inscricao",
};

export const INTERNSHIP = {
  ADMISSION: {
    LOGIN: "/estagios/admissao/formulario/:uuid/login",
    FORM_ANSWER: "/estagios/admissao/formulario/:uuid/responder",
  },
  UPDATE: {
    LOGIN: "/estagios/atualizacao/formulario/:uuid/login",
    FORM_ANSWER: "/estagios/atualizacao/formulario/:uuid/:user_info_id/responder",
  },
  COMPANY_EVALUATION: {
    INDEX: "/estagios/avaliar-empresa/avaliacoes",
    SHOW: "/estagios/avaliar-empresa/avaliacoes/:uuid",
    EDIT: "/estagios/avaliar-empresa/avaliacoes/:uuid/editar",
  },
  ENVELOPES: {
    OWNED: "/envelopes/minhas",
    SHOW: "/envelopes/:id"
  },
  STELLANTIS: {
    ADMISSION: {
      LOGIN: "/dados/formulario/:uuid/login",
      FORM_ANSWER: "/dados/formulario/:uuid/responder",
    }
  }
};

export const ATS = {
  QUESTIONNAIRE: {
    FORM_ANSWER: {
      EDIT: "/ats/questionarios/respostas/:id/editar",
    },
  },
  JOBS: {
    INDEX: "/vagas",
    SHOW: "/vagas/:id",
    CANDIDATURES: {
      FAST_CREATE: "/vagas/:id/candidaturas/formulario"
    }
  },
};

export const INTERNSHIP_PROGRAMS = {
  SHOW: "/programa_estagios/:id",
  COMPANY: {
    SHOW: "/programa_estagio/:id"
  }
}

export const JOB_BOARD = {
  COMPANIES: {
    LOGIN: "/ies/:id/empresas/entrar",
    REGISTRATION: "/ies/:id/empresas/cadastro/criar",
    NEW: "/ies/:display_name/empresas/cadastro/criar",
    POST: "/ies/:display_name/empresas/cadastro"
  }
}

export const ADMIN = {
  ENVELOPES: {
    SHOW: "admin/envelopes/:id"
  }
}

export const API = { 
  FEATURES: "/api/features.json",
}

const PATHS = {
  USERS,
  ACADEMY,
  TESTS,
  COMPANIES,
  BENEFITS,
  CAREER_MENTORS,
  ATTENDEES,
  PERSONAL_DOCUMENTS,
  CATEGORIES,
  CONVENTIONS,
  INTERNSHIP,
  ATS,
  INTERNSHIP_PROGRAMS,
  ADMIN,
  JOB_BOARD,
  API
};

export default PATHS;