import React, { useState, useEffect } from "react";
import styled from "styled-components";
import StudyDay from "./StudyDay";
import { SelectInput, HiddenField } from "components/form";
import _ from "lodash";

const StudyScheduleDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-gap: 20px;
  padding: 15px;
`;

const Paragraph = styled.p`
  padding-left: 15px;
`;

const DEFAULT_VIEW_TYPE = "default"

const defaultArray = [{key: new Date().getTime(), available: true, day_of_week: 0}]
const customArray = Array.from({ length: 7 }, (_, i) => ({key: new Date().getTime() + (i + 1), available: false, day_of_week: i + 1}))

function StudySchedule(props) {
  const [type, setType] = useState(null);
  const [schedule, setSchedule] = useState(null);

  const prefix = props.prefix || "study_schedule_attributes";

  const setDaysByType = () => {
    if(type != schedule.schedule_type){
      return type == DEFAULT_VIEW_TYPE ? defaultArray : customArray
    } else {
      return schedule?.days_attributes
    }
  }

  const renderDays = () => {
    let days = setDaysByType() || [];

    return days.map((day, index) => {
      let dayPrefix = {
        id: `${prefix}_days_attributes_${index}`,
        name: `${prefix}[days_attributes][${index}]`,
      };

      return <StudyDay {...props} day={day} key={day?.id || day?.key} prefix={dayPrefix} index={index} />;
    })
  }

  useEffect(() => {
    setSchedule(props.getValues?.(prefix) || {});
  }, [props.getValues]);

  useEffect(() => {
    if(!_.isEmpty(schedule)) setType(schedule.schedule_type);
  }, [schedule])

  return (
    <>
      <Paragraph>
        Informe seu período de aulas na instituição de ensino em que você
        estuda:
      </Paragraph>
      <ScheduleType {...props} schedule={schedule} prefix={prefix} handleChange={setType} />
      {schedule?.id && (
        <HiddenField {...props} name={`${prefix}[id]`} id={`${prefix}_id`} />
      )}
      <StudyScheduleDiv className="study-schedule-days">{type && renderDays()}</StudyScheduleDiv>
    </>
  );
}

function ScheduleType(props) {
  const collection = [
    { value: "default", label: "De segunda a sexta" },
    { value: "custom", label: "Personalizado" },
  ];

  const prefix = {
    id: `${props.prefix}_schedule_type`,
    name: `${props.prefix}[schedule_type]`
  }

  const handleChange = (value) => {
    props.handleChange(value);
    props.setValue?.(prefix.name, value);
    props.unregister?.(`${props.prefix}[days_attributes]`)
  }

  return (
    <div className="study-schedule-type" style={{ padding: "0 15px" }}>
      <SelectInput
        {...props}
        placeholder="Selecione o tipo de cronograma..."
        name={prefix.name}
        id={prefix.id}
        handleChange={handleChange}
        collection={collection}
        label="Tipo de Cronograma"
      />
    </div>
  );
}

export default StudySchedule;
