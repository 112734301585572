import React, { useState, useEffect } from "react";
import { SearchableSelect } from "components/form";
import { getCollection } from "utils/getCollection";

export default (props) => {
  const [cities, setCities] = useState([]);

  const prefix = props.prefix || {
    name: "address_attributes[city_id]",
    id: "address_attributes_city_id",
  };
  
  let state_id = props.getValues?.("address_attributes[state_id]");

  useEffect(() => {
    if(state_id) getCollection(`/selects/${state_id}/cities_react.json`, setCities);
  }, [state_id])

  return (
    <div className="form-group">
      <SearchableSelect
        {...props}
        placeholder="Selecione a cidade..."
        name={prefix.name}
        id={prefix.id}
        collection={cities}
        label={props.label || "Cidade"}
      />
    </div>
  );
}