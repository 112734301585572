import React from "react";
import { useEnvelope } from "../context";
import styled from "styled-components";
import { connect } from "react-redux";

const Link = styled.a`
  display: flex;
  gap: 6px;
`;

function mapStateToProps(state) {
  const { userType } = state.session;

  return { userType };
}

export default connect(mapStateToProps)((props) => {
  const { envelope } = useEnvelope();
  const { userType } = props;

  if(envelope.download == null)
    return;
    
  if (envelope.can_show_hybrid_download) {
    return (
      <p style={{margin: "25px 0px;"}}>
        <b>Documento assinado pela empresa e estagiário:</b>
        <Link href={envelope.download}>
          <i className="material-icons-outlined">file_download</i>
          {envelope.type_name}
        </Link>
      </p>
    )
  }

  if (envelope.contract_type !== "analogic" || userType !== "CompanyManager") {
    return;
  }


    return (
      <p style={{margin: "25px 0px;"}}>
          <b>Documento base:</b>
          <Link href={envelope.download}>
            <i className="material-icons-outlined">file_download</i>
            {envelope.type_name}
          </Link>
      </p>
    );
});
