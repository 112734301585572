import React from "react";
import { RadioButtons } from "components/form";
import _ from "lodash";

export default (props) => {
  const prefix = props.prefix || {
    name: "disability",
    id: "disability",
  };

  const options = [
    { value: "true", label: "Sim, sou PCD" },
    { value: "false", label: "Não" },
  ];

  const defaultValue = _.get(props.getValues?.(), prefix.name)?.toString() || props.defaultValue?.toString();
  
  return (
    <RadioButtons
      {...props}
      id={prefix.id}
      name={prefix.name}
      options={options}
      label={props.label || "Pessoa com deficiência (PCD)?"}
      defaultValue={defaultValue}
    />
  );
}