import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { USERS, ATS, ACADEMY } from 'navigation/ROUTES';

const NavMenu = styled.nav`
  height: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: span 2;
  align-content: center;

  ul {
    list-style-type: none;
    margin-bottom: 0;

    li {
      padding: 5px;
      margin-right: 20px;
    }

    li a {
      color: white;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 767px) {
    & {
      display: none;
    }
  }
`;

const LeftMenu = styled.ul`
  display: flex;
  align-content: center;
  padding-inline-start: 20px;

  li {
    display: flex;
    height: 100%;
    align-items: center;
  }

  button {
    color: white;
    border: none;
    background: none;
    outline: none;
  }
`;

const RightMenu = styled.ul`
  display: inline-flex;
  justify-self: end;

  li {
    display: flex;
  }

  li button {
    border: none !important;
    background: none;
    outline: none;
  }

  li img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
`;

const Nav = ({toggleDrawer, isLogged, user}) => (
  <NavMenu>
    <LeftMenu>
      <li>
        {isLogged && (
          <button onClick={toggleDrawer}>
            <i className="material-icons">apps</i>
          </button>
        )}
      </li>
      {!user?.intern && (
        <li>
          <Link to={ATS.JOBS.INDEX}>Vagas</Link>
        </li>
      )}
      <li>
        <a href="https://carreiras.walljobs.com.br/" target="_blank">
          Blog
        </a>
      </li>
      <li>
        <a href={ACADEMY.COURSE.INDEX} target="_blank">
          Academy
        </a>
      </li>
    </LeftMenu>

    <RightMenu>
      <li>
        {isLogged ? (
          <button onClick={toggleDrawer}>
            <img src={user?.avatar} alt={`Avatar ${user?.first_name}`} />
          </button>
        ) : (
          <Link to={USERS.LOGIN}>
            <b>Entrar</b>
          </Link>
        )}
      </li>
    </RightMenu>
  </NavMenu>
);

function mapStateToProps(state) {
  const { isLogged } = state.session;
  const user = state.session?.attributes;

  return { isLogged, user };
}

export default connect(mapStateToProps)(Nav);