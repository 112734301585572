import React from "react";
import { SelectInput } from "components/form";
import { useStaticCollection } from "utils/hooks";

function Nationality(props) {
  const prefix = props.prefix || {
    id: "nationality_id",
    name: "nationality_id",
  };

  const collection = useStaticCollection("/selects/nationalities.json");

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione sua nacionalidade..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Nacionalidade"}
      />
    </div>
  );
}

export default Nationality;
