import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import api from "services/api";
import { useParams } from "react-router-dom";
import { useEnvelope } from "../../../../context";
import { useForm } from "react-hook-form";

import RejectionCause from "./RejectionCause";

export default (props) => {
  const { openModal, setOpenModal, action } = props;
  const { event: { meta: { reject_by } } } = props;

  const { id } = useParams();
  const { mutate } = useEnvelope();

  const {
    control,
    handleSubmit,
  } = useForm({
    mode: "onBlur",
  });

  const updateEnvelope = (collectedData) => {
    const params = { rejection_origin: reject_by, ...collectedData };
    const payload = {
      event: action,
      status: { status: "declined", ...params },
    };

    api
      .patch(`/v1/internship/envelopes/${id}`, payload)
      .then(() => {
        swal(
          "Ação concluída!",
          "O contrato foi rejeitado com sucesso.",
          "success"
        );
        mutate();
      })
      .catch(() => {
        swal(
          "Ops!",
          "Não foi possível rejeitar o contrato no momento... tente novamente, por favor!",
          "error"
        );
      });
  };

  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(!openModal)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ fontSize: "1.75rem", fontWeight: "bold" }}>
        Motivo da rejeição
        <IconButton sx={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p>
          Para confirmar a rejeição desse contrato, por favor informe o motivo
          pelo qual ele será rejeitado.
        </p>
        <form onSubmit={handleSubmit(updateEnvelope)} className="reject-action">
          <RejectionCause control={control} reject_by={reject_by} />
          <button
            className="button button-primary"
            style={{ float: "right" }}
            type="submit"
          >
            Confirmar
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
