import React from 'react'
import {UncontrolledInput as Input} from 'components/form'

function Matriculation(props) {
  const prefix = props.prefix || {
    id: "matriculation",
    name: "matriculation",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe o número de sua matrícula..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Número de matrícula"}
      />
    </div>
  )
}
export default Matriculation;