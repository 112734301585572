import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import _ from "lodash";

function SelectInput(props) {
  const [value, setValue] = useState("");

  const validation = { ...{ required: props.required }, ...props.validation };

  const messages = {
    ...{ required: "Não pode ficar em branco" },
    ...props.messages,
  };

  const error = _.get(props.errors, props.name);

  function setDefaultValue() {
    return (
      props.defaultValue?.value ||
      props.defaultValue ||
      _.get(props.getValues?.(), props.name) ||
      ""
    );
  }

  useEffect(() => {
    if (!_.isEmpty(props.collection)) setValue(setDefaultValue());
  }, [props.collection, props.defaultValue, props.control?._fields]);

  useEffect(() => {
    if (value) props.handleChange?.(value);
  }, [value]);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={validation}
      defaultValue={value}
      render={({ field: { onChange, ref } }) => (
        <TextField
          select
          variant="standard"
          id={props.id}
          slotProps={{
            htmlInput: { className: props.className }
          }}
          autoComplete="off"
          autoFocus={props.autoFocus}
          label={props.label}
          placeholder={props.placeholder}
          required={props.required}
          onChange={(event) => {
            onChange(event.target.value?.value || event.target.value);
            setValue(event.target.value);
          }}
          inputRef={ref}
          error={!_.isEmpty(error)}
          helperText={
            error?.message || messages[error?.type] || props.helperText
          }
          value={value}
        >
          {props.collection.map((option) => {
            let value = option.value;
            
            if(value === null || value === undefined){
              value = option.id;
            }

            return (
              <MenuItem
                key={value}
                value={value}
              >
                {option.label || option.name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
}

export default SelectInput;
