import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.text.primary};
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  a:hover {
    color: inherit;
    text-decoration: none;
  }


  ${({theme}) => theme.palette.mode === "dark" && `
    .button, .swal-button{
      background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15))!important;
    }
  `}

  .swal-overlay{
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
  }

  .swal-modal{
    background-color: ${({ theme }) => theme.palette.background.default};
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  }

  .swal-title{
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .swal-text{
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .swal-icon--success:after, .swal-icon--success:before, .swal-icon--success__hide-corners{
    background-color:transparent;
  }

  .swal-button:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    background-image: ${({theme}) => theme.palette.mode === "light" ? "linear-gradient(rgba(0,0,0,.06), rgba(0,0,0,.06))" : "linear-gradient(rgba(0,0,0,.21), rgba(0,0,0,.21))"};
  }

  .swal-button--confirm, .swal-button--confirm:not([disabled]):hover{
    background-color:#51F27E;
  }

  .swal-button--danger, .swal-button--danger:not([disabled]):hover{
    background-color: ${({theme}) => theme.palette.error.main};
  }

  .swal-button--cancel, .swal-button--cancel:not([disabled]):hover{
    background-color: ${({theme}) => theme.palette.mode === "light" ? "#D6D6D6" : "#2B3140"};
    color: ${({theme}) => theme.palette.text.primary};
  }

  span.label{
    font-size: 12px;
    font-weight: bolder;
    color: white;
    text-align: center;
    padding: 2px 6px;
    border-radius: 3px;
    margin-bottom: 0;
    background-color: #00e7c0;
    display: block;
    width: fit-content;
    height: fit-content;

    ${({theme}) => theme.palette.mode === "dark" && `{
      background-image: linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.2));
    }`}
  }
  
`;
