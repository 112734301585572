import axios from "axios";
import swal from "sweetalert";

const zipcodeSearch = async (zipcode) => {
  const api = axios.create();
  const address = await api
    .get(`https://viacep.com.br/ws/${zipcode}/json/`)
    .then((res) => {
      let address = res.data;
      if (!address.erro) {
        return {
          street: address.logradouro,
          neighborhood: address.bairro,
          state_id: address.uf,
          city_id: address.localidade,
          country_id: "Brasil",
        };
      } else {
        swal("Ops!", "CEP não encontrado...", "warning");
        return {}
      }
    })
    .catch(() => {
      swal(
        "Ops!",
        "Não foi possível completar essa requisição. Por favor, tente novamente!",
        "warning"
      );
      return {}
    });
  
  return address;
};

export default zipcodeSearch;
