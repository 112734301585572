import React from "react";
import styled from "styled-components";
import { Paper } from "@mui/material";

const ContentBox = styled(Paper)`
  &.content-box {
    margin: 0 auto 20px auto;
    padding: 40px;

    header.content-box-header {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    div.content-box-header-infos {
      width: 100%;
      display: inline-flex;
      margin-bottom: 50px;

      img.logo {
        width: 140px;
        height: 140px;
        margin-right: 25px;
      }

      h2 {
        margin-bottom: 5px;
      }

      p {
        color: #959aad;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &.content-box {
      margin: 20px 0 0 0;
      border: none;
      border-radius: 0;
      box-shadow: none;
      padding: 0;

      div.content-box-header-infos {
        margin-bottom: 0px;

        img.logo {
          width: 45px;
          height: 45px;
        }

        p {
          color: #959aad;
          margin-bottom: 8px;
        }
      }
    }
  }
`;

export default React.memo(({ children }) => (
  <ContentBox className="content-box" elevation={2}>{children}</ContentBox>
));
