import React from "react";
import { FormControlLabel, Switch } from "@mui/material";

export default (props) => {
  return (
    <FormControlLabel
      control={<Switch {...props} />}
      label={props.label}
      labelPlacement={props.labelPlacement}
      slotProps={{ typography: { variant: "subtitle2" } }}
    />
  );
};
