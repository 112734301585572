import React from "react";
import { useNestedAttributes, useStaticCollection } from "utils/hooks";
import SoftwareAttributes from "./SoftwareAttributes";

function Software(props) {
  const softwares = useStaticCollection("/selects/softwares.json");

  const prefix = props.prefix || "user_softwares_attributes";

  const { list, addItem, destroyItem } = useNestedAttributes({...props, prefix})

  const renderSoftwares = () => {
    return list?.map((item, index) => {
      const properties = {
        ...props,
        prefix,
        item,
        index,
        softwares,
        destroyItem,
      };
      return <SoftwareAttributes {...properties} key={item?.id || item?.key} />;
    });
  };

  return (
    <div>
      {renderSoftwares()}
      <div className="add-button">
        <button type="button" onClick={addItem}>
          adicionar <i className="fa fa-plus"></i>
        </button>
      </div>
    </div>
  );
}

export default Software;
