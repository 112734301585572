import colors from "styles/colors.js";

export const palette = (mode) => ({
  mode,
  primary: {
    main: colors.punch[500],
    50: colors.punch[50],
    100: colors.punch[100],
    200: colors.punch[200],
    300: colors.punch[300],
    400: colors.punch[400],
    500: colors.punch[500],
    600: colors.punch[600],
    700: colors.punch[700],
    800: colors.punch[800],
    900: colors.punch[900],
    950: colors.punch[950]
  },
  secondary: {
    main: colors.dodgeBlue[500],
    50: colors.dodgeBlue[50],
    100: colors.dodgeBlue[100],
    200: colors.dodgeBlue[200],
    300: colors.dodgeBlue[300],
    400: colors.dodgeBlue[400],
    500: colors.dodgeBlue[500],
    600: colors.dodgeBlue[600],
    700: colors.dodgeBlue[700],
    800: colors.dodgeBlue[800],
    900: colors.dodgeBlue[900],
    950: colors.dodgeBlue[950]
  },
  default: {
    main: colors.brightGray[700],
    50: colors.brightGray[50],
    100: colors.brightGray[100],
    200: colors.brightGray[200],
    300: colors.brightGray[300],
    400: colors.brightGray[400],
    500: colors.brightGray[500],
    600: colors.brightGray[600],
    700: colors.brightGray[700],
    800: colors.brightGray[800],
    900: colors.brightGray[900],
    950: colors.brightGray[950]
  },
  error: {
    main: colors.redRibbon[700],
    50: colors.redRibbon[50],
    100: colors.redRibbon[100],
    200: colors.redRibbon[200],
    300: colors.redRibbon[300],
    400: colors.redRibbon[400],
    500: colors.redRibbon[500],
    600: colors.redRibbon[600],
    700: colors.redRibbon[700],
    800: colors.redRibbon[800],
    900: colors.redRibbon[900],
    950: colors.redRibbon[950]
  },
  warning: {
    main: colors.amber[600],
    50: colors.amber[50],
    100: colors.amber[100],
    200: colors.amber[200],
    300: colors.amber[300],
    400: colors.amber[400],
    500: colors.amber[500],
    600: colors.amber[600],
    700: colors.amber[700],
    800: colors.amber[800],
    900: colors.amber[900],
    950: colors.amber[950]
  },
  info: {
    main: colors.purpleHeart[600],
    50: colors.purpleHeart[50],
    100: colors.purpleHeart[100],
    200: colors.purpleHeart[200],
    300: colors.purpleHeart[300],
    400: colors.purpleHeart[400],
    500: colors.purpleHeart[500],
    600: colors.purpleHeart[600],
    700: colors.purpleHeart[700],
    800: colors.purpleHeart[800],
    900: colors.purpleHeart[900],
    950: colors.purpleHeart[950]
  },
  success: {
    main: colors.apple[600],
    50: colors.apple[50],
    100: colors.apple[100],
    200: colors.apple[200],
    300: colors.apple[300],
    400: colors.apple[400],
    500: colors.apple[500],
    600: colors.apple[600],
    700: colors.apple[700],
    800: colors.apple[800],
    900: colors.apple[900],
    950: colors.apple[950]
  },
  text: {
    primary: colors.brightGray[600],
    secondary: "#959aad",
  },
  background: {
    default: "#f0f0f0",
    paper: "#ffffff",
  },
  // ...(mode === "dark"
  //   ? {
  //       // primary: {
  //       //   main: "#8D69E0",
  //       // },
  //       text: {
  //         primary: "#f0f0f0",
  //         secondary: "#959aad",
  //       },
  //       background: {
  //         default: "#333b4d",
  //         paper: "#3B4459",
  //       },
  //     }
  //   : {
  //       // primary: {
  //       //   main: "#7338c4",
  //       // },
  //       text: {
  //         primary: colors.brightGray[600],
  //         secondary: "#959aad",
  //       },
  //       background: {
  //         default: "#f0f0f0",
  //         paper: "#ffffff",
  //       },
  //     }),
});
