import React, { useEffect, useState } from "react";
import IdAndDestroyFields from "components/users/fields/shared/IdAndDestroyFields";
import Cid from "./Cid";
import Description from "./Description";
import DisabilityType from "./DisabilityType";

function DisabilityAttributes(props) {
  const { item, index } = props;

  const [destroy, setDestroy] = useState(false);

  const removeFromList = () => {
    setDestroy(true);
  };

  useEffect(() => {
    if (destroy) props.destroyItem(item.id, index);
  }, [destroy]);

  return (
    <div className="row">
      <div className="col-md-12 disability-title">
        <h4>Deficiência</h4>
      </div>
      <DisabilityType {...props} />
      <Description {...props} />
      <Cid {...props} />
      <ButtonDestroy removeFromList={removeFromList} {...props} />
      <IdAndDestroyFields {...props} destroy={destroy} />
      <CidLinks {...props} />
    </div>
  );
}

function ButtonDestroy(props) {
  const { removeFromList } = props;

  return (
    <div className="col-md-1 col-button">
      <button type="button" className="button-delete" onClick={removeFromList}>
        <i className="fa fa-trash"></i>
      </button>
    </div>
  );
}

function CidLinks(props) {
  const { item } = props;

  const renderLink = () => {
    const lastCid = item.last_cid;

    if (!lastCid) {
      return <p>Nenhum CID disponível.</p>;
    }

    const { src, filename } = lastCid;
    return (
      <p>
        <a href={src}>{filename}</a>
      </p>
    );
  };

  return item.id && (
    <div className="col-md-12">
      <h4>CID atual:</h4>
      {renderLink()}
    </div>
  )
}

export default React.memo(DisabilityAttributes);
