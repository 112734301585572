import React from "react";
import PropTypes from "prop-types";

const noop = () => {
  // no operation (do nothing real quick)
};

const textStyles = {
  color: "#222",
  fontSize: "14px",
  fontWeight: "400",
  fontFamily: '"Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif'
}

const customStyles = {
  input: (base) => ({
    ...base,
    ...textStyles
  }),
  placeholder: (base) => ({
    ...base,
    ...textStyles,
    color: "#999"
  }),
  menu: (base) => ({
    ...base,
    ...textStyles,
    color: "#555"
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#337ab7" : undefined,
    color: state.isFocused ? "white" : "#555"
  }),
  singleValue: (base) => ({
    ...base,
    ...textStyles
  })
}

class RequiredSelect extends React.Component {
  state = {
    value: this.props.value || "",
  };

  selectRef = null;
  setSelectRef = ref => {
    this.selectRef = ref;
  };

  onChange = (value, actionMeta) => {
    this.props.onChange(value, actionMeta);
    this.setState({ value });
  };

  getValue = () => {
    if (this.props.value != undefined) return this.props.value;
    return this.state.value || "";
  };

  render() {
    const { SelectComponent, required, ...props } = this.props;
    const { isLoading, isDisabled } = this.props;
    const enableRequired = !isDisabled;

    return (
      <div>
        <SelectComponent
          {...props}
          styles={customStyles}
          ref={this.setSelectRef}
          onChange={this.onChange}
          noOptionsMessage={() => <span>Sem opções disponíveis</span>}
        />
        {enableRequired && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              width: "100%",
              height: 0,
              position: "absolute",
            }}
            value={this.getValue()}
            onChange={noop}
            onFocus={() => this.selectRef.focus()}
            required={required}
          />
        )}
      </div>
    );
  }
}

RequiredSelect.defaultProps = {
  onChange: noop,
};

RequiredSelect.protoTypes = {
  selectComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default RequiredSelect;