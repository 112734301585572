import React, { useState } from "react";
import styled from "styled-components";
import { Title } from "./Show/style"
import { FaChevronDown } from "react-icons/fa";

const SectionTitle = styled.h3`
  color: #7F7F7F;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
`;

const DropdownContainer = styled.div`
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
`;

const QuestionLabel = styled.button`
  background: #f9f9f9;
  border: none;
  color: #666666;
  cursor: pointer;
  font-size: 18px;
  text-align: left;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Source Sans Pro";

  &:hover {
    background: #e9ecef;
  }
`;

const Answer = styled.p`
  margin: 0;
  padding: 10px;
  background: #f1f1f1;
  border-top: 1px solid #ccc;
  font-size: 16px;
  color: #333;
`;

export default function Faq(props) {
  const faq = props?.faq || [];
  const [expandedQuestionId, setExpandedQuestionId] = useState(null);


  const toggleQuestion = (id) => {
    setExpandedQuestionId((prev) => (prev === id ? null : id));
  };

  return (
    <div>
       <Title>FAQ</Title>
      {faq.map((section) => (
        <div key={section.section_id}>
          <SectionTitle>{section.section_name}</SectionTitle>
          {section.questions.map((questionGroup, index) => (
            <React.Fragment key={index}>
              {questionGroup.map((question) => (
                <DropdownContainer key={question.id}>
                  <QuestionLabel onClick={() => toggleQuestion(question.id)}>
                    {question.label}
                    <FaChevronDown style={{ transform: expandedQuestionId === question.id ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s' }} />
                  </QuestionLabel>
                  {expandedQuestionId === question.id && (
                    <Answer>{question.value}</Answer>
                  )}
                </DropdownContainer>
              ))}
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
}
