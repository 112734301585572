import React, { useEffect, useState } from "react";
import { getCollection } from "utils/getCollection";
import { SearchableSelect } from "components/form";
import { Tip } from "components/ui";
import _ from "lodash";

function University(props) {
  const prefix = props.prefix || {
    id: "university_id",
    name: "university_id",
  };

  const [collection, setCollection] = useState(props.collection || []);
  const [universityTree, setUniversityTree] = useState(props.universityTree || []);
  const [children, setChildren] = useState([]);

  const defaultValue = _.get(props.control?._defaultValues, prefix.name) || props.defaultValue;
  const level = props.level || 0;
  const label = props.withoutLabel
    ? undefined
    : props.label || "Instituição de Ensino";

  const handleChange = (university) => {
    setChildren([]);

    if (university?.value) {
      getCollection(
        `/selects/${university.value}/children_react.json`,
        setChildren
      );
    }
  };

  const renderChildren = () => {
    if (children.length == 0) {
      return;
    }

    return (
      <University
        {...props}
        withoutLabel
        collection={children}
        universityTree={universityTree}
        level={level + 1}
      />
    );
  };

  const renderTip = () => {
    if (level > 0) {
      return;
    }

    return (
      <Tip>
        Não encontrou sua <b>faculdade</b>? <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=JG0Ku-RhXEeaKMWr_mgPSFGW5il2p45MgKp_-yqFCKxUNVFISDhWODVSNjREUTkwMVJGSkZQRUQ1UC4u" target="_blank">Clique aqui</a> e solicite o cadastramento dela em nossa plataforma! 😉
      </Tip>
    );
  };

  useEffect(() => {
    const getUniversities = () => {
      getCollection(`/selects/universities_unified.json`, setCollection);
    };

    if (_.isEmpty(collection)) getUniversities();
  }, []);

  useEffect(() => {
    const getUniversityTree = () => {
      getCollection(
        `/selects/${defaultValue}/university_tree.json`,
        setUniversityTree
      );
    };

    if (defaultValue && level === 0) getUniversityTree();
  }, [defaultValue]);

  return (
    <div className="form-group">
      <SearchableSelect
        {...props}
        placeholder="Selecione a instituição onde você estuda..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        defaultValue={universityTree[level]?.value || defaultValue || ""}
        label={label}
        onChange={handleChange}
        useChildrenSelects
      />
      {renderChildren()}
      {renderTip()}
    </div>
  );
}

export default University;
