import React from "react";
import { ContentAcademy } from "./styles";

export default React.memo(({ children }) => {
 
  return (
    <ContentAcademy>
        {children}
    </ContentAcademy>
  );
});