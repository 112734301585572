import React from "react";
import { FooterAcademy } from "./styles";
import { FaLinkedin, FaSquareFacebook, FaTiktok } from "react-icons/fa6";
import { GrInstagram } from "react-icons/gr";

export default React.memo(() => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <FooterAcademy>
      <div>
        <div className="social_medias">
          <a href="https://www.linkedin.com/company/3962053" target="_blank"><FaLinkedin /></a>
          <a href="https://www.facebook.com/wjtrampos" target="_blank"><FaSquareFacebook /></a>
          <a href="https://www.instagram.com/wall.jobs" target="_blank"><GrInstagram /></a>
          <a href="https://www.tiktok.com/@walljobs" target="_blank"><FaTiktok /></a>
        </div>
        <div className="terms_of_use">
          <p>
            Walljobs™ {year} - Todos os direitos reservados -
            <a href="https://www.walljobs.com.br/terms.pdf"> Termos de uso</a>
          </p>
        </div>
      </div>
    </FooterAcademy>
  );
});