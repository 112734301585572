import React from "react";
import Input from "components/form/NewLanding/Input";

function EmployeesNumber(props) {
  const prefix = ((typeof props.prefix) === 'string') ? 
    {id: props.prefix, name: props.prefix}  : 
      props.prefix || {
        id: "employees_number",
        name: "employees_number",
      };


  return (
    <Input
      {...props}
      placeholder="Número de estagiários"
      name={prefix.name}
      id={prefix.id}
    />
  );
}

export default EmployeesNumber;