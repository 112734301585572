import React from "react";
import { useStaticCollection } from "utils/hooks";
import { SearchableSelect } from "components/form";

function Skills(props) {
  const collection = useStaticCollection("/selects/skills.json")

  const prefix = props.prefix || {
    name: "skill_ids",
    id: "skill_ids",
  };

  const sanitizeValue = (values) => {
    return values.map?.((option) => option?.value || option) || [];
  };

  return (
    <div className="form-group">
      <SearchableSelect
        {...props}
        id={prefix.id}
        name={prefix.name}
        collection={collection}
        placeholder="Digite aqui e/ou escolha as habilidade, por exemplo: E-commerce, Contabilidade, Controladoria"
        label={props.label || "Quais as suas habilidades?"}
        sanitizeValue={sanitizeValue}
        multiple
      />
    </div>
  );
}
export default Skills;
