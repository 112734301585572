import React from "react";
import TextField from "@mui/material/TextField";
import _ from "lodash";

function UncontrolledInput(props) {
  const validation = { ...{ required: props.required }, ...props.validation };

  const messages = {
    ...{ required: "Não pode ficar em branco" },
    ...props.messages,
  };

  const error = _.get(props.errors, props.name);

  const inputRef = props.register?.(props.name, validation);

  return (
    <TextField
      slotProps={{
        input: props.InputProps,
        htmlInput: { className: props.className },
        inputLabel: props.InputLabelProps
      }}
      variant="standard"
      id={props.id}
      name={props.name}
      placeholder={props.placeholder}
      type={props.type}
      label={props.label}
      required={props.required}
      defaultValue={props.defaultValue}
      multiline={props.multiline}
      rows={props.rows}
      autoFocus={props.autoFocus}
      autoComplete="off"
      onChange={(e) => {
        inputRef?.onChange?.(e);
        props.onChange?.(e);
      }}
      onBlur={(e) => {
        inputRef?.onBlur?.(e);
        props.onBlur?.(e);
      }}
      inputRef={inputRef?.ref}
      error={props.error || !_.isEmpty(error)}
      helperText={error?.message || messages[error?.type] || props.helperText}
      disabled={props.disabled}
    />
  );
}

export default UncontrolledInput;
