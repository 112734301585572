import React from 'react';
import StudySchedule from 'components/users/fields/academic/StudySchedule/StudySchedule';
import Section from './Section';

function StudyScheduleSection(props){
  return(
    <Section title="Horário de Estudo">
      <StudySchedule {...props} />
    </Section>
  )
}


export default StudyScheduleSection;
