import React from "react";
import {UncontrolledInput as Input} from 'components/form';

function Complement(props) {
  const prefix = props.prefix || {
    name: "address_attributes[complement]",
    id: "address_attributes_complement",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        className={props.className}
        placeholder="Informe o complemento, caso haja..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        defaultValue={props.defaultValue}
        label={props.label || "Complemento"}
      />
    </div>
  );
}

export default Complement;
