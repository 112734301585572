import React from "react";
import {
  Abilities,
  AcademicEntities,
  Course,
  CourseSituation,
  CourseStartDate,
  CurrentSemester,
  Education,
  FormationDate,
  Language,
  Matriculation,
  NotInUniversity,
  Period,
  Skills,
  Software,
  University
} from "components/users/fields/academic";

import {
  AuthorizeWhatsapp,
  AutoEnroll,
  CellPhone,
  CPF,
  DateOfBirth,
  Email,
  Ethnicity,
  Gender,
  MaritalStatus,
  Name,
  Working,
  Nationality,
  JobInterests
} from "components/users/fields/essential";

import {
  ExpectedSalary,
  Experiences,
  Interests,
  Portfolios,
  Summary
} from "components/users/fields/experience";

import {
  Avatar,
  Facebook,
  Hobbies,
  Instagram,
  InterestingFact,
  Linkedin,
  MessagePhone,
  MotherName,
  Motivations,
  Phone,
  RG,
  RGState,
  SexualOrientation,
  Skype
} from "components/users/fields/personal";

import State from "components/users/fields/personal/address/State";
import City from "components/users/fields/personal/address/City";

import StudyScheduleSection from "pages/profile/users/Wizard/forms/sections/StudyScheduleSection";
import AddressSection from "pages/profile/users/Wizard/forms/sections/AddressSection";
import DisabilitySection from "pages/profile/users/Wizard/forms/sections/DisabilitySection";
import AcademicSection from "./AcademicSection";

export const EditModeContext = React.createContext({
  openModal: null,
  fieldList: [],
  handleEditModal: () => {},
});

export const EditModeProvider = EditModeContext.Provider;

export const fields = {
  full_name: <Name />,
  cpf: <CPF />,
  email: <Email />,
  cellphone: <CellPhone />,
  phone: <Phone />,
  message_phone: <MessagePhone />,
  date_of_birth: <DateOfBirth />,
  nationality_id: <Nationality />,
  ethnicity_id: <Ethnicity />,
  gender_id: <Gender />,
  working: <Working />,
  expected_salary: <ExpectedSalary />,
  auto_enroll: <AutoEnroll />,
  authorize_whatsapp: <AuthorizeWhatsapp />,
  university_id: <University />,
  course_id: <Course />,
  period: <Period />,
  course_start_date: <CourseStartDate />,
  course_situation: <CourseSituation />,
  not_in_university: <NotInUniversity />,
  current_semester: <CurrentSemester />,
  formation_date: <FormationDate />,
  skype: <Skype />,
  facebook: <Facebook />,
  linkedin: <Linkedin />,
  instagram: <Instagram />,
  user_languages: <Language />,
  summary: <Summary />,
  portfolios: <Portfolios />,
  hobbies: <Hobbies />,
  interesting_fact: <InterestingFact />,
  motivations: <Motivations />,
  experiences: <Experiences />,
  user_softwares: <Software />,
  abilities: <Abilities />,
  skills: <Skills />,
  education: <Education />,
  academic_entities: <AcademicEntities />,
  matriculation: <Matriculation />,
  mother_name: <MotherName />,
  rg: <RG />,
  rg_state_id: <RGState />,
  address: <AddressSection />,
  study_schedule: <StudyScheduleSection />,
  disabilities: <DisabilitySection />,
  interests: <Interests />,
  avatar: <Avatar />,
  marital_status: <MaritalStatus />,
  sexual_orientation: <SexualOrientation />,
  job_interests: <JobInterests />,
  academic: <AcademicSection />,
  state_id: <State />,
  city_id: <City />
};

export const sections = {
  summary: [
    fields["full_name"],
    fields["working"],
    fields["summary"],
    fields["portfolios"],
    fields["job_interests"],
    fields["interesting_fact"],
    fields["motivations"],
    fields["hobbies"]
  ],
  experiences: [
    fields["interests"],
    fields["experiences"]
  ],
  softwares: [
    fields["user_softwares"]
  ],
  academic: [
    fields["university_id"],
    fields["course_id"],
    fields["course_situation"],
    fields["period"],
    fields["course_start_date"],
    fields["formation_date"],
    fields["current_semester"],
    fields["academic_entities"]
  ],
  education: [
    fields["education"]
  ],
  certifications: [
    fields["abilities"],
    fields["skills"]
  ],
  internship: [
    fields["matriculation"],
    fields["rg_state_id"],
    fields["study_schedule"]
  ],
  infos: [
    fields["avatar"],
    fields["gender_id"],
    fields["ethnicity_id"],
    fields["nationality_id"],
    fields["date_of_birth"],
    fields["rg"],
    fields["mother_name"],
    fields["marital_status"],
    fields["sexual_orientation"],
    fields["expected_salary"],
    fields["address"],
    fields["disabilities"]
  ],
  contact: [
    fields["email"],
    fields["cellphone"],
    fields["phone"],
    fields["message_phone"],
    fields["authorize_whatsapp"],
    fields["skype"],
    fields["facebook"],
    fields["linkedin"],
    fields["instagram"]
  ],
  languages: [
    fields["user_languages"]
  ]
};
