import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useUserInfo() {
  const { id } = useParams();
  const { data, isLoading, mutate } = useFetch(`/v1/profile/user_infos/${id}`);

  const getIncluded = (type) => {
    return data?.included.filter(element => element?.type === type) || []
  }

  return {
    userInfo: data?.data?.attributes,
    included: data?.included,
    mutate,
    getIncluded,
    isLoading,
  };
}

export function useInvite(){
  const { id } = useParams();
  const { data, isLoading } = useFetch(`/v1/internship/update/invites/${id}`);

  return {
    invite: data?.form_url,
    isLoading: isLoading
  };
}