import React from "react";
import { AddableSelect as CreatableSelect } from "components/form";
import _ from "lodash";

function Hobbies(props) {
  const prefix = props.prefix || {
    name: "hobbies",
    id: "hobbies",
  };

  const defaultValue = props.defaultValue || _.get(props.getValues?.(), prefix.name) || ""

  const collection = defaultValue.map?.((option) => ({value: option, label: option})) || [];

  const sanitizeValue = (values) => {
    return values.map?.((option) => option?.value || option);
  };

  return (
    <div className="form-group">
      <CreatableSelect
        {...props}
        placeholder="Quais seus hobbies? O que você gosta de fazer?"
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        defaultValue={defaultValue}
        label={props.label || "Hobbies"}
        sanitizeValue={sanitizeValue}
        multiple
      />
    </div>
  );
}

export default Hobbies;
