import React from "react";
import { UncontrolledInput as Input } from "components/form";

function Instagram(props) {
  const prefix = props.prefix || {
    name: "instagram",
    id: "instagram",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe seu Instagram..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Instagram"}
      />
    </div>
  );
}

export default Instagram;
