import React, { useState } from 'react';
import _ from "lodash";
import Disability from "components/users/fields/personal/Disability";
import Disabilities from "components/users/fields/personal/Disabilities";
import { Checkbox, Collapse } from "@mui/material";

function DisabilitySection(props) {
  const defaultValue = props.getValues?.("disability")?.toString() || false;
  const [expanded, setExpanded] = useState(defaultValue === "true");

  const handleChange = (value) => {
    setExpanded(value === 'true');
  };

  return (
    <>
      <div className="col-md-12">
        <Disability {...props} handleChange={handleChange} />
      </div>
      <Collapse
        sx={{ width: "100%" }}
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <div className="col-md-12">
          <Disabilities {...props} required={expanded} />
        </div>
      </Collapse>
    </>
  );
}

export default DisabilitySection;