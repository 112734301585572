import React from 'react';
import { UncontrolledInput as Input } from 'components/form';

function CourseName(props){
  const { prefix, index } = props;

  return(
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe o curso..."
        type="text"
        name={`${prefix}[${index}][course_name]`}
        id={`${prefix}_${index}_course_name`}
        label="Curso"
      />
    </div>
  )
}

export default CourseName;