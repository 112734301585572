import React from "react";
import { UncontrolledInput as Input } from "components/form";

function Motivations(props) {
  const prefix = props.prefix || {
    name: "motivations",
    id: "motivations",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="O que te motiva? Conte aqui o que te faz levantar todos os dias, estudar essa área..."
        type="text"
        multiline
        rows={4}
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Paixões/Motivações"}
      />
    </div>
  );
}

export default Motivations;
