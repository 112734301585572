import React from "react";

import { Position } from 'reactflow';

import styled from "styled-components";

const Label = styled.h1`
  text-align: center;
  margin-top: 25px;
  color: ${props => props.color};
  font-weight: 600;
  font-size: 32px;
`

export const GroupBox = ({
  data,
  isConnectable,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
}) => (<Label color={data?.color}>{data?.label}</Label>)

