import React from "react";
import { useNestedAttributes, useStaticCollection } from "utils/hooks";
import DisabilityAttributes from "./DisabilityAttributes";

function Disabilities(props) {
  const disabilityTypes = useStaticCollection("/selects/disabilities.json");

  const prefix = props.prefix || "disabilities_attributes";

  const { list, addItem, destroyItem } = useNestedAttributes({ ...props, prefix });

  const renderDisabilities = () => {
    return list?.map((item, index) => {
      const properties = {
        ...props,
        prefix,
        item,
        index,
        disabilityTypes,
        destroyItem,
      };
      return <DisabilityAttributes {...properties} key={item?.id || item?.key || index} />;
    });
  };

  return (
    <div>
      {renderDisabilities()}
      <div className="add-button">
        <button type="button" onClick={addItem}>
          adicionar <i className="fa fa-plus"></i>
        </button>
      </div>
    </div>
  );
}

export default Disabilities;
