import React from "react";
import { useEnvelope } from "../context";
import styled from "styled-components";

const Link = styled.a`
  display: flex;
  gap: 6px;
`;

const Title = styled.h3`
  font-weight: 600;
`

export default () => {
  const { envelope } = useEnvelope();
  const { signed_document } = envelope;

  if (_.isEmpty(signed_document)) {
    return;
  }

  const renderSignedDocument = () => {
    return signed_document.map((item) => (
      <Link href={item.url} key={item.filename} target="blank" >
        <i className="material-icons-outlined">file_download</i>{" "}
        {item.filename}
      </Link>
    ));
  };

  return (
    <>
      <Title>Contrato assinado</Title>
      {renderSignedDocument()}
      <br />
    </>
  );
};
