import React, { useEffect } from 'react'
import {UncontrolledInput as Input} from 'components/form';
import _ from "lodash";

function Neighborhood(props) {
  const prefix = props.prefix || {
    name: "address_attributes[neighborhood]",
    id: "address_attributes_neighborhood"
  }

  useEffect(() => {
    let { neighborhood } = props.address;
    if(neighborhood) props.resetField(prefix.name, { defaultValue: neighborhood })
  }, [props.address.neighborhood])

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe o bairro..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Bairro"}
        InputLabelProps={!_.isEmpty(props.address) ? { shrink: !!props.address?.neighborhood } : {}} 
      />
    </div>
  )
}

export default Neighborhood
