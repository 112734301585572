import React, { useEffect } from "react"
import {UncontrolledInput as Input} from 'components/form';
import VMasker from "vanilla-masker";

function FormationDate(props) {
  const prefix = props.prefix || {
    name: "formation_date",
    id: "formation_date"
  }

  const validation = {
    pattern: /([0][1-9]|[1][0-2])\/(19|20)\d{2}$/
  };

  const messages = {
    pattern: "Não segue o padrão MM/AAAA ou não é uma data válida",
  };

  useEffect(() => {
    VMasker(document.querySelector(".formation_date")).maskPattern("99/9999");
  }, []);
  
  return(
    <div className="form-group">
      <Input
        {...props}
        className="formation_date"
        placeholder="MM/AAAA"
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Data de Formação"}
        validation={validation}
        messages={messages}
      />
    </div>
  )
}
export default FormationDate;