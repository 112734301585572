import React from "react";
import { HiddenField } from "components/form";

function IdAndDestroyFields(props) {
  const { index, item, destroy, prefix } = props;

  const handleChange = (value) => {
    props.setValue?.(`${prefix?.name || prefix}[${index}][_destroy]`, value);
  };

  return (
    <>
      {item.id && (
        <HiddenField
          {...props}
          name={`${prefix?.name || prefix}[${index}][id]`}
          id={`${prefix?.id || prefix}_${index}_id`}
        />
      )}
      <HiddenField
        {...props}
        name={`${prefix?.name || prefix}[${index}][_destroy]`}
        id={`${prefix?.id || prefix}_${index}__destroy`}
        handleChange={handleChange}
        value={destroy}
      />
    </>
  );
}

export default IdAndDestroyFields;
