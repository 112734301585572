import { useState } from "react";

function useFilterController() {
  const [filters, setFilters] = useState({});
  const [infos, setInfos] = useState({});

  function filterData(filters) {
    setFilters(filters);
  }

  function updateInfos(infos) {
    let { totalCount, length } = infos;
    if(totalCount === 0 && length > 0) totalCount = length;
    setInfos({totalCount, length})
  }

  return [infos, filters, updateInfos, filterData];
}

export default useFilterController;
