import React from "react";
import styled from "styled-components";

const Button = styled.div`
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  border: 1px solid ${({theme}) => theme.mode === "light" ? "#e4e4e4" : "#333b4d"};
  background-color: ${({theme}) => theme.mode === "light" ? "#f7f7f7" : theme.background.paper};
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    font-size: 16px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    color: inherit;
    cursor: pointer;

    i {
      font-size: 16px !important;
      margin-right: 20px;
    }
  }
`;

export default ({ children }) => (
  <Button className="back-button">{children}</Button>
);
