import React from "react"
import { AddableSelect as CreatableSelect } from "components/form";
import _ from "lodash";

function Portfolios(props) {
  const prefix = props.prefix || {
    name: "portfolios",
    id: "portfolios",
  };

  const defaultValue = props.defaultValue || _.get(props.getValues?.(), prefix.name) || ""

  const collection = defaultValue.map?.((option) => ({value: option, label: option})) || [];

  const sanitizeValue = (values) => {
    return values.map?.((option) => option?.value || option);
  };

  return(
    <div className="form-group">
      <CreatableSelect
        {...props}
        placeholder="Insira aqui o link de seu site ou portfólio. Você pode inserir mais de um!"
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        defaultValue={defaultValue}
        label={props.label || "Sites/Portfólios"}
        sanitizeValue={sanitizeValue}
        multiple
      />
      <small><b>Dica:</b> insira o link completo; exemplo: <b><i>http://www.</i></b>meusite<b><i>.com.br</i></b></small>
    </div>

  )
}
export default Portfolios;