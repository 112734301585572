import React from "react";
import styled from "styled-components";
import { Title } from "./Show/style";
const StepContent = styled.div`
  background-color: #FFF;
  border-radius: 8px;
  padding: 12px 32px;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;

  p{
    margin-left: 10px;
    padding-right: 10px;
    font-family: "Source Sans Pro";
    color: #7F7F7F
  }

  i{
    font-size: 20px;
    color: #7F7F7F;
    flex-shrink: 0;
  }
`

const CardSteps = styled.div`
  display: inline;
`

export default function Step(props){
  const steps = props?.steps || []

  const hendleStep = (step) => {
    if (!step.date == "" || !step.date == null) {
      return `${step.name} •  ${step.date}`
    }else{
      return `${step.name}`
    }
  }
  return (
    <>
      <Title>Cronograma</Title>
      <CardSteps>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <StepContent>
              <i className="far fa-calendar"></i><p className="step-name">{hendleStep(step)}</p>
            </StepContent>
          </React.Fragment>
        ))}
      </CardSteps>
    </>
  )
}