import React, { useEffect, useState } from "react";
import EducationType from './EducationType';
import InstitutionName from './InstitutionName';
import CourseName from './CourseName';
import Duration from './Duration';
import IdAndDestroyFields from "components/users/fields/shared/IdAndDestroyFields";

function EducationAttributes(props) {
  const { item, index } = props;

  const [destroy, setDestroy] = useState(false);

  const removeFromList = () => {
    setDestroy(true);
  };

  useEffect(() => {
    if (destroy) props.destroyItem(item.id, index);
  }, [destroy]);

  return (
    <div className="row">
      <div className="col-md-3">
        <EducationType {...props} />
      </div>
      <div className="col-md-3">
        <InstitutionName {...props} />
      </div>
      <div className="col-md-3">
        <CourseName {...props} />
      </div>
      <div className="col-md-3">
        <Duration {...props} />
      </div>
      <div className="col-md-1 col-button">
        <button
          type="button"
          className="button-delete"
          onClick={removeFromList}
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
      <IdAndDestroyFields {...props} destroy={destroy} />
    </div>
  );
}

export default React.memo(EducationAttributes)