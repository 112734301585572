import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "@root/redux/features/sessions/actions";

const Button = styled.a`
  color: white !important;
  cursor: pointer;
  display: flex;
  gap: 10px;
`;

export default ({ toggleDrawer }) => {
  let navigate = useNavigate();

  const handleClick = () => {
    toggleDrawer();
    handleLogout(navigate);
  };

  return (
    <Button onClick={handleClick}>
      <i className="material-icons">power_settings_new</i> Sair
    </Button>
  );
};
