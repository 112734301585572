export const typography = {
  fontFamily: ["Source Sans Pro", "Poppins", "Roboto", "Helvetica", "Arial"].join(", "),
  h1: {
    fontFamily: "Poppins",
    fontSize: "96px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "116.7%",
    letterSpacing: "-1.5px",
  },
  h2: {
    fontFamily: "Poppins",
    fontSize: "60px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "120%",
    letterSpacing: "-0.5px",
  },
  h3: {
    fontFamily: "Poppins",
    fontSize: "48px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "116.7%",
  },
  h4: {
    fontFamily: "Poppins",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "123.5%",
    letterSpacing: "0.25px",
  },
  h5: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "133.4%",
  },
  h6: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "160%",
    letterSpacing: "0.15px",
  },
  subtitle1: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "175%",
    letterSpacing: "0.15px",
  },
  subtitle2: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "157%",
    letterSpacing: "0.1px",
  },
  body1: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    letterSpacing: "0.15px",
  },
  body2: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "143%",
    letterSpacing: "0.17px",
  },
  caption: {
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "166%",
    letterSpacing: "0.4px",
  },
  overline: {
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "266%",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
};
