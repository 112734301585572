import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

function useNestedAttributes(params) {
  const { prefix, unregister, getValues } = params;

  const [list, setList] = useState([]);

  const addItem = () => {
    let key = new Date().getTime();
    let newList = [...list, { key }];
    setList(newList);
  };

  const destroy = (index) => {
    let name = `${prefix?.name || prefix}[${index}]`
    unregister?.(name);
    
    hideAttributes(index);
  };

  const hideAttributes = (index) => {
    let field = document.getElementById(`${prefix?.id || prefix}_${index}__destroy`);
    let parent = field.parentElement;
    parent.style.display = "none";
  }

  const destroyItem = useCallback((id, index) => {
    id ? hideAttributes(index) : destroy(index);
  }, [])

  useEffect(() => {
    let fieldName = (prefix?.name || prefix).replaceAll(/\[|\]\[/gi, ".").replaceAll(/\]/gi, "")
    let defaultValues = params.defaultValues || getValues?.(fieldName);

    if(_.isEmpty(defaultValues)) return addItem();

    setList(defaultValues);
  }, [])

  return { list, addItem, destroyItem };
}

export default useNestedAttributes;
