import React from "react";
import { UncontrolledInput as Input } from "components/form";

function MotherName(props) {
  const prefix = props.prefix || {
    id: "mother_name",
    name: "mother_name",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe o nome completo de sua mãe..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Nome completo da mãe"}
      />
    </div>
  );
}

export default MotherName;
