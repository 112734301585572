import React from "react";
import _ from "lodash";
import { RadioButtons } from "components/form";

function NotInUniversity(props) {
  const prefix = props.prefix || {
    name: "not_in_university",
    id: "not_in_university",
  };

  const options = [
    { value: "false", label: "Sim" },
    { value: "true", label: "Não" },
  ];

  const defaultValue = _.get(props.getValues?.(), prefix.name)?.toString() || props.defaultValue?.toString()

  return (
    <RadioButtons
      {...props}
      id={prefix.id}
      name={prefix.name}
      options={options}
      label={props.label || "Cursa ou já cursou ensino superior?"}
      defaultValue={defaultValue}
    />
  );
}

export default NotInUniversity;
