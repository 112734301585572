import { useEffect, useState } from "react";

function useSteps(params) {
  const [step, setStep] = useState(0);
  const { totalLength } = params;

  const next = () => {
    if (hasNext()) setStep(step + 1);
  };

  const previous = () => {
    if (hasPrevious()) setStep(step - 1);
  };

  const hasNext = () => step < totalLength - 1;

  const hasPrevious = () => step >= 1;

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [step])

  return { step, setStep, previous, next, hasNext, hasPrevious };
}

export default useSteps;
