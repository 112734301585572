import {React, useState, useEffect} from "react";
import styled from "styled-components";
import _ from "lodash";

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

const InputStyled = styled.input`
  border-radius: 4px;
  border: 1px solid #DDD;
  padding: 4px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  width: 100%;
  padding: 12px;
  border-color: ${(props) => props.borderColor};

  outline-color: ${(props) => props.borderColor};

  ::placeholder{
    color: #2F2F2F;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }
`

const Required = styled.a`
  color: #2F2F2F;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  align-self: stretch;
`

const HelperText = styled.a`
  color: #FF6262 !important;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  align-self: stretch;
`

function Input(props){
  const [fieldValue, setFieldValue] = useState("");
  const [helperText, setHelperText] = useState(props.helperText || "");
  const [borderColor, setBorderColor] = useState("none");
  const [emailValidated, setEmailValidated] = useState(false);
  const [cpnjValidated, setCnpjValidated] = useState(false);
  const [passwordValidated, setpasswordValidated] = useState(false);


  const renderRequired = () => {
    return (
      <Required>
        {props.requiredText || "Este campo é obrigatório."}
      </Required>
    )
  }

  const renderHelperText = () => {
    return (
      <>
        <HelperText>{helperText}</HelperText>
      </>
    )
  }

  const validation = { ...{ required: props.required }, ...props.validation };

  const messages = {
    ...{ required: "Não pode ficar em branco" },
    ...props.messages,
  };

  const error = _.get(props.errors, props.name);

  const inputRef = props.register?.(props.name, validation);

  const validateEmail = (email) => {
    const regex = new RegExp(
      /^[A-Za-z0-9_@./#&+-]+@[A-Za-z0-9_@./#&+-]+\.[a-z.]+\)?$/i
    )
    return regex.test(email);
  }

  const validateCnpj = (cnpj) => {
    const regex = new RegExp(
      '[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}'
    )
    
    return regex.test(cnpj);
  }
 

  const formatPassword = (password) => {
    const regex = new RegExp(
      '^(?=.*\\d)^(?=.*[#!$@%^&*-])^(?=.*[A-Z])^(?=.*[a-z])'
    );
    return regex.test(password);
  };

  const validatePassword = (password) => {
    if (password && formatPassword(password) && password.length >= 8) {
      return true;
    }
    
    return false;
  };


  useEffect(() => {
    setHelperText(props.helperText);
  }, [props.helperText]);

  useEffect(() => {
    // validação do email
    if(fieldValue?.length > 0 && props.type === "email"){
      if(validateEmail(fieldValue)){
        setHelperText("");
        setBorderColor("#5CB85C");
        props.setEmailValidation?.(true);
        setEmailValidated(true);
      }else{
        setHelperText("Formato de e-mail inválido.");
        setBorderColor("red");
        props.setEmailValidation?.(false);
        setEmailValidated(false);
      }
    }

    // validação cnpj
    if(fieldValue?.length > 0 && props.type === "cnpj"){
      if(validateCnpj(fieldValue)){
        setHelperText("");
        setBorderColor("#5CB85C");
        props.setCnpjValidation?.(true);
        setCnpjValidated(true);
      }else {
        setHelperText("Por favor, insira um CNPJ válido.");
        setBorderColor("red");
        props.setCnpjValidation?.(false);
        setCnpjValidated(false);
      }
    }

    if(fieldValue?.length > 0 && props.type === "password"){
      if(validatePassword(fieldValue)){
        setHelperText("");
        setBorderColor("#5CB85C");
        setpasswordValidated(true);
      }
    }

    if(props.type === "phone" && fieldValue?.length < 19){
      setHelperText("");
      setBorderColor("none");
    }

    if(fieldValue?.length < 1){
      setHelperText("");
      setBorderColor("none");
      setEmailValidated(false);
      setCnpjValidated(false);
      setpasswordValidated(false)
    }

    // validação dos outros campos
    
  }, [fieldValue])

  useEffect(() => {
    if(fieldValue?.length > 0 && props.type === "email"){
      if(!props.isValid && emailValidated){
        setHelperText("Este e-mail já está cadastrado. Por favor, use outro.");
        setBorderColor("red");
      }
    }

    // validação do cnpj
    if(fieldValue?.length > 0 && props.type === "cnpj"){
      if(!props.isValid && cpnjValidated){
        setHelperText("Este CNPJ já está cadastrado. Tente fazer login ou recupere sua senha.");
        setBorderColor("red");
      }
    }
    if(fieldValue?.length > 0 && props.type === "password"){
      if(fieldValue?.length < 8 && passwordValidated){
        setBorderColor("red");
      }
    }

    // validação dos outros campos
    if(fieldValue?.length > 0 && props.isValid){
      setHelperText("");
      setBorderColor("#5CB85C");
    }

  }, [props.isValid])

  
  return (
    <InputBox>
      <InputStyled 
        placeholder={props.placeholder}
        className={props.className}
        required={props.required}
        id={ props.id}
        name={props.name}
        type={props.type}
        autoComplete="off"
        onChange={(e) => {
          setFieldValue(e.target.value);
          inputRef?.onChange?.(e);
          props.onChange?.(e);
        }}
        onBlur={(e) => {
          inputRef?.onBlur?.(e);
          props.onBlur?.(e);
        }}
        ref={inputRef?.ref}
        error={props.error || !_.isEmpty(error)}  
        helperText={helperText}
        borderColor={props?.color || borderColor || "none"}
      />
      {helperText && renderHelperText()}
      {props.required && renderRequired()}
    </InputBox>
  )
}

export default Input;
