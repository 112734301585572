import React, { useState } from "react";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UncontrolledInput as Input } from "components/form";
import {Input as NewInput } from "components/form/NewLanding";

export default (props) => {
  const [showPassword, setShowPassword] = useState(false);
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function renderInput(){
    if(props.layout === "new"){
      return (
        <NewInput
          {...props}
          id={props.prefix.id}
          name={props.prefix.name}
          type="password"
          placeholder={props.placeholder || "Digite sua senha..."}
        />
      )
    }else {
      return (
        <Input
          {...props}
          type={showPassword ? "text" : "password"}
          placeholder={props.placeholder || "Digite sua senha..."}
          label={props.label || "Senha"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )
    }
  }

  
  return (
    renderInput()
  );
}