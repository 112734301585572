import React from "react";
import styled from "styled-components";

export const MainBoxStyles = styled.div`
  width: 85%;
  max-width: 1150px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    & {
      width: 100%;
      min-height: calc(
        100vh - (60px + 140px)
      ); //total da viewport - (altura do header no mobile + altura do footer)
      //background: #f0f0f0;
    }
  }
`;

export default React.memo(({ children }) => (
  <MainBoxStyles className="main">{children}</MainBoxStyles>
));
