export const emptyValue = (value) => {
  return (
    // null or undefined
    (value == null) ||

    // has length and it's zero
    (value?.hasOwnProperty('length') && value.length === 0) ||

    // is a String and length it's zero
    (typeof value === 'string' && value.trim().length === 0) ||

    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  )
}