import React from "react";
import { SelectInput } from "components/form";

function CourseSituation(props) {
  const prefix = props.prefix || {
    id: "course_situation",
    name: "course_situation",
  };

  const options = [
    { value: "studying", label: "Cursando" },
    { value: "locked", label: "Trancado" },
    { value: "graduate", label: "Graduado" },
  ];

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione..."
        name={prefix.name}
        id={prefix.id}
        collection={options}
        label={props.label || "Situação do curso"}
      />
    </div>
  );
}

export default CourseSituation;
