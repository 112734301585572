import React from "react";
import { useStaticCollection, useNestedAttributes } from "utils/hooks";
import LanguageAttributes from "./LanguageAttributes";

function Language(props) {
  const languages = useStaticCollection("/selects/languages.json");

  const prefix = props.prefix || "user_languages_attributes";

  const { list, addItem, destroyItem } = useNestedAttributes({ ...props, prefix });

  const renderLanguages = () => {
    return list?.map((item, index) => {
      const properties = {
        ...props,
        prefix,
        item,
        index,
        languages,
        destroyItem,
      };
      return <LanguageAttributes {...properties} key={item?.id || item?.key || index} />;
    });
  };

  return (
    <div>
      {renderLanguages()}
      <div className="add-button">
        <button type="button" onClick={addItem}>
          adicionar <i className="fa fa-plus"></i>
        </button>
      </div>
    </div>
  );
}

export default Language;
