import React, { useEffect } from "react";
import _ from "lodash";

function Cid(props) {
  const { index } = props;

  const prefix = {
    id: `${props.prefix}_${index}_cid`,
    name: `${props.prefix}[${index}][cid]`
  }

  const inputRef = props.register?.(prefix.name);

  useEffect(() => {
    document.querySelector("input[type='file']").addEventListener("change", (e) => {
      let input = e.target;
      input.classList.toggle("non-empty", input.files.length);
    });
  }, [])

  return (
    <div className="col-md-3">
      <div className="form-group">
        <label>{props.label || "CID"}</label>
        <input
          accept="application/pdf, image/*"
          id={prefix.id}
          name={prefix.name}
          type="file"
          onChange={(e) => {
            inputRef?.onChange?.(e);
            props.onChange?.(e);
          }}
          multiple={props.multiple}
          ref={inputRef?.ref}
        />
      </div>
    </div>
  );
}

export default Cid;
