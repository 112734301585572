import styled from "styled-components";
import { Paper } from "@mui/material";

const BoxStyleTypes = styled.div`
  margin: 35px 0 20px;
  display: grid;
  grid-template-columns: 3fr 1fr 3fr 1fr 3fr;
  grid-gap: 10px;

  h3 {
    grid-column: 1 / -1;
  }

  @media screen and (max-width: 767px) {
    & {
      grid-template-columns: 1fr;
      grid-gap: 0px;

      h3 {
        margin-bottom: 15px;
      }
    }
  }
`;

const BoxStyleType = styled(Paper)`
  min-width: 200px;
  padding: 10px;
  height: fit-content;
  background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.40;
      ${props.theme.mode === "light" && `filter: grayscale(100%);`}
    `}

  h4 {
    font-weight: bold;
  }
`;

const State = styled.div`
  margin-top: 12px;
  padding-top: 10px;
  border-top: 1px dashed rgba(0, 0, 0, 0.18);
`;

const StateName = styled.p`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StateUpdatedAt = styled.small`
  display: block;
  margin-top: 4px;
  font-size: 11px;
`;

const StateStatus = styled.span`
  font-size: 12px;
  font-weight: bolder;
  color: white;
  text-align: center;
  padding: 2px 6px;
  border-radius: 3px;
  margin-bottom: 0;
  display: block;
  width: fit-content;
  height: fit-content;
  margin-left: auto;

  &.created {
    color: #333b4d;
    background-color: #dbdbdb;
  }
  &.sent,
  &.delivered {
    background-color: #3aafe6;
  }
  &.review {
    background-color: #f7af42;
  }
  &.declined,
  &.voided {
    background-color: #bf213c;
  }
  &.signed,
  &.completed,
  &.approved {
    background-color: #51f27e;
  }
  &.not_defined {
    background-color: #666;
  }
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  h5{
    font-weight: bold;
    font-size: 18px;
  }

  button{
    margin-top: 20px;
  }
`;

const ActionWrapper = styled.div`
  margin: 30px 0 60px 0;
`;

export {
  State,
  BoxStyleTypes,
  BoxStyleType,
  StateName,
  StateUpdatedAt,
  StateStatus,
  Action,
  ActionWrapper
};
