import React, { useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "styled-components";
import { handleThemeMode } from "@root/redux/features/theme/actions";
import { GlobalStyles } from "styles/global";
import { ThemeModeProvider } from "styles/context";
import { getDesignTokens } from "styles/mui/theme";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";

const ThemeWrapper = (props) => {
  //const [mode, setMode] = useState(props.mode || "light"); //turned off theme switcher for a while
  const [mode, setMode] = useState("light");

  const themeMode = useMemo(
    () => ({
      toggleThemeMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  useEffect(() => {
    handleThemeMode(mode);
  }, [mode]);

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ThemeModeProvider value={themeMode}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme.palette}>
          <GlobalStyles theme={theme} />
          {props.children}
        </ThemeProvider>
      </MuiThemeProvider>
    </ThemeModeProvider>
  );
};

const mapStateToProps = state => ({ mode: state.theme.mode })

export default connect(mapStateToProps)(ThemeWrapper);