import React, { useEffect } from "react";
import {UncontrolledInput as Input} from 'components/form';
import _ from "lodash";

function Street(props) {
  const prefix = props.prefix || {
    name: "address_attributes[street]",
    id: "address_attributes_street",
  };

  useEffect(() => {
    let { street } = props.address;
    if(street) props.resetField(prefix.name, { defaultValue: street })
  }, [props.address.street])

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe a rua, avenida..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Rua"}
        InputLabelProps={!_.isEmpty(props.address) ? { shrink: !!props.address?.street } : {}} 
      />
    </div>
  );
}

export default Street;
