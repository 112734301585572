import React from "react";
import swal from "sweetalert";
import api from "services/api";

import { useParams } from "react-router-dom";
import { useEnvelope } from "pages/internship/envelopes/Show/context";

const RejectUpload = (props) => {
  const { id } = useParams();
  const { mutate } = useEnvelope();

  const rejectUpload = (action) => {
    const payload = { event: action, status: {} };
    api
      .patch(`/v1/internship/envelopes/${id}`, payload)
      .then(() => {
        swal(
          "Tudo certo!",
          "Upload rejeitado com sucesso! O estagiário deverá subir o documento corretamente dessa vez...",
          "success"
        );
        mutate();
      })
      .catch(() => {
        swal(
          "Ops!",
          "Não foi possível rejeitar o upload deste documento... tente novamente, por favor!",
          "error"
        );
      });
  };

  const handleUpdate = () => {
    swal(
      "Atenção",
      "Deseja rejeitar o upload desse documento? Caso rejeite este upload, o estagiário deverá efetuar o upload correto em nossa plataforma.",
      "warning",
      {
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Sim",
            value: true,
            closeModal: true,
          },
        },
      }
    ).then((value) => {
      if (value) rejectUpload(props.action);
    });
  };

  return (
    <button className="button button-danger" onClick={handleUpdate}>
      Rejeitar upload
    </button>
  );
};

export default RejectUpload;

