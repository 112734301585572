import React from 'react'
import { RadioButtons } from "components/form";
import _ from "lodash";

function Working(props) {
  const prefix = props.prefix || {
    name: "working",
    id: "working",
  };

  const options = [
    { value: "true", label: "Sim" },
    { value: "false", label: "Não" },
  ];

  const defaultValue = _.get(props.getValues?.(), prefix.name)?.toString() || props.defaultValue?.toString()

  return (
    <RadioButtons
      {...props}
      id={prefix.id}
      name={prefix.name}
      options={options}
      required={false}
      label={props.label || "Está trabalhando?"}
      defaultValue={defaultValue}
    />
  );
}

export default Working
