import React from "react";
import styled from "styled-components";
import { Title } from "./Show/style"

const BenefitContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 12px 52px;
  align-self: stretch;
  flex-wrap: wrap;

  p {
    color: #7F7F7F;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
`

const CardBenefits = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  flex: 1 0 0;
`

const ImageBenefit = styled.div`
  .image-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .image-container img {
    max-width: 100%;
    height: auto;
  }

  .image-container p {
    margin-top: 8px;
    font-size: 16px;
  }

  @media screen and (max-width: 700px){
    .image-container img{
      height: 6rem;
    }
  }
`

export default function Benefit(props){
  const benefits = props?.benefits

  return (
    <>
      <Title>Benefícios</Title>
      <CardBenefits>
        <BenefitContent>
          {benefits?.map(function(benefit, index){
            if (!(benefit === "" || benefit === null)){
              return (
                <React.Fragment key={index}>
                  <ImageBenefit>
                    <div className="image-container">
                      <img src={benefit.path} alt={benefit.icon} />
                      <p>{benefit.name}</p>
                    </div>
                  </ImageBenefit>
                </React.Fragment>
              )
            }
          })}
        </BenefitContent>
      </CardBenefits>
    </>
  )
}