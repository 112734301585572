import store from "@root/redux/store";
import { USERS } from "navigation/ROUTES";

export const handleUnauth = (navigate) => {
  store.dispatch({ type: "sessions/unauthUser" });
  //navigate(USERS.LOGIN);
  swal(
    "Não autorizado",
    "Você não está autorizado para essa ação ou sua sessão encerrou. Por favor, faça o login novamente.",
    "warning",
    {
      buttons: false,
      timer: 2200
    }
  )
  .then(() => window.location.href = USERS.LOGIN)
};

export const handleInternalError = () => {
  swal(
    "Algo deu errado!",
    "Por causa de um erro interno não conseguimos concluir sua solicitação! Por favor, tente novamente mais tarde!",
    "error"
  )
}
