import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import Form from "./Form";
import { EditModeContext } from "./context";
import { USERS } from "navigation/ROUTES";

const Link = styled(RouterLink)`
  font-size: 1rem;
  display: block;
  color: ${({ theme }) => theme.info[700]};
  margin-top: 10px;
`;

const Modal = (props) => {
  const { openModal, handleEditModal } = React.useContext(EditModeContext);

  if (!props.defaultValues) {
    return;
  }

  return (
    <Dialog
      open={openModal}
      onClose={() => handleEditModal()}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{ fontSize: "1.75rem", fontWeight: "bold" }}>
        Preenchimento de perfil
        <IconButton sx={{ float: "right" }} onClick={() => handleEditModal()}>
          <CloseIcon />
        </IconButton>
        {props.origin != "candidatureRequired" && <Link to={USERS.WIZARD}>ir para edição completa</Link>}
      </DialogTitle>
      <DialogContent>
        <Form {...props} />
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
