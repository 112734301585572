import React from "react";
import styled from "styled-components";

const CardBoxInfo = styled.div`
  width: 100%;

  h3 {
    transition: 0.2s linear all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      text-decoration: none;
    }
  }

  p {
    margin: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default (props) => (
  <CardBoxInfo className="card-box-info">
    <small>{props.subtitle}</small>
    <h3>{props.title}</h3>
    {props.children}
  </CardBoxInfo>
);
