import React, { useState, useEffect } from "react";
import { getCollection } from "utils/getCollection";
import { SelectInput, SearchableSelect } from "components/form";
import { useStaticCollection } from "utils/hooks";

function StateAndCity(props) {
  const [state, setState] = useState(null);
  const states = useStaticCollection("/selects/states.json")
  const [cities, setCities] = useState([]);

  const getCities = () => {
    getCollection(`/selects/${state}/cities_react.json`, setCities);
  };

  const handleChange = (state_id) => {
    setState(state_id);
  };

  useEffect(() => {
    if (state) getCities();
  }, [state]);

  return (
    <>
      <div className="col-md-3">
        <State {...props} handleChange={handleChange} collection={states} />
      </div>
      <div className="col-md-3">
        <City {...props} collection={cities} />
      </div>
    </>
  );
}

function State(props) {
  const prefix = props.prefix || {
    name: "address_attributes[state_id]",
    id: "address_attributes_state_id",
  };

  const [defaultValue, setDefaultValue] = useState(null);

  const { collection } = props;

  const setDefaultFromZipcode = () => {
    let { state_id } = props.address;
    let state = collection.find((option) => option.abbreviation == state_id) || state_id
    setDefaultValue(state);
  }

  useEffect(() => {
    setDefaultFromZipcode();
  }, [props.address.state_id]);

  useEffect(() => {
    if(defaultValue) props.resetField(prefix.name, { defaultValue: defaultValue?.value || defaultValue })
  }, [defaultValue])

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        className={props.className}
        placeholder="Selecione o estado..."
        required={props.required}
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        defaultValue={defaultValue}
        label={props.label || "Estado"}
        handleChange={props.handleChange}
      />
    </div>
  );
}

function City(props) {
  const prefix = props.prefix || {
    name: "address_attributes[city_id]",
    id: "address_attributes_city_id",
  };

  const setDefaultFromZipcode = () => {
    let { city_id } = props.address;
    let city = props.collection.find((option) => option.label == city_id) || city_id
    if(city) props.resetField(prefix.name, { defaultValue: city?.value || city })
  }

  useEffect(() => {
    setDefaultFromZipcode();
  }, [props.address.city_id, props.collection]);

  return (
    <div className="form-group">
      <SearchableSelect
        {...props}
        className={props.className}
        placeholder="Selecione a cidade..."
        required={props.required}
        name={prefix.name}
        id={prefix.id}
        collection={props.collection}
        label={props.label || "Cidade"}
      />
    </div>
  );
}

export default StateAndCity;
