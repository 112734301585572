import React from "react";
import { useEnvelope } from "../../../../context";

export default () => {
  const { envelope } = useEnvelope();

  if (!envelope.resource_is_a_signer) {
    return <h5>Aguarde pela assinatura dos assinantes deste contrato.</h5>;
  }

  if (envelope.embedded) {
    return <Embedded envelope={envelope} />;
  }

  return (
    <h5>
      Por favor, verifique seu email. O link para assinatura será enviado por
      lá quando for sua vez de assinar.
    </h5>
  );
};

const Embedded = ({envelope}) => {
  const url = envelope.docusign_url;
  const disabled = !url;

  if(!envelope.resource_is_current_signer){
    return <h5>
      Aguarde pela sua vez de assinar.
    </h5>
  }

  if (disabled) {
    return (
      <h5>
        Não foi possível gerar o link para sua assinatura. Por favor, entre em contato conosco.
      </h5>
    );
  }

  return (
    <>
      <h5>Clique no botão abaixo para ser direcionado:</h5>

      <a href={url} className="button button-primary" target="_blank">
        Assinar contrato
      </a>
    </>
  );
};
