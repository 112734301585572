import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Logout from "./Logout";
import { ThemeSwitcher } from "components/ui";
import { Button, HeaderSidebar, Icon, Info, ProfilePhoto } from "./styles";

const BottomButtons = styled.div`
  padding: 30px 0 21px 0;
  margin: auto 27px 0px 27px;
  display: flex;
  align-items: center;
  //justify-content: space-between; //changed to flex-end after turn off the theme switcher
  justify-content: flex-end;
`;

const Intern = React.lazy(() => import("./Intern"));
const Candidate = React.lazy(() => import("./Candidate"));
const PosIntern = React.lazy(() => import("./PosIntern"));

function Sidebar(props) {
  const { toggleDrawer, user } = props;

  const renderMenu = () => {
    if(user?.intern){
      return <Intern user={user} toggleDrawer={toggleDrawer} />
    } else if(user?.former_intern){
      return <PosIntern user={user} toggleDrawer={toggleDrawer}  />
    } else {
      return <Candidate user={user} toggleDrawer={toggleDrawer} />
    }
  }

  return (
    <HeaderSidebar>
      <Button onClick={toggleDrawer}>
        <Icon className="material-icons closer">close</Icon>
      </Button>
      {/* <ProfileForce>
         <p>
          Força do perfil
          <br />
          <b>Básico</b>
        </p>
      </ProfileForce> */}
      <Info>
        <ProfilePhoto>
          <img src={user?.avatar} alt={`Avatar ${user?.first_name}`} />
        </ProfilePhoto>
        <h3>{user?.first_name}</h3>
        <h4>{user?.email}</h4>
      </Info>
      {renderMenu()}
      <BottomButtons>
        {/* <ThemeSwitcher onChange={toggleDrawer} /> */}
        <Logout toggleDrawer={toggleDrawer} />
      </BottomButtons>
    </HeaderSidebar>
  );
}

function mapStateToProps(state) {
  const user = state.session?.attributes;

  return { user };
}

export default React.memo(connect(mapStateToProps)(Sidebar));
