import { useTranslation } from "react-i18next";

const formatStatusText = (settings, status) => {
  const { t } = useTranslation();

  if (
    status.status == "completed" &&
    (settings.only_approval || settings.approval)
  ) {
    return t("envelope.status.approved");
  }

  if (
    status.status == "completed" &&
    !settings.assign &&
    !status.state_name.match("_sign")
  ) {
    return t("envelope.status.finished");
  }

  return t(`envelope.status.${status.status}`);
};

export { formatStatusText };
