import React from "react";
import { useStaticCollection } from "utils/hooks";
import { SelectInput } from "components/form";

function RGState(props) {
  const collection = useStaticCollection("/selects/states.json");

  const prefix = props.prefix || {
    name: "rg_state_id",
    id: "rg_state_id",
  };

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione o estado..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Estado de Emissão do RG"}
      />
    </div>
  );
}

export default RGState;
