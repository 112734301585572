import { React, useEffect } from "react";
import Input from "components/form/NewLanding/Input";
import VMasker from "vanilla-masker";

function Cnpj(props) {
  const prefix = ((typeof props.prefix) === 'string') ? 
    {id: props.prefix, name: props.prefix}  : 
      props.prefix || {
        id: "cnpj",
        name: "cnpj",
    };



  useEffect(() => {
    VMasker(document.querySelector(".cnpj")).maskPattern("99.999.999/9999-99");
  }, [])

  return (
    <Input
      {...props}
      className={`cnpj ${props.className}`}
      placeholder="CNPJ *"
      name={prefix.name}
      id={prefix.id}
    />
  );
}

export default Cnpj;