import React from "react";
import { File } from "components/form";

function CurriculumVitae(props) {
  const prefix = props.prefix || {
    id: "curriculum_vitae",
    name: "curriculum_vitae",
  };

  return (
    <>
      <div> 
        <h3>Upload do Currículo</h3>
      </div>
      
      <div>
        <File
          {...props}
          id={prefix.id}
          name={prefix.name}
          label="Formato permitido: PDF"
          buttonLabel="Adicionar arquivo"
          accept='application/pdf'
        />
      </div>
    </>
  );
}

export default CurriculumVitae;