import React from "react";
import user from "assets/images/default-user-image.png";
import { useNavigate } from "react-router-dom";
import { ACADEMY } from "navigation/ROUTES";
import { Content, Navbar, NavLeft, NavItem, NavRight, Profile, ProfileImg } from "./styles";

export default function HeaderMenu(props) {
  const { toggleDrawer } = props;
  let navigate = useNavigate();

  return (
    <Content>
      <Navbar>
        <NavLeft />
        <NavRight>
          {/* <NavItem onClick={() => navigate(ACADEMY.INDEX)}>
              Home
            </NavItem> */}
          <div className="desktop">
            {
              props.isLogged && (
                <>
                  <NavItem onClick={() => navigate(ACADEMY.COURSE.INDEX)}>
                    Todos os Cursos
                  </NavItem>
                  <NavItem onClick={() => navigate(ACADEMY.COURSE.OWNED)}>
                    Meus Cursos
                  </NavItem>
                </>
              )
            }
            <NavItem href="https://carreiras.walljobs.com.br/" target="_blank">
              Blog
            </NavItem>
            {
              props.isLogged && (
                <NavItem>
                  <ProfileImg onClick={toggleDrawer}>
                    <Profile src={user} img="Imagem default usuário"></Profile>
                  </ProfileImg>
                </NavItem>
              )
            }
          </div>
          <div className="mobile">
            <i className="material-icons" onClick={toggleDrawer}>
              menu
            </i>
          </div>
        </NavRight>
      </Navbar>
    </Content>
  );
}