import React, { useEffect, useState } from 'react';
import IdAndDestroyFields from 'components/users/fields/shared/IdAndDestroyFields';
import LanguageId from './LanguageId';
import Level from './Level';

function LanguageAttributes(props) {
  const { item, index } = props;
  const [destroy, setDestroy] = useState(item._destroy || false);

  const removeFromList = () => {
    setDestroy(true);
  };

  useEffect(() => {
    if (destroy) props.destroyItem(item.id, index);
  }, [destroy]);

  return (
    <div className="row">
      <div className="col-md-6 form-group">
        <LanguageId {...props} />
      </div>
      <div className="col-md-6 form-group">
        <Level {...props} />
      </div>
      <div className="col-md-1 col-button">
        <button
          type="button"
          className="button-delete"
          onClick={removeFromList}
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
      <IdAndDestroyFields {...props} destroy={destroy} />
    </div>
  );
}

export default React.memo(LanguageAttributes);