import useFetch from "./useFetch";

const useStaticCollection = (request) => {
  const options = {
    revalidateOnFocus: false,
  }

  const { data } = useFetch(request, options);

  return data || [];
}

export default useStaticCollection;