import React, { useState } from "react";
import { Controller } from "react-hook-form";
import _ from "lodash";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from '@mui/styles';
import colors from "styles/colors.js";

const useStyles = makeStyles({
  root: {
    backgroundColor: "transparent",
    padding: "6px 9px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: `radial-gradient(${colors.dodgeBlue[500]}, ${colors.dodgeBlue[500]} 30%,transparent 36%)`,
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
  },
});

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      color="secondary"
      //className={classes.root}
      //checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      //icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function RadioButtons(props) {
  const [value, setValue] = useState(setDefaultValue());

  const validation = {...{required: props.required}, ...props.validation}

  const messages = {...{required: "Não pode ficar em branco"}, ...props.messages}

  const error = _.get(props.errors, props.name)

  const handleChange = (value) => {
    setValue(value);
    props.handleChange?.(value);
  };

  function setDefaultValue(){
    return props.defaultValue ||
      _.get(props.getValues?.(), props.name) ||
      null;
  };

  const renderOptions = () => {
    const options = props.options || [];
    return options.map((option, index) => (
      <FormControlLabel
        slotProps={{ typography: { variant: "body2" } }}
        className="radio-button-label"
        key={index}
        value={option.value}
        control={<StyledRadio />}
        label={option.label}
        disabled={option.disabled}
      />
    ));
  };

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={validation}
      defaultValue={props.defaultValue}
      render={({ field: { onChange } }) => (
        <FormControl
          component="fieldset"
          error={!_.isEmpty(error)}
          disabled={props.disabled}
          required={props.required}
        >
          <FormLabel className="radio-label" component="legend" color="secondary">
            {props.label}
          </FormLabel>
          <RadioGroup 
            autoFocus={props.autoFocus}
            required={props.required}
            id={props.id}
            aria-label={props.name}
            name={props.name}
            value={value}
            onChange={(event) => {
              onChange(event.target.value)
              handleChange(event.target.value)
            }}
          >
            {renderOptions()}
          </RadioGroup>
          <FormHelperText>{error?.message || messages[error?.type] || props.helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default RadioButtons;
