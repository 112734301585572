import React from 'react';
import { UncontrolledInput as Input } from 'components/form';

function Job(props) {
  const { prefix, index } = props;

  return (
    <Input
      {...props}
      placeholder="Função exercida..."
      type="text"
      name={`${prefix}[${index}][job]`}
      id={`${prefix}_${index}_job`}
      label="Cargo"
    />
  );
}

export default Job;