import styled from "styled-components";
import logow from "assets/images/logo-w.png";
import logo from "assets/images/logo-walljobs-academy-escuro.png";

//Header Menu styles
export const Content = styled.div`
  background-color: #fafafa !important;
`;

export const Navbar = styled.nav`
  width: 100%;
  height: 80px;
  background-color: #fafafa !important;
  background-image: url(${logo});
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center left 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #312185;

  @media screen and (max-width: 767px) {
    & {
      height: 60px;
      font-size: 14px;
      background-image: url(${logow});
      background-position: center left 16px;
      background-size: 45px;
      background-repeat: no-repeat;
      padding: 16px;
    }
  }
`;

export const NavLeft = styled.nav`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0 1rem 3rem;

  & :nth-child(2) {
    margin-left: 8rem;
  }
`;

export const Menu = styled.ul`
  list-style: none;
  height: fit-content;
  margin: 0;
  padding: 22px 0 0 45px;

  .mobile {
    display: none;
  }

  li a {
    font-size: 16px;
    line-height: 38px;
    display: inline-flex;
    color: #fff;
    text-decoration: none;

    i {
      color: #fff;
      font-size: 21px;
      line-height: 38px;
      padding-right: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    .mobile {
      display: block;
    }
  }
`;

export const NavItem = styled.a`
  color: rgb(49, 33, 133, .8);
  font-size: 15px;
  text-transform: uppercase;
  padding: 0 16px;
  margin: 0 8px;
  text-decoration: none;

  &:hover {
    color: #312185 !important;  
    transition: color 0.15s ease-in-out;
    cursor: pointer;
  }
`;

export const NavRight = styled.nav`
  .desktop {
    color: rgb(49, 33, 133, .8);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 24px;
  }

  .mobile {
    display: none;
  }

  @media screen and (max-width: 767px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
`;

export const ProfileImg = styled.nav``;

export const Profile = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
`;

// Sidebar Styles
export const HeaderSidebar = styled.div`
  background: no-repeat right 25px top 23px, linear-gradient(211.32deg, #3C2F7E 30%, #342584 70%);
  background-size: 74px, cover;
  height: 100%;
  width: 344px;
  transition: 0.15s ease-out all;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    & {
      width: 100vw;
      max-width: 414px;
    }
  }
`;

export const Button = styled.button`
  border: none !important;
  background: none;
  outline: none;
`;

export const Icon = styled.i`
  color: #ffffff;
  font-size: 21px;
  line-height: 21px;
  padding: 21px 0px 0px 21px;
  float: left;
`;

export const Info = styled.div`
  margin-top: 30px;

  h3 {
    font-size: 16px;
    text-align: center;
    line-height: 22px;
    font-weight: bold;
    color: #fff;
  }

  h4 {
    text-align: center;
    line-height: 10px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const BottomButtons = styled.div`
  margin: auto 27px 20px 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProfilePhoto = styled.div`
  width: 48px;
  height: 48px;
  margin: 2px auto;
  padding: 0;

  img {
    width: 48px;
    height: 48px;
    border-radius: 30px;
    margin: 0 auto;
  }
`;
