import React from 'react';
import { Checkbox } from 'components/form';

function CurrentJob(props){
  const { prefix, index } = props 

  return(
    <Checkbox
      {...props}
      id={`${prefix}_${index}_current_job`}
      name={`${prefix}[${index}][current_job]`}
      label="Trabalho atual"
      handleChange={props.handleChange}
    />
  )
}

export default CurrentJob;