import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useEnvelope } from "../context";
import { Loader } from "components/ui";

import Menu from "./Workflow/Menu";
import Stepper from "./Workflow/Stepper";
import Flow from './Workflow/Flow';

import BaseDocument from "./BaseDocument";
import SignedDocument from "./SignedDocument";
import UniversityInstructions from "./UniversityInstructions";

const components = {
  stepper: <Stepper />,
  flow: <Flow />
}

const Header = styled.div`
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  justify-content: space-between;

  h3{
    font-weight: 600;
  }

  @media screen and (max-width: 767px) {
    & {
      flex-direction: column-reverse;
      gap: 25px;
    }
  }
`

export default (props) => {
  const [component, setComponent] = useState("stepper");
  const { envelope, isLoading } = useEnvelope();
  const { t } = useTranslation();

  if(isLoading){
    return <Loader />;
  }

  return (
    <>
      <p>
        <b>Tipo de contrato:</b> {t(`contract_type.${envelope.contract_type}`)}
      </p>
      <div dangerouslySetInnerHTML={{__html: envelope.description}}></div>

      <BaseDocument />

      <Header>
        <h3>Processo de assinatura</h3>
        {!props.admin_view && <Menu component={component} changeComponent={setComponent} />}
      </Header>

      {components[component]}

      {envelope.current_state === 'completed' && <SignedDocument />}

      <UniversityInstructions />
    </>
  );
};
