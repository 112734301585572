import React, { useState, useEffect } from "react";
import { SelectInput } from "components/form";
import { useStaticCollection } from "utils/hooks";

function Country(props) {
  const collection = useStaticCollection("/selects/countries.json");
  const [defaultValue, setDefaultValue] = useState("");

  const prefix = props.prefix || {
    name: "address_attributes[country_id]",
    id: "address_attributes_country_id",
  };

  const setDefaultFromZipcode = () => {
    let { country_id } = props.address;
    let country =
      collection.find((option) => option.label || country_id) || country_id;
    setDefaultValue(country);
  };

  useEffect(() => {
    setDefaultFromZipcode();
  }, [props.address.country_id]);

  useEffect(() => {
    if (defaultValue)
      props.resetField(prefix.name, {
        defaultValue: defaultValue?.value || defaultValue,
      });
  }, [defaultValue]);

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        className={props.className}
        placeholder="Selecione o país..."
        required={props.required}
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        defaultValue={defaultValue}
        label={props.label || "País"}
      />
    </div>
  );
}

export default Country;
