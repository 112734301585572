import {React, useState, useEffect} from "react";
import { serialize } from "object-to-formdata";
import swal from "sweetalert";
import api from "services/api";
import { COMPANIES } from "navigation/ROUTES";
import { JOB_BOARD } from "navigation/ROUTES";
import { MainDiv, 
  LeftDiv, 
  InfoDiv, 
  IesLogo, 
  InfoText, 
  RightDiv, 
  Box, 
  Welcome, 
  WelcomeText, 
  AlreadyRegistered, 
  LoginHere, 
  BoxForm, 
  DivTop,
  LoginData,
  Form,
  Button,
  BoxFooter } from "./styles";
import { pathWithParams } from "utils/pathWithParams";
import { useParams } from "react-router-dom";
import logo from "assets/images/W.png";
import { Loader } from "components/ui";
import { useForm } from "react-hook-form";
import Email from "./fields/Email";
import Name from "./fields/Name";
import Password from "./fields/Password";
import ConfirmPassword from "./fields/ConfirmPassword";
import Cnpj from "./fields/Cnpj";
import CompanyName from "./fields/CompanyName";
import EmployeesNumber from "./fields/EmployeesNumber";
import Cellphone from "./fields/Cellphone";
import Sector from "./fields/Sector";
import useResetPassword from "./fields/useResetPassword";
import Website from "./fields/Website";
import axios from "axios";
import styled from "styled-components";

const ValidationMessages = styled.div`
  margin-right: auto;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    display: flex;
    gap: 3px;

    span {
      margin: 0;
      color: #ea484b;
      margin-top: 7px;
    }
  }

  p:last-of-type {
    margin-bottom: 10px;
  }
`;


export default function UseForm(props) {
  const configuration  = props.configuration || {};
  const isLoading = props.isLoading;
  const { display_name } = useParams();
  const [data, setData] = useState({});
  const [step, setStep] = useState(1);
  const [emailValidation, setEmailValidation] = useState(false);
  const [cnpjValidation, setCnpjValidation] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { state, handleChangePassword } = useResetPassword();
  const { password, password_confirmation } = state;
  const [formData, setFormData] = useState({
    // first step
    company_manager_name: "",
    company_manager_email: "",
    emailIsValid: null,
    passwordIsValid: null,
    // second step
    company_cnpj: "",
    cnpjIsValid: null,
    company_name: "",
    company_sector: "",
    number_of_interns: "",
    company_phone: "",
    company_website: ""
  });

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {    
    if (isStepOneComplete() && isStepTwoComplete() && submitted) submit();
  }, [data]);

  const submitForm = (data) => {
    $(".button:submit").attr("disabled", true);
    const { confirm_password, ...rest } = data;
    setSubmitted(true);
    setData({...rest});
  };

  const handleChange = (e) => {
    if(e == null) return;
    
    setFormData((currentFormData) => ({...currentFormData, [e.target.name]: e.target.value}));
  };
  
  const handleSectorChange = (value) => {
    if(value == null) return;
    setFormData((currentFormData) => ({...currentFormData, ['company_sector']: value.label}));
  }

  const handlePasswordChange = (event) => {
    handleChange(event);
    handleChangePassword(event)

    if (validatePassword(event) && password_confirmation.value === event.target.value) {
      setFormData((currentFormData) => ({...currentFormData, ['passwordIsValid']: true}));
    }else{
      setFormData((currentFormData) => ({...currentFormData, ['passwordIsValid']: false}));
    }
  };

  const validatePassword = (event) => {
    const regex = new RegExp(
      '^(?=.*\\d)^(?=.*[#!$@%^&*-])^(?=.*[A-Z])^(?=.*[a-z])'
    );

    if (event?.target?.value?.length >= 8 && regex.test(event?.target?.value)){
      return true;
    }
    return false;
  }

  const handlePasswordConfirmationChange = (event) => {
    handleChangePassword(event);
    if (validatePassword(event) && password.value === event.target.value) {
      setFormData((currentFormData) => ({...currentFormData, ['passwordIsValid']: true}));
    }else{
      setFormData((currentFormData) => ({...currentFormData, ['passwordIsValid']: false}));
    }
  };

  const validateEmail = (event) => {
    const email = event.target.value;
    
    if(email.length > 0){
      api.post('/v1/profile/companies/validate_company_manager_email', {email: email}).then(({data}) => {
        emailValidation === true && setFormData((currentFormData) => ({...currentFormData, ['emailIsValid']: data.valid}));
      });
    }
  };

  const handleCheckEmail = (event) => {
    handleChange(event);
    validateEmail(event);
  }

  const validateCnpj = (event) => {
    const cnpj = event.target.value;
    if(cnpj.length > 0){
      api.post('/v1/profile/companies/validate_company_cnpj', {cnpj: cnpj}).then(({data}) => {
        cnpjValidation === true && setFormData({...formData, cnpjIsValid: data.valid});
      })
    }
  }

  const showValidations = () => {
    const validations = _.flow([
      Object.entries,
      (arr) => arr.filter(([key, attrs]) => !attrs.value),
    ])(password.validations);

    return validations.map(([key, attrs]) => (
      <p key={key}>
        <span className="material-icons">close</span>
        {attrs.message}
      </p>
    ));
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const isStepOneComplete = () => {
    return formData.company_manager_name && formData.emailIsValid && formData.passwordIsValid && emailValidation;
  };

  const isStepTwoComplete = () => {
    return formData.cnpjIsValid && cnpjValidation && formData.company_name && formData.company_phone && formData.company_website;
  }

  
  const loginDataText = () => {
    return step === 1 ? "Dados de Acesso" : "Dados da empresa";
  }

  const handleLogin = (response) => {
    const href= response.headers['location'];

    swal({
      icon: 'success',
      title: 'Tudo certo',
      text: 'Cadastro realizado com sucesso.',
      showConfirmButton: false,
      timer: 2000
    }).then(() => props.type === "ies_board" ? window.location.href = href : window.location.href = "/empresas/cadastrado");
  
  }
  
  const handleError = (error) => {
    console.log(error);
  }

  const submit = () => {
    const params = serialize({ 
      company: {
        cnpj: data.company_cnpj,
        name: data.company_name,
        phone: data.company_phone,
        website: data.company_website,
        sector_id: data.company_sector,
        number_of_interns: data.company_number_of_interns,
        company_managers_attributes: {"0": {
          email: data.company_manager_email,
          name: data.company_manager_name,
          password: data.company_manager_password,
        }}
      }
    });

    const api = axios.create({ withCredentials: true });
    api
      .post(pathWithParams(props.type === "ies_board" ? JOB_BOARD.COMPANIES.POST : COMPANIES.POST, {display_name: display_name}), params)
      .then((resp) => handleLogin(resp))
      .catch((error) => handleError(error));
  }
  

  const renderButton = () => {
    if(step === 1){
      return (
        <Button disabled={!isStepOneComplete()} onClick={handleNextStep}>
          Próximo passo
        </Button>
    )}else {
      return (
        <Button className="button" disabled={!isStepTwoComplete()} type="submit">
          Finalizar cadastro
        </Button>
      )
    }
  }

  function fields(){

    if(step === 1){
      return (
        <>
          <Name register={register} isValid={formData["company_manager_name"]} errors={errors} required prefix="company_manager_name" value={formData["_name"]} onChange={handleChange} />
          <Email register={register} isValid={formData["emailIsValid"]} setEmailValidation={(e) => setEmailValidation(e) } errors={errors} required prefix="company_manager_email" onChange={handleCheckEmail} />
          <Password register={register} isValid={formData["passwordIsValid"]} errors={errors} required prefix="company_manager_password" onChange={(event) => handlePasswordChange(event)}/>
          {password.value && (
            <ValidationMessages>{showValidations()}</ValidationMessages>
          )}
          <ConfirmPassword required  isValid={formData["passwordIsValid"]} setFormDataPassword={(e) => handlePasswordConfirmationChange(e)} onChange={handlePasswordConfirmationChange} color={password_confirmation.color} error={!!password_confirmation.error} helperText={password_confirmation.error} />
        </>
      )
    }else {
      return (
        <>
          <Cnpj register={register} type="cnpj" errors={errors} isValid={formData["cnpjIsValid"]} setCnpjValidation={(e) => setCnpjValidation(e) } required prefix="company_cnpj" value={formData.company_cnpj} onChange={handleChange} onBlur={validateCnpj} />
          <CompanyName register={register} isValid={formData.company_name} errors={errors} required prefix="company_name" value={formData.company_name} onChange={handleChange} />
          <Sector control={control} register={register} placeholder="Setor" errors={errors} required  value={formData.company_sector} onChange={handleSectorChange} />
          <EmployeesNumber  type="number" register={register} errors={errors} prefix="company_number_of_interns" value={formData.number_of_interns} onChange={handleChange} />
          <Cellphone  register={register} type="phone" isValid={formData["company_phone"].length > 18} errors={errors} required prefix="company_phone" value={formData.company_phone} onChange={handleChange} />
          <Website register={register} isValid={formData["company_website"]} errors={errors} required prefix="company_website" value={formData.company_website} onChange={handleChange} />
        </>
      )
    }
  }
  
  if(isLoading){
    return <Loader />
  }

  return (
    <MainDiv>
      <LeftDiv>
        <img className={props.type} src={logo} alt="walljobs_logo"/>
        {props.type === "ies_board" && (
          <InfoDiv>
            <IesLogo src={configuration.logo} alt="university_logo" />
            <InfoText>
              Empresa, comece a postar vagas para alunos da {configuration.university_name}
            </InfoText>
         </InfoDiv>
        )}
      </LeftDiv>
      <RightDiv>
        <Box>
          <Welcome>
            <WelcomeText>Boas vindas</WelcomeText>
            <AlreadyRegistered>
              Já possui uma conta? <LoginHere href={'/gestores/entrar'}>Faça login aqui</LoginHere>
            </AlreadyRegistered>
          </Welcome>
          <BoxForm>
            <DivTop>
              <LoginData>
                {loginDataText()}
              </LoginData>
              <div>
                {step}/2
              </div>
            </DivTop>
            <Form onSubmit={handleSubmit(submitForm)}>
              {fields()}
              <BoxFooter paragraphDisplay={step === 2 && "flex"}>
                <p>
                  "Finalizar cadastro", você aceita a&nbsp;<a href="https://www.walljobs.com.br/sobre/privacidade">Política de Privacidade</a>&nbsp;e os &nbsp;<a href="https://www.walljobs.com.br/terms.pdf">Termos do WallJobs</a>.
                </p>
                {renderButton()}
              </BoxFooter>
            </Form>
          </BoxForm>
        </Box>
      </RightDiv>
    </MainDiv>
  )

}