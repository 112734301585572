import React from "react";
import { SelectInput } from "components/form";

export default (props) => {
  const prefix = props.prefix || {
    id: "marital_status",
    name: "marital_status",
  };

  const collection = [
    { value: "single", label: "Solteiro(a)"},
    { value: "married", label: "Casado(a)"},
    { value: "separated", label: "Separado(a)"},
    { value: "divorced", label: "Divorciado(a)"},
    { value: "widowed", label: "Viúvo(a)"},
  ]

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Estado civil"}
      />
    </div>
  )
}