import React from "react";
import { SelectInput } from "components/form";

function SoftwareId(props) {
  const { index, prefix } = props;

  return (
    <SelectInput
      {...props}
      placeholder="Selecione o software..."
      name={`${prefix}[${index}][software_id]`}
      id={`${prefix}_${index}_software_id`}
      collection={props.softwares}
      label={props.label || "Software"}
    />
  );
}

export default SoftwareId;
