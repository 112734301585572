import React from "react";
import { SelectInput } from "components/form";
import { useStaticCollection } from "utils/hooks";

export default (props) => {
  const states = useStaticCollection("/selects/states.json")

  const prefix = props.prefix || {
    name: "address_attributes[state_id]",
    id: "address_attributes_state_id",
  };

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione o estado..."
        name={prefix.name}
        id={prefix.id}
        collection={states}
        label={props.label || "Estado"}
      />
    </div>
  );
}