
import { SearchableSelect } from "components/form";
import { useStaticCollection } from "utils/hooks";

function Sector(props) {
  const collection = useStaticCollection("/selects/sectors.json");

  const prefix = props.prefix || {
    name: "company_sector",
    id: "company_sector",
  };

  return (
    <div style={{width: '100%', margin: 0}}>
      <SearchableSelect
        {...props}
        placeholder="Setor"
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Setor"}
      />
    </div>
  );
}

export default Sector;
