import React from "react";
import { BarChart as BaseBarChart } from "@mui/x-charts/BarChart";
import COLORS from "styles/colors.js";
import { useTheme } from "@mui/material";

const colors = [
  COLORS.dodgeBlue[400],
  COLORS.punch[500],
  COLORS.purpleHeart[400],
  COLORS.brightGray[600],
];

export default (props) => {
  const theme = useTheme();

  return (
    <BaseBarChart
      colors={colors}
      slotProps={{
        legend: { labelStyle: theme.typography.overline },
      }}
      {...props}
    />
  );
};
