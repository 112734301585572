import React from "react";
import swal from "sweetalert";
import api from "services/api";
import styled from "styled-components";

import { useParams } from "react-router-dom";
import { useEnvelope } from "pages/internship/envelopes/Show/context";

const Link = styled.a`
  display: flex;
  gap: 6px;
`;

const ApproveUpload = (props) => {
  const { id } = useParams();
  const { envelope, mutate } = useEnvelope();

  const confirmUpload = (action) => {
    const payload = { event: action, status: {} };
    api
      .patch(`/v1/internship/envelopes/${id}`, payload)
      .then(() => {
        swal(
          "Tudo certo!",
          "Upload aprovado com sucesso!",
          "success"
        );
        mutate();
      })
      .catch(() => {
        swal(
          "Ops!",
          "Não foi possível aprovar o upload deste documento... tente novamente, por favor!",
          "error"
        );
      });
  };

  const handleUpdate = () => {
    swal(
      "Atenção",
      "Deseja aprovar o upload desse documento? Ao dar sua aprovação, confirmaremos as assinaturas da universidade em nossa plataforma e o contrato passará a ser vigente.",
      "warning",
      {
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Confirmar",
            value: true,
            closeModal: true,
          },
        },
      }
    ).then((value) => {
      if (value) confirmUpload(props.action);
    });
  };

  const renderUpload = () => {
    return envelope.signed_document.map((item) => (
      <Link href={item.url} key={item.filename}>
        <i className="material-icons-outlined">file_download</i>{" "}
        {item.filename}
      </Link>
    ));
  };

  return (
    <>
      <h3>Upload do contrato:</h3>
      {renderUpload()}

      <button className="button button-info" onClick={handleUpdate}>
        Aprovar upload
      </button>
    </>
  );
};

export default ApproveUpload;

