import styled from "styled-components";
import walljobs from "assets/images/walljobs.png";

export const HeaderSidebar = styled.div`
  background: url(${walljobs}) no-repeat right 25px top 23px;
  background-size: 74px;
  min-height: 100vh;
  width: 344px;
  transition: 0.15s ease-out all;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    & {
      width: 100vw;
      max-width: 414px;
    }
  }
`;

export const Icon = styled.i`
  color: #ffffff;
  font-size: 21px;
  line-height: 21px;
  padding: 21px 0px 0px 21px;
  float: left;
`;

export const Button = styled.button`
  border: none !important;
  background: none;
  outline: none;
`;

export const Info = styled.div`
  margin-top: 30px;

  h3 {
    font-size: 16px;
    text-align: center;
    line-height: 22px;
    font-weight: bold;
    color: #fff;
  }

  h4 {
    text-align: center;
    line-height: 10px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const ProfilePhoto = styled.div`
  width: 48px;
  height: 48px;
  margin: 2px auto;
  padding: 0;

  img {
    width: 48px;
    height: 48px;
    border-radius: 30px;
    margin: 0 auto;
  }
`;

export const ProfileForce = styled.div`
  padding-top: 10px;
  height: 60px;

  p {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    font-weight: 600;
    color: #ffffff;
    margin-right: 18px;
    b {
      font-size: 16px;
      line-height: 22px;
      color: #f8e71c;
    }
  }
`;

export const Menu = styled.ul`
  list-style: none;
  height: fit-content;
  margin: 0;
  padding: 22px 0 0 45px;

  li a {
    font-size: 16px;
    line-height: 38px;
    display: inline-flex;
    color: #fff;
    text-decoration: none;

    i {
      color: #fff;
      font-size: 21px;
      line-height: 38px;
      padding-right: 10px;
    }
  }
`;
