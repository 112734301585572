import React from 'react';
import {UncontrolledInput as Input} from 'components/form';

function Number(props) {
  const prefix = props.prefix || {
    name: "address_attributes[number]",
    id: "address_attributes_number"
  }

  const validation = {
    pattern: /\d+/
  }

  const messages = {
    pattern: "Número inválido"
  }

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe o número..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Número"}
        validation={validation}
        messages={messages}
      />
    </div>
  )
}

export default Number
