import React from 'react';
import { SearchableSelect } from 'components/form';

function EducationType(props) {
  const { prefix, index } = props;
  return (
    <div className="form-group">
      <SearchableSelect
        {...props}
        id={`${prefix}_${index}_education_type_id`}
        name={`${prefix}[${index}][education_type_id]`}
        collection={props.educationTypes}
        placeholder="Selecione o tipo de formação..."
        label="Tipo de Formação"
      />
    </div>
  );
}

export default EducationType;
