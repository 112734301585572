import React from "react";
import { UncontrolledInput as Input } from "components/form";

function Description(props) {
  const { prefix, item, index } = props;

  return (
    <div className="col-xs-12 col-sm-12 col-md-4 disability-description">
      <div className="form-group">
        <Input
          {...props}
          placeholder="Em poucas palavras, descreva sua deficiência e/ou informe alguma necessidade referente a ela..."
          type="text"
          multiline
          rows={4}
          name={`${prefix}[${index}][description]`}
          id={`${prefix}_${index}_description`}
          label={props.label || "Descrição da Deficiência"}
        />
      </div>
      <br />
    </div>
  );
}

export default Description;
