import React from "react";
import { Alert, AlertTitle, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default React.forwardRef((props, ref) => {
  return (
    <Alert ref={ref} {...props} iconMapping={{ success: <CheckCircleOutlineIcon />}}>
      {props.title && <AlertTitle variant="subtitle2">{props.title}</AlertTitle>}
      <Typography variant="caption">{props.description}</Typography>
    </Alert>
  )
})