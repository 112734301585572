import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import VerifiedIcon from '@mui/icons-material/Verified';

const Text = styled.small`
  font-weight: 600;
  text-align: center;
  display: block;
  width: 100%;
  background-color: #0095ff;
  color: #fafafa;
  padding: 3px 4px 3px 4px;
  margin-bottom: -15px;
  border-radius: 4px 4px 0px 0px;
  z-index: 2;
`

function mapStateToProps(state) {
  const user = state.session?.attributes;

  return { user };
}

export default connect(mapStateToProps)((props) => {
  const { job } = props;

  if (!job.program_exclusive) return;

  return (
    <Text><VerifiedIcon fontSize='small' sx={{marginRight: '5px'}} /> Faz parte de um Programa de estágio</Text>
  )
});
