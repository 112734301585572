import React, { useState } from "react";
import { Collapse, Paper } from "@mui/material";
import styled from "styled-components";
import SearchIcon from '@mui/icons-material/Search';
import colors from "styles/colors.js";

const Filters = styled(Paper)`
  &.filters{
    margin-bottom: 20px;
    width: 100%;
    min-height: 83px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
  }
 
  div#collapse-filters {
    width: 100%;
    height: auto;

    div.MuiFormControl-root {
      width: 250px;
    }
  }

  @media screen and (max-width: 767px) {
    &.filters{
      margin-bottom: 0px;
      border-radius: 0;
      background-color: ${({theme}) => theme.mode === "light" ? "#f8f8f8" : "#3B4459"};
      background-image: none;
      box-shadow: none;
      min-height: 40px;
      padding: 10px 16px;
    }

    div#collapse-filters {
      div.MuiFormControl-root {
        width: 100%;
      }

      button.MuiButtonBase-root {
        float: right;
        margin: 15px 0px 10px 0px !important;
      }
    }
  }
`;

const FiltersInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 
    "total button"
    "labels labels";
  row-gap: 8px;

  p {
    grid-area: total;
    color: #959aad;
    margin-bottom: 0;

    i {
      color: ${colors.dodgeBlue[500]};
      margin-left: 5px;
      position: relative;
      top: 3px;
    }
  }

  div.labels {
    grid-area: labels
  }

  div.labels label:last-of-type{
    margin-bottom: 12px;
  }

  label {
    background-color: ${colors.dodgeBlue[500]};
    color: white;
    font-size: 13px;
    padding: 3px 5px;
    border-radius: 4px;
    margin-right: 7px;
    margin-bottom: 0;
    margin-top: 5px;

    i {
      font-size: 12px;
      margin-left: 4px;
      position: relative;
      top: 2px;
      cursor: pointer;
    }
  }

  a {
    grid-area: button;
    color: ${colors.dodgeBlue[500]} !important;
    font-size: 12px;
    text-decoration: none!important;
    margin-left: auto;
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    & {
      a {
        align-self: auto;
      }
    }
  }
`;

const Search = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  height: 54px;
  flex-direction: column;

  @media (max-width: 768px){
    width: 100%;
    align-items: center;
  }
`

const Form = styled.form`
  display: flex;
  gap: 15px;
  align-items: flex-end;

  @media (max-width: 768px){
    flex-direction: column;
  }
`

export default (props) => {
  const isMobile = window.screen.width < 720;
  const [expanded, setExpanded] = useState(!isMobile);


  return (
    <Filters className="filters">
      <FiltersInfo className="filters-info">
        <p>
          Exibindo {props.length} de {props.totalCount}
          <i className="material-icons">south_east</i>
        </p>
        <div className="labels">{props.renderFilters()}</div>
        {isMobile && 
          <a
            role="button"
            onClick={() => setExpanded(!expanded)}
            aria-expanded={expanded}
          >
            adicionar filtros
          </a>
        }
      </FiltersInfo>
      <Collapse sx={{ width: "100%" }} in={expanded} timeout="auto" unmountOnExit>
        <div id="collapse-filters">
          <Form onSubmit={(event) => event.preventDefault()}>
            {props.children}
            <Search>
              <SearchIcon style={{cursor: "pointer", width: "30px", height: "30px"}} />
            </Search>
          </Form>
        </div>
      </Collapse>
    </Filters>
  );
};
