import React, { useState } from "react";
import styled from "styled-components";
import { File } from "components/form";

const Image = styled.img`
  width: 230px;
  height: 230px;
  object-fit: cover;
`

function Avatar(props) {
  const [preview, setPreview] = useState(null);

  const handleChange = (event) => {
    let picture = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(picture);

    reader.onloadend = function(e){
      setPreview(reader.result);
    }
  }

  return (
    <div className="form-group" style={{ width: "fit-content" }}>
      <ProfilePicture {...props} defaultValue={preview} />
      <ProfileField {...props} handleChange={handleChange} />
    </div>
  );
}

function ProfilePicture(props) {

  const defaultValue =
    props.defaultValue || props.getValues?.("avatar_path");

  return(
    <Image className="avatar" src={defaultValue?.src || defaultValue} alt="Foto de Perfil" />
  )
}

function ProfileField(props) {
  const prefix = props.prefix || {
    name: "avatar",
    id: "avatar",
  };

  return (
    <div>
      <File
        {...props}
        id={prefix.id}
        name={prefix.name}
        buttonLabel="Atualizar"
        label={props.label || "Foto de Perfil"}
        accept="image/*"
        onChange={props.handleChange}
      />
    </div>
  );
}

export default Avatar;
