import React from "react";
import { BasePassword as Input } from "components/form";

function Password(props) {
  const prefix = props.prefix || {
    id: "password",
    name: "password",
  };

  return (
    <Input
      {...props}
      id={prefix.id}
      name={prefix.name}
      requiredText="Este campo é obrigatório. Sua senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, um número e um caractere especial."
    />
  );
}

export default Password;
