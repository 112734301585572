import React from "react";
import { SelectInput } from "components/form";
import { useStaticCollection } from "utils/hooks";

function Ethnicity(props) {
  const prefix = props.prefix || {
    id: "ethnicity_id",
    name: "ethnicity_id",
  };

  const collection = useStaticCollection("/selects/ethnicities.json");

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione sua etnia/cor..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Etnia/Cor"}
      />
    </div>
  );
}

export default Ethnicity;
