import React, { useEffect } from 'react';
import { UncontrolledInput as Input } from 'components/form';
import VMasker from "vanilla-masker";

function StartDate(props) {
  const { prefix, index } = props;

  const validation = {
    pattern: /([0][1-9]|[1][0-2])\/(19|20)\d{2}$/
  };

  const messages = {
    pattern: "Não segue o padrão MM/AAAA ou não é uma data válida",
  };

  useEffect(() => {
    VMasker(document.querySelectorAll(".start_date")).maskPattern("99/9999");
  }, []);

  return (
    <Input
      {...props}
      className="start_date"
      placeholder="MM/AAAA"
      type="text"
      id={`${prefix}_${index}_start_date`}
      name={`${prefix}[${index}][start_date]`}
      label="Início"
      validation={validation}
      messages={messages}
    />
  );
}

export default StartDate;