import React from "react";
import styled from "styled-components";
import { CardBoxLabel, Label } from "components/ui";

const Infos = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ({ job }) => (
  <CardBoxLabel>
    <Infos>
      <small style={{ fontWeight: "bold", color: "inherit"}}>{job.salary}</small>
      <small>
        <i className="material-icons">access_time</i> {job.days_to_close}
      </small>
    </Infos>
    <Label>{job.job_type}</Label>
  </CardBoxLabel>
);
