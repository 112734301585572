import React, { cloneElement, useEffect, useState } from "react";

import { useEnvelope } from "../../context";

import Sign from "./Template/Sign";
import Confirm from "./Template/Confirm";
import UploadBaseContract from "./Template/UploadBaseContract";
import UploadContract from "./Template/UploadContract";
import Download from "./Template/Download";
import Await from "./Template/Await";
import Reject from "./Template/Reject";
import Steps from "./Template/Steps";
import ApproveUpload from "./Template/ApproveUpload";
import RejectUpload from "./Template/RejectUpload";
import { Action, ActionWrapper } from "./Template/styles";

const types = {
  sign: <Sign />,
  await: <Await />,
  confirm: <Confirm />,
  upload_base_contract: <UploadBaseContract />,
  upload_contract: <UploadContract />,
  download: <Download />,
  reject: <Reject />,
  approve_upload: <ApproveUpload />,
  reject_upload: <RejectUpload />
};

export default () => {
  const [groups, setGroups] = useState([]);
  const { envelope } = useEnvelope();
  const { workflow, current_state, current_state_events } = envelope;

  const getActions = (events) => {
    if (current_state == "rejected") {
      return (
        <ActionWrapper>
          <Action>
            <h5>
              Esse contrato foi rejeitado durante o processo de assinatura.
            </h5>
          </Action>
        </ActionWrapper>
      );
    }

    return events.map((item) => {
      const {description, event} = item;
      const actionComponent = types[event.meta.action];
      const canShowAction = actionComponent && (event.meta.actor == event.scope || event.scope === "walljobs");

      return (
        <ActionWrapper>
          <Action>
            <h5>{description}</h5>
          </Action>

          {canShowAction && (
            <Action>
              {cloneElement(actionComponent, {
                action: event.name,
                event: event,
              })}
            </Action>
          )}
        </ActionWrapper>
      );
    });
  };

  useEffect(() => {
    const createGroups = () => {
      let groups = [];

      workflow.flow.forEach((item) => {
        let key = Object.keys(item)[0];
        let data = {
          ...item[key],
          action: key,
          events: current_state == key ? current_state_events : [],
        };
        let group = { id: data.meta.group, label: data.label_group, items: [] };
        let lastGroup = groups[groups.length - 1];

        if (group.id != lastGroup?.id) {
          group.items.push(data);
          groups.push(group);
        } else {
          group = lastGroup;
          group.items.push(data);
        }
      });

      groups.forEach((group) => {
        group.current_group = group.items.some(
          (item) => item.action == current_state
        );

        group.rejected = group.items.some((item) =>
          item.statuses.some((status) => status.status == "declined")
        );
      });

      setGroups(groups);
    };

    createGroups();
  }, [current_state, workflow]);

  return (
    <>
      <Steps groups={groups} />

      {getActions(current_state_events)}
    </>
  );
};
