const getInitialState = () => {
  return JSON.parse(localStorage.getItem("theme")) || {};
}

const initialState = getInitialState();

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case "theme/updateMode":
      localStorage.setItem("theme", JSON.stringify({...state, mode: action.payload}))
      return { ...state, mode: action.payload };
    default:
      return state;
  }
}
