import AuthorizeWhatsapp from "./AuthorizeWhatsapp";
import AutoEnroll from "./AutoEnroll";
import CellPhone from "./CellPhone";
import CPF from "./CPF";
import DateOfBirth from "./DateOfBirth";
import Email from "./Email";
import Ethnicity from "./Ethnicity";
import Gender from "./Gender";
import Sex from "./Sex";
import MaritalStatus from "./MaritalStatus";
import Name from "./Name";
import Working from "./Working";
import Nationality from "./Nationality";
import JobInterests from "./JobInterests";

export {
  AuthorizeWhatsapp,
  AutoEnroll,
  CellPhone,
  CPF,
  DateOfBirth,
  Email,
  Ethnicity,
  Gender,
  Sex,
  MaritalStatus,
  Name,
  Working,
  Nationality,
  JobInterests
}