import React from "react";

import { TakingTestHeaderContainer, LogoImage } from "./styles";
import logo from "assets/images/walljobs_logo.png";

function TakingTestHeader() {
  return (
    <TakingTestHeaderContainer>
      <LogoImage src={logo} alt="Logo Walljobs" className="logo" />
    </TakingTestHeaderContainer>
  );
}

export default TakingTestHeader;
