export default {
  attributes: {
    full_name: "Nome completo",
    email: "E-mail",
    social_name: "Nome social",
    ethnicity: "Etnia/Cor",
    ethnicity_id: "Etnia/Cor",
    current_semester: "Semestre atual",
    working: "Está trabalhando?",
    certifications: "Certificações",
    abilities: "Certificações",
    gender: "Gênero",
    gender_id: "Gênero",
    date_of_birth: "Data de nascimento",
    cellphone: "Celular",
    skype: "Skype",
    linkedin: "Linkedin",
    facebook: "Facebook",
    instagram: "Instagram",
    university: "Universidade",
    university_id: "Universidade",
    course: "Curso",
    course_id: "Curso",
    formation_date: "Data de Formação",
    course_start_date: "Data de início do curso",
    matriculation: "Número de matrícula",
    course_situation: "Situação do curso",
    avatar: "Foto de perfil",
    terms: "Termos e Condições",
    summary: "Carta de apresentação",
    auto_enroll: "Autoriza ser pré-selecionado?",
    authorize_whatsapp: "Contato via whatsapp",
    mother_name: "Nome da mãe",
    cpf: "CPF",
    rg: "RG",
    rg_state: "Estado de emissão do RG",
    rg_state_id: "Estado de emissão do RG",
    academic_entities: "Entidades acadêmicas",
    academic_entity_ids: "Entidades acadêmicas",
    expected_salary: "Pretensão salarial",
    interesting_fact: "Fato interessante",
    motivations: "Paixões/Motivações",
    hobbies: "Hobbies",
    phone: "Telefone fixo",
    message_phone: "Telefone p/ recado",
    disability: "Pessoa com deficiência",
    interests: "Áreas em que gostaria de trabalhar",
    skills: "Habilidades",
    period: "Período de estudo",
    user_languages: "Idiomas",
    user_softwares: "Softwares",
    address: "Endereço",
    portfolios: "Sites/Portfólios",
    business_email: "E-mail corporativo",
    curriculum_vitae: "Currículo",
    marital_status: "Estado civil",
    sexual_orientation: "Orientação sexual",
    job_interests: "Procura por",
  },
};
