import React from 'react';
import Section from './Section';
import Address from 'components/users/fields/personal/address/Address';

function AddressSection(props) {
  return (
    <Section title="Endereço">
      <Address {...props} />
    </Section>
  );
}

export default AddressSection;