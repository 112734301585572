import React from "react";
import { SelectInput } from "components/form";

export default (props) => {
  const prefix = props.prefix || {
    id: "sexual_orientation",
    name: "sexual_orientation",
  };

  const collection = [
    { value: "heterosexual", label: "Heterossexual"},
    { value: "homosexual", label: "Homossexual"},
    { value: "bisexual", label: "Bissexual"},
    { value: "asexual", label: "Assexual"},
    { value: "pansexual", label: "Pansexual"},
    { value: "not_respond", label: "Prefiro não responder"},
  ];

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Orientação sexual"}
      />
    </div>
  )
}