import React from "react";
import { Content, Title, BannerStyle, LogoStyle} from "./style"
import { Link } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";
import { COMPANIES } from "navigation/ROUTES";
import Faq from "../Faq"
import Step from "../Step"
import Benefit from "../Benefit";
import JobProgram from "../JobProgram";

export default function Body(props){
  const internship_program = props?.internship_program || props?.data?.internship_program;
  const jobs = props.data?.jobs?.data || props?.jobs;

  const renderSteps = () => {
    if (internship_program?.steps.length != 0){
      return <Step steps={internship_program?.steps}/>
    }
  }

  const renderBenefits = () => {
    if (internship_program?.benefits.length >= 2){
      return <Benefit benefits={internship_program?.benefits} />
    }
  }

  const renderLogo = () => {
    if (internship_program?.logo != null){
      return <LogoStyle src={internship_program.logo} alt={internship_program?.title} />
    }
  }

  const handleCompanySubmit = () => {
    window.location.href = pathWithParams(COMPANIES.SHOW, { id: internship_program?.company_slug })
  }

  return(
    <>
      <Content>
        <BannerStyle src={internship_program?.banner} alt={internship_program?.title} />
        <div className="container">
          <div className="program-info">
            <div className="content">
              <div className="contant-header">
                <div className="image">
                  {renderLogo()}
                </div>
                
                <div className="content-info">
                  <h1 className="program_title">{internship_program?.title}</h1>
                  <h4>{internship_program?.company_name}</h4>
                  <h5>Inscrições até {internship_program?.close_enrollment_at}</h5>
                  <h5>{internship_program?.address}</h5>
                </div>
              </div>

              <div className="btn-company">
                <Link
                  className="button button-info"
                  onClick={() => handleCompanySubmit()}
                >
                  Página da empresa
                </Link>
              </div>
            </div>

            <div className="about-program">
              <h2>Sobre o programa de estágio</h2>
              <div dangerouslySetInnerHTML={{__html: internship_program?.description}}>
              </div>
            </div>
          </div>

          <div className="card-program" >
            <section className="job-content">
              <JobProgram jobs={jobs} />                  
            </section>
          </div>

          <section className="company">
            <div className="card-program" >
              <div className="card-company">
                <div className="columns">
                  <div className="skills">
                    <Title>Habilidades esperadas</Title>
                    <div className="text" dangerouslySetInnerHTML={{ __html: internship_program?.skill }}></div>
                  </div>

                  <div className="about-company">
                    <Title>Sobre a empresa</Title>
                    <div className="text"
                    dangerouslySetInnerHTML={{ __html: internship_program?.about_company }}></div>
                  </div>
                </div>

                <div>
                  {renderSteps()}
                </div>

                <div>
                  {renderBenefits()}
                </div>

                <div>
                  <Faq faq={internship_program?.faq}/>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Content>
    </>
  )
}
