import React, { useState } from "react";
import Modal from "./Modal";
import "./style.scss";

export default (props) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    swal("Atenção", "Deseja mesmo rejeitar esse contrato?", "warning", {
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: "Sim",
          value: true,
          closeModal: true,
        },
      },
    }).then((value) => {
      if (value) setOpenModal(true);
    });
  };

  return (
    <>
      <button className="button button-danger" onClick={handleClick}>
        Rejeitar contrato
      </button>

      <Modal openModal={openModal} setOpenModal={setOpenModal} {...props} />
    </>
  );
};
