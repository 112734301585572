import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import loading from "assets/images/loading.png";
import logo from "assets/images/logo_roxo.png";
import Nav from './Nav';
import { USERS } from 'navigation/ROUTES';

const Header = styled.header`
  width: 100%;
  min-height: 60px;
  background-image: url(${loading});
  background-size: 45px;
  background-repeat: no-repeat;
  background-position: top 5px center;
  background-color: #7338c4;
  display: grid;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  font-weight: 400;
  font-size: 14px;

  a.open {
    font-size: 32px;
    color: ${({theme}) => theme.mode === "light" ? "#7338c4" : "#f8f8f8"};
    display: none;
    text-align: right;
    margin-right: 15px;
  }

  @media screen and (max-width: 767px) {
    & {
      height: 60px;
      background-image: url(${({theme}) => theme.mode === "light" ? logo : loading});
      background-color: ${({theme}) => theme.mode === "light" ? "#f8f8f8" : "#7338c4"};
      background-size: 40px;
      background-position: center left 13px;

      a.open {
        display: block;
        grid-column: 2;
      }
    }
  }
`;

function HeaderMenu(props) {
  const { toggleDrawer, isLogged } = props;

  return (
    <Header className="profile-header">
      {isLogged ? (
        <a className="open" onClick={toggleDrawer}>
          <i className="material-icons">menu</i>
        </a>
      ) : (
        <Link to={USERS.LOGIN} className="open">
          <i className="material-icons">login</i>
        </Link>
      )}
      <Nav toggleDrawer={toggleDrawer} />
    </Header>
  );
}

function mapStateToProps(state) {
  const { isLogged } = state.session;

  return { isLogged };
}

export default React.memo(connect(mapStateToProps)(HeaderMenu));
