import React, { useEffect } from "react";
import { UncontrolledInput as Input } from "components/form";
import VMasker from "vanilla-masker";
import _ from "lodash";

function CPF(props) {
  const prefix = props.prefix || {
    id: "cpf",
    name: "cpf",
  };

  const validation = { minLength: 14, pattern: /\d{3}\.\d{3}\.\d{3}-\d{2}/ };

  const messages = {
    minLength: "CPF inválido",
    pattern: "CPF inválido",
  };

  useEffect(() => {
    VMasker(document.querySelector(".cpf")).maskPattern("999.999.999-99");
  }, []);

  return (
    <div className="form-group">
      <Input
        {...props}
        className={`${props.className} cpf`}
        placeholder="___.___.___-__"
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "CPF"}
        validation={validation}
        messages={messages}
      />
    </div>
  );
}

export default CPF;
