import React from "react";
import { UncontrolledInput as Input } from "components/form";

function InterestingFact(props) {
  const prefix = props.prefix || {
    name: "interesting_fact",
    id: "interesting_fact",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Conte para nós uma situação inusitada, algo que você fez e que foi incrível ou qualquer outro fato interessante!"
        type="text"
        multiline
        rows={4}
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Fato Interessante"}
      />
    </div>
  );
}

export default InterestingFact;
