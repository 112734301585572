import React, { useState } from "react";
import { Collapse } from "@mui/material";
import { ExpandMore } from "components/ui";

function Section(props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <section className="section-fields">
      <div className="col-md-12">
        <h3>
          {props.title}
          <ExpandMore expanded={expanded} setExpanded={setExpanded} hideInDesktop />
        </h3>
      </div>

      <Collapse
        sx={{
          width: "100%",
          "@media screen and (min-width: 768px)": {
            height: "fit-content",
            overflow: "initial",
            visibility: "initial",
          },
        }}
        in={expanded}
        timeout="auto"
      >
        <div className="section-content">{props.children}</div>
      </Collapse>
    </section>
  );
}

export default Section;
