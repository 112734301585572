import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

const CustomCircularProgress = withStyles({
  circle: {
    color: "#6DE0C6",
  },
})(CircularProgress);

export default (props) => (
  <Box position="relative" display="inline-flex">
    <CustomCircularProgress variant="determinate" {...props} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="caption" component="div">
        {props.text}
      </Typography>
    </Box>
  </Box>
);
