import styled from "styled-components";

export const TakingTestHeaderContainer = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 70px;
  width: 100%;
  color: #404555;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 1px;
  z-index: 2;
`;

export const LogoImage = styled.img`
  display: flex;
  width: 118.695px;
  height: 40px;
`;

export const Logo = styled.div`
  .logo {
    max-width: 100%;
    height: auto;
  }
`;
