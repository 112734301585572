import React from "react";
import Job from "pages/ats/jobs/shared/Job";
import BackBreadcrumb from "pages/ats/jobs/Show/BackBreadcrumb"
import styled from "styled-components";
import { Title } from "./Show/style"

const CardJob = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  box-sizing: border-box;
  flex: 0 1 calc(50% - 12px);

  @media screen and (max-width: 1180px) {
    grid-template-columns: 100%;
    grid-gap: 16px;
    margin-top: 2px;
  }
`

export default function JobProgram(props){
  const jobs = props.jobs || []
  
  return (
    <>
      <Title>Vagas de Estágio</Title>
      <BackBreadcrumb />
      <CardJob>
        {jobs?.map(function(job, index){
          return (
            <React.Fragment key={index}>
              <Job item={job}/>
            </React.Fragment>
          )
        })}
      </CardJob>
    </>
  )
}