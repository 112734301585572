import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import _ from "lodash";
import Autocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const filter = createFilterOptions();

export default function FreeSoloCreateOption(props) {
  const defaultValue = setDefaultValue();
  const [value, setValue] = useState([]);

  const validation = {...{required: props.required}, ...props.validation}

  const messages = {...{required: "Não pode ficar em branco"}, ...props.messages}

  const error = _.get(props.errors, props.name)

  function setDefaultValue(){
    return props.defaultValue?.value ||
      props.defaultValue ||
      _.get(props.getValues?.(), props.name)  ||
      "";
  };

  useEffect(() => {
    setValue(findDefaultValues() || []);
  }, [props.collection, defaultValue]);

  useEffect(() => {
    props.onChange?.(value);
  }, [value]);

  function findDefaultValues(){    
    let value;
    if(props.multiple){
      value = defaultValue.map?.((value) => {
        return props.collection.find((option) => option?.value == (value?.value || value));
      });
    } else {
      value = props.collection.find((option) => option?.value == (defaultValue?.value || defaultValue))
    }

    return value || defaultValue;
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={validation}
      defaultValue={props.sanitizeValue?.(defaultValue) || defaultValue}
      render={({ field: { onChange } }) => (
        <Autocomplete
          fullWidth
          multiple={props.multiple}
          value={value}
          onChange={(event, newValue) => {
            onChange(
              props.sanitizeValue?.(newValue) ||
                newValue?.inputValue ||
                newValue
            );
            if (typeof newValue === "string") {
              setValue({
                value: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setValue({
                value: newValue.inputValue,
              });
            } else {
              setValue(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== "") {
              filtered.push({
                value: params.inputValue,
                label: `Adicionar "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          filterSelectedOptions
          id={props.id}
          options={props.collection}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") return option;
            // Add "xxx" option created dynamically
            if (option?.value) return option.value;
            // Regular option
            return option?.label || "";
          }}
          // renderOption={(props, option) => {
          //   if(typeof option === "string") return option;

          //   return option?.label;
          // }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              required={props.required}
              label={props.label}
              placeholder={props.placeholder}
              error={!_.isEmpty(error)}
              helperText={error?.message || messages[error?.type] || props.helperText}
            />
          )}
        />
      )}
    />
  );
}
