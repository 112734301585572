import React from "react";
import Header from "components/ui/academy/header/Header";
import Footer from "components/ui/academy/footer/Footer";
import Content from "components/ui/academy/content/Content";

export default ({ element }) => (
  <>
    <Header />
      <Content>
        {element}
      </Content>
    <Footer />
  </>
);
