import React from "react";
import { useNestedAttributes, useStaticCollection } from "utils/hooks";
import EducationAttributes from "./EducationAttributes";

function Education(props) {
  const educationTypes = useStaticCollection("/selects/education_types.json")

  const prefix = props.prefix || "educations_attributes";
  
  const { list, addItem, destroyItem } = useNestedAttributes({ ...props, prefix });

  const renderEducation = () => {
    return list?.map((item, index) => {
      const properties = {
        ...props,
        prefix,
        item,
        index,
        educationTypes,
        destroyItem,
      };
      return <EducationAttributes {...properties} key={item?.id || item?.key} />;
    });
  };

  return (
    <div>
      {renderEducation()}
      <div className="add-button">
        <button type="button" onClick={addItem}>
          adicionar <i className="fa fa-plus"></i>
        </button>
      </div>
    </div>
  );
}

export default Education;
