import React from 'react';
import { UncontrolledInput as Input } from 'components/form';

function Description(props) {
  const { prefix, index } = props;

  return (
    <Input
      {...props}
      placeholder="Fale um pouco mais sobre a função que você exerceu..."
      rows="4"
      multiline
      name={`${prefix}[${index}][description]`}
      id={`${prefix}_${index}_description`}
      label="Descrição do trabalho"
    />
  );
}

export default Description;