import React, { useEffect } from "react";
import { UncontrolledInput as Input } from "components/form";
import VMasker from "vanilla-masker";

function ExpectedSalary(props) {
  const prefix = props.prefix || {
    name: "expected_salary",
    id: "expected_salary",
  };

  useEffect(() => {
    VMasker(document.querySelector(".money")).maskMoney({
      precision: 2,
      separator: ",",
      delimiter: ".",
      zeroCents: false,
    });
  }, []);

  return (
    <div className="form-group">
      <Input
        {...props}
        className={`${props.className} money`}
        placeholder="Informe sua pretensão salarial..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Pretensão salarial"}
        control={props.control}
      />
    </div>
  );
}

export default ExpectedSalary;
