import React from "react";
import swal from "sweetalert";
import api from "services/api";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import { useEnvelope } from "pages/internship/envelopes/Show/context";

const Download = (props) => {
  const { id } = useParams();
  const { mutate } = useEnvelope();
  const { t } = useTranslation();

  const updateDownload = (action) => {
    const payload = { event: action, status: {} };
    api
      .patch(`/v1/internship/envelopes/${id}`, payload)
      .then(() => {
        mutate();
      })
  }
  
  const handleClick = () => {
    const message = t(`envelope.workflow.actions.download.${props.event.scope}`);

    swal(
      "Atenção!", message, "warning",
      {
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Continuar",
            value: true,
            closeModal: true,
          },
        },
      }
    ).then((value) => {
      if (value){
        if(!props.ignoreCall)
          updateDownload(props.action);
    
        window.location = props.event.extra.link;
      }
    });
  };

  return (
    <button className="button button-primary" onClick={handleClick}>
      Baixar contrato
    </button>
  );
};

function mapStateToProps(state) {
  const user = state.session?.attributes;

  return { user };
}

function mergeProps(stateProps, dispatchProps, ownProps){
  return { ...stateProps, ...ownProps };
}

export default React.memo(connect(mapStateToProps, null, mergeProps)(Download));

