import {React, useEffect, useState} from "react";
import Input from "components/form/NewLanding/Input";

function ConfirmPassword(props) {
  const [value, setValue] = useState("");
  const prefix = ((typeof props.prefix) === 'string') ? 
    {id: props.prefix, name: props.prefix}  : 
      props.prefix || {
        id: "password_confirmation",
        name: "password_confirmation",
      };


  return (
    <Input 
      {...props}
      placeholder="Confirmar senha"
      name={prefix.name}
      type={"password"}
      id={prefix.id}
      helperText={props.helperText}
      color={props.color}
    />
  )
}

export default ConfirmPassword;
