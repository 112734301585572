import React from "react";
import { SearchableSelect } from "components/form";
import { useStaticCollection } from "utils/hooks"; 

export default (props) => {
  const collection = useStaticCollection("/selects/job_interests.json")

  const prefix = props.prefix || {
    id: "job_interests",
    name: "job_interests",
  };

  const sanitizeValue = (values) => {
    return values.map?.((option) => option?.value || option) || [];
  };

  return (
    <div className="form-group">
      <SearchableSelect
        {...props}
        id={prefix.id}
        name={prefix.name}
        collection={collection}
        placeholder="Selecione..."
        label={props.label || "O que você procura no WallJobs?"}
        sanitizeValue={sanitizeValue}
        multiple
      />
    </div>
  ) 
}