const colors = {
  punch: {
    50: "#FFF1F1",
    100: "#FFE0DF",
    200: "#FFC7C5",
    300: "#FFA09D",
    400: "#FF6965",
    500: "#FE3B35",
    600: "#E11812",
    700: "#C7130E",
    800: "#A41410",
    900: "#881714",
    950: "#4A0705",
  },
  dodgeBlue: {
    50: "#EFF8FF",
    100: "#DEF0FF",
    200: "#B6E3FF",
    300: "#75CEFF",
    400: "#2CB5FF",
    500: "#009BF6",
    600: "#007BD4",
    700: "#0062AB",
    800: "#00538D",
    900: "#064574",
    950: "#043C4D",
  },
  purpleHeart: {
    50: "#F9F6FE",
    100: "#F1EBFC",
    200: "#E5DAFA",
    300: "#D1BCF6",
    400: "#B591EF",
    500: "#9A67E5",
    600: "#8248D5",
    700: "#7338C4",
    800: "#5E3099",
    900: "#4E287B",
    950: "#32115A",
  },
  brightGray: {
    50: "#F6F7F9",
    100: "#EDEDF1",
    200: "#D6D8E1",
    300: "#B3B8C6",
    400: "#8992A7",
    500: "#6A748D",
    600: "#555C74",
    700: "#464C5E",
    800: "#404555",
    900: "#353845",
    950: "#23252E",
  },
  white: {
    50: "#FFFFFF",
    100: "#F8F8F8",
    200: "#F2F2F2",
    300: "#ECECEC",
    400: "#E7E7E7",
    500: "#E1E1E1",
    600: "#DBDBDB",
    700: "#D5D5D5",
    800: "#CFCFCF",
    900: "#C9C9C9",
    950: "#C3C3C3",
  },
  apple: {
    50: "#F1FCF2",
    100: "#DFF9E4",
    200: "#C1F1CB",
    300: "#90E5A3",
    400: "#58D073",
    500: "#32B550",
    600: "#28A745",
    700: "#1F7634",
    800: "#1E5D2D",
    900: "#1A4D27",
    950: "#092A12",
  },
  redRibbon: {
    50: "#FEF2F2",
    100: "#FEE6E5",
    200: "#FCCFCF",
    300: "#F9A8A8",
    400: "#F57779",
    500: "#EC474F",
    600: "#DC3545",
    700: "#B7192C",
    800: "#99182C",
    900: "#83182C",
    950: "#490812",
  },
  amber: {
    50: "#FFFFEA",
    100: "#FFFBC5",
    200: "#FFF885",
    300: "#FFEE46",
    400: "#FFDF1B",
    500: "#FFC107",
    600: "#E29400",
    700: "#BB6902",
    800: "#985108",
    900: "#7C420B",
    950: "#482200",
  },
}

export default colors;