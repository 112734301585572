import React from 'react'
import { RadioButtons } from "components/form";
import _ from "lodash";

function AutoEnroll(props) {
  const prefix = props.prefix || {
    name: "auto_enroll",
    id: "auto_enroll",
  };

  const options = [
    { value: "true", label: "Sim" },
    { value: "false", label: "Não" },
  ];

  const defaultValue = _.get(props.getValues?.(), prefix.name)?.toString() || props.defaultValue?.toString()

  return (
    <RadioButtons
      {...props}
      id={prefix.id}
      name={prefix.name}
      options={options}
      required={false}
      label={props.label || "Autoriza ser pré-selecionado para vagas?"}
      helperText="Dica: isso significa que podemos te pré-selecionar para uma vaga caso seu perfil combine com o perfil desejado pela empresa."
      defaultValue={defaultValue}
    />
  );
}

export default AutoEnroll
