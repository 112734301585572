import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useEnvelope() {
  const { id } = useParams();
  const { data, isLoading, mutate } = useFetch(`/v1/internship/envelopes/${id}`);

  return {
    envelope: data?.data?.attributes,
    isLoading,
    mutate
  };
}
