import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import SearchOffIcon from "@mui/icons-material/SearchOff";

const Button = styled.p`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: flex-end;
  margin-top: -60px;
  gap: 5px;
  cursor: pointer;
  text-decoration: underline;
  color: ${({theme}) => theme.mode === "light" ? "rgba(0, 0, 0, .5)" : "rgba(255, 255, 255, .7)"};
  transition: .2s linear all;

  & svg{
    transition: .1s linear all;
  }

  &:hover {
    color: ${({theme}) => theme.text.primary};
  }

  &:hover svg {
    fill: ${(props) => props.enabled ? "#ea294b" : "#34a6a6" };
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
    margin: 0px;
    margin-top: -1px;
    padding: 20px 0;
    background-color: ${({theme}) => theme.mode === "light" ? "#f8f8f8" : theme.background.paper};
    font-size: 0.85rem;
  }
`;

export default (props) => {
  const [enabled, setEnabled] = useState(props.enabled || false);

  const renderContent = () => {
    return enabled ? (
      <>
        <SearchOffIcon fontSize="medium" />
        {"Limpar filtro de vagas exclusivas"}
      </>
    ) : (
      <>
        <SavedSearchIcon fontSize="medium" />
        {"Apenas vagas exclusivas à minha Instituição de Ensino"}
      </>
    );
  };

  const handleSearch = (value = null) => {
    let event = { target: { name: "ies_jobs_only", value: value } };
    props.handleChange(event);
    props.createFilterLabel(event);
  };

  useEffect(() => {
    if (enabled) {
      handleSearch("Apenas vagas exclusivas à minha Instituição de Ensino");
    } else {
      handleSearch();
    }
  }, [enabled]);

  useEffect(() => {
    setEnabled(props.enabled);
  }, [props.enabled])

  return (
    <Button enabled={enabled} onClick={() => setEnabled(!enabled)}>
      {renderContent()}
    </Button>
  );
};