import React, { useEffect } from "react"
import { UncontrolledInput as Input } from "components/form";
import VMasker from "vanilla-masker";

function CourseStartDate(props) {
  const prefix = props.prefix || {
    name: "course_start_date",
    id: "course_start_date"
  }

  const validation = {
    pattern: /([0][1-9]|[1][0-2])\/(19|20)\d{2}$/
  };

  const messages = {
    pattern: "Não segue o padrão MM/AAAA ou não é uma data válida",
  };

  useEffect(() => {
    VMasker(document.querySelector(".course_start_date")).maskPattern("99/9999");
  }, []);
  
  return(
    <div className="form-group">
      <Input
        {...props}
        className={`${props.className} course_start_date`}
        placeholder="MM/AAAA"
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Data de Início do Curso"}
        validation={validation}
        messages={messages}
      />
    </div>
  )
}
export default CourseStartDate;