import React, { useEffect, useState } from "react";
import { styled, FormGroup, Switch } from "@mui/material";
import { EditModeContext } from "./context";

const CustomFormGroup = styled(FormGroup)({
  marginLeft: "auto",
  alignItems: "flex-end",
});

const MaterialUISwitch = styled(Switch)({
  width: 62,
  height: 34,
  padding: 7,
  margin: "0!important",
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 25 28"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "rgba(0, 0, 0, .25)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#c13950!important",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 25 25"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "rgba(0, 0, 0, .25)",
    borderRadius: 20 / 2,
  },
});

export default () => {
  const EditMode = React.useContext(EditModeContext);

  return (
    <CustomFormGroup className="edit-mode-switch">
      <MaterialUISwitch
        onChange={EditMode.toggleEnabled}
        sx={{ m: 1 }}
        checked={EditMode.enabled}
      />
    </CustomFormGroup>
  );
};
