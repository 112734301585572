import { useEffect, useReducer } from "react";
import api from "services/api";
import produce from "immer";

const initialState = {
  collection: [],
  included: [],
  totalCount: 0,
  totalPages: 1,
  currentPage: 1,
  hasMore: true,
  isFetching: false,
};

const reducerActions = {
  fetchedData: (state, payload) => {
    state.collection = [...state.collection, ...payload.collection.data];
    state.included = [...state.included, ...payload.collection.included || []];
    state.totalCount = payload.total_count;
    state.totalPages = payload.total_pages;
    state.currentPage += 1;
  },
  applyFilters: (state, _) => {
    state.currentPage = 1;
    state.totalPages = 1;
    state.hasMore = true;
    state.collection = [];
    state.included = [];
  },
  updateFetchStatus: (state, value) => {
    state.isFetching = value;
  },
  noMoreData: (state, _) => {
    state.hasMore = false;
  },
};

function reducer(state, action) {
  let fn = reducerActions[action.type];

  if (fn) {
    return produce(state, (draftState) => fn(draftState, action.payload));
  }

  console.log("[WARNING] Action without reducer: ", action);
  return state;
}

function useDisplay(params) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { request, filters, updateInfos } = params;

  const {
    collection,
    included,
    totalCount,
    totalPages,
    currentPage,
    hasMore,
    isFetching,
  } = state;

  const loadMore = () => {
    filters["page"] = currentPage;
    if (currentPage <= totalPages) {
      dispatch({ type: "updateFetchStatus", payload: true });

      api
        .get(request, { params: filters })
        .then(({ data }) => {
          dispatch({ type: "fetchedData", payload: data });
        })
        .catch((resp) => console.log(resp))
        .finally(() => dispatch({ type: "updateFetchStatus", payload: false }));
    } else {
      dispatch({ type: "noMoreData" });
    }
  };

  const applyFilters = () => {
    dispatch({ type: "applyFilters" });
  };

  useEffect(() => {
    updateInfos({
      totalCount: totalCount,
      length: collection.length,
    });
  }, [collection, totalCount]);

  return {
    collection,
    included,
    hasMore,
    isFetching,
    loadMore,
    applyFilters,
  };
}

export default useDisplay;
