import React, { useEffect, useContext, cloneElement } from "react";
import InfiniteScroll from "react-infinite-scroller";
import _ from "lodash";
import { useDisplay } from "utils/hooks";
import { FilterContext } from "./FilterController";
import { Loader } from "components/ui";
import styled from "styled-components";

function Display({ request, component, user_info_id, style = "default" }) {
  const { updateInfos, filters } = useContext(FilterContext);

  let params = { request, filters, updateInfos };

  if (user_info_id) {
    params = { request, filters, updateInfos, user_info_id };
  }

  const FetchData = useDisplay(params);

  const DisplayAcademy = styled.div`
    width: 100%;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    div.loader {
      grid-column: 1 / -1;
    }

    @media (min-width: 720px) {
      /* padding-top: 0; */
    }

    @media (min-width: 1234px) {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 10px;
      row-gap: 13px;
    }
  `;

  function renderList() {
    const list = FetchData.collection || [];
    return list.map((item) => {
      let properties = { key: item.id, item };
      let included = _findIncludes(item);

      if (!_.isEmpty(included)) properties.included = included;

      return cloneElement(component, properties);
    });
  }

  const _findIncludes = (item) => {
    let resources = {};
    if (_.isEmpty(FetchData.included) || !item.relationships) return {};

    Object.keys(item.relationships).map((key) => {
      const { id, type } = item.relationships[key].data;
      resources[key] =
        FetchData.included.find(
          (element) => element?.id == id && element?.type == type
        ) || {};
    });

    return resources;
  };

  useEffect(() => FetchData.applyFilters(), [filters]);

  return (
    <InfiniteScroll
      className={style != "default" ? style : "display"}
      loadMore={FetchData.loadMore}
      hasMore={!FetchData.isFetching && FetchData.hasMore}
      threshold={100}
    >
      {style != "default" ? (
        <DisplayAcademy>
          {renderList()}
          {(FetchData.isFetching || FetchData.hasMore) && (
            <Loader key={0} displayLoader />
          )}
        </DisplayAcademy>
      ) : (
        <>
          {renderList()}
          {(FetchData.isFetching || FetchData.hasMore) && (
            <Loader key={0} displayLoader />
          )}
        </>
      )}
    </InfiniteScroll>
  );
}

export default Display;
