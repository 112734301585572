import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { formPost } from "services/api";
import { File } from "components/form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useEnvelope } from "../../../../context";

import Download from "../Download";

function UploadBaseContract(props) {
  const { t } = useTranslation();

  const { envelope, mutate } = useEnvelope();

  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { register, handleSubmit } = useForm();


  const handleDownload = () => {
    const message = t(`envelope.workflow.actions.download.base_contract`);

    swal(
      "Atenção!", message, "warning",
      {
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Continuar",
            value: true,
            closeModal: true,
          },
        },
      }
    ).then((value) => {
      if (value){
        window.location = props.event.extra.link;
      }
    });
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const successfulRequisition = () => {
    mutate();
    swal(
      "Tudo certo!",
      "Upload feito com sucesso!",
      "success"
    );
  };

  const unsuccessfulRequisition = () => {
    swal(
      "Ops!",
      "Não foi possível adicionar esse documento. Por favor, tente novamente mais tarde.",
      "error"
    );
  };

  const submitData = (data) => {
    const envelope = data;
    swal("Aguarde", "Estamos efetuando o upload do contrato.", "info");
    formPost(`/v1/internship/envelopes/${id}/upload_base`, { envelope })
      .then(() => successfulRequisition())
      .catch(() => unsuccessfulRequisition());
    handleOpen();
  };

  return (
    <>


      <button className="button button-primary" onClick={handleDownload}>
        Baixar contrato
      </button>
  
      <button
        className="button button-info"
        onClick={handleOpen}
        disabled={!envelope.can_upload}
      >
        {envelope.can_upload ? "Fazer upload" : "Upload feito"}
      </button>
      <Dialog open={open} onClose={handleOpen}>
        <form onSubmit={handleSubmit(submitData)}>
          <h3 style={{ padding: "20px 24px 0px" }}>
            Faça o upload do contrato preenchido
          </h3>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: "15px" }}>
              Faça aqui o upload do contrato de estágio com as informações preenchidas
            </DialogContentText>
            <File
              register={register}
              required
              multiple
              id="filled_archives[]"
              name="filled_archives[]"
              label="Formatos permitidos: docx e pdf"
              buttonLabel="Adicionar arquivo"
              accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
          </DialogContent>
          <DialogActions sx={{ padding: "20px 24px" }}>
            <button
              className="button button-secondary modal-button"
              type="button"
              onClick={handleOpen}
            >
              Cancelar
            </button>
            <button
              className="button button-primary modal-button"
              type="submit"
            >
              Salvar
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default UploadBaseContract;
