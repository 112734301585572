import styled from "styled-components";

export const FooterContainer = styled.footer`
	position: relative;
	background-color: #EAE6E3;
	height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	z-index: 2;
`;

export const Copyright = styled.p`
	text-align: center;
`;
