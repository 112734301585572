import React from "react";
import TakingTestHeader from "components/ui/test/taking/header/Header";
import TakingTestFooter from "components/ui/test/taking/footer/Footer";

export default ({ element }) => (
  <>
    <TakingTestHeader />
      {element}
    <TakingTestFooter />
  </>
);
