import React from "react";
import { FooterContainer, Copyright } from './styles'

function Footer() {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <FooterContainer>
      <Copyright>© {year} WallJobs. Todos os direitos reservados.</Copyright>
    </FooterContainer>
  )
}

export default Footer;