import React from "react";

function Facebook(){

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
        <path d="M32.5001 16.098C32.5001 7.20605 25.3361 -0.00195312 16.5001 -0.00195312C7.66009 4.68751e-05 0.496094 7.20605 0.496094 16.1C0.496094 24.134 6.34809 30.794 13.9961 32.002V20.752H9.93609V16.1H14.0001V12.55C14.0001 8.51605 16.3901 6.28805 20.0441 6.28805C21.7961 6.28805 23.6261 6.60205 23.6261 6.60205V10.562H21.6081C19.6221 10.562 19.0021 11.804 19.0021 13.078V16.098H23.4381L22.7301 20.75H19.0001V32C26.6481 30.792 32.5001 24.132 32.5001 16.098Z" fill="#7338C4"/>
      </svg>    
    </div>
  )
}

export default Facebook;