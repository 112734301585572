import React from "react";
import { SearchableSelect } from "components/form";
import { useStaticCollection } from "utils/hooks";

function Abilities(props) {
  const collection = useStaticCollection("/selects/abilities.json");

  const prefix = props.prefix || {
    name: "ability_ids",
    id: "ability_ids",
  };

  const sanitizeValue = (values) => {
    return values.map?.((option) => option?.value || option) || [];
  };

  return (
    <div className="form-group">
      <SearchableSelect
        {...props}
        placeholder="Digite aqui e/ou escolha o certificado, por exemplo: TOFEL, MCSA, CELI"
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Quais certificados que você possui?"}
        sanitizeValue={sanitizeValue}
        multiple
      />
    </div>
  );
}
export default Abilities;
