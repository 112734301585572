import React from "react";
import { UncontrolledInput as Input } from "components/form";

function RG(props) {
  const prefix = props.prefix || {
    id: "rg",
    name: "rg",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe seu RG..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "RG"}
      />
    </div>
  );
}

export default RG;
