import React, { useState, useMemo, useEffect } from "react";
import _ from "lodash";
import { EditModeProvider, fields, sections } from "./context";
import Modal from "./Modal";
import EditIcon from "./EditIcon";
import Switch from "./Switch";
import api from "services/api";

export { EditIcon, Switch };

export default (props) => {
  const [enabled, setEnabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fieldList, setFieldList] = useState([]);
  const [defaultValues, setDefaultValues] = useState(null);
  const [origin, setOrigin] = useState(null);

  const handleEditModal = (options = {}) => {
    setOpenModal((prevMode) => !prevMode);
    fillFieldList(options);
    setDefaultValues(null);
    if (options.type) setOrigin(options.type);
  };

  const fillFieldList = ({ type, attributes, required = false }) => {
    let list =
      attributes?.map((item) => {
        let requiredField = required;
        if (type == "candidatureRequired" && (item == "phone" || item == "user_languages")) {
          requiredField = false;
        }

        return React.cloneElement(fields[item], { required: requiredField }, null)
      }) || sections[type];
    list = _.compact(list);
    if (!_.isEmpty(list)) setFieldList(list);
  };

  const toggleEnabled = () => {
    setEnabled((prevMode) => !prevMode);
  };

  const EditMode = useMemo(
    () => ({
      enabled,
      toggleEnabled,
      openModal,
      fieldList,
      handleEditModal,
    }),
    [enabled, openModal, fieldList]
  );

  useEffect(() => {
    if (_.isEmpty(defaultValues)) {
      const getDefaultValues = () => {
        api
          .get("/v1/profile/user_infos/attributes")
          .then((resp) => setDefaultValues(resp.data?.data?.attributes))
          .catch(() => setDefaultValues({}));
      };

      getDefaultValues();
    }
  }, [defaultValues]);

  return (
    <EditModeProvider value={EditMode}>
      {props.children}
      <Modal defaultValues={defaultValues} origin={origin} />
    </EditModeProvider>
  );
};
