import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { formPost } from "services/api";
import { File } from "components/form";
import { useParams } from "react-router-dom";
import { useEnvelope } from "../../../../context";

function AddDocument() {
  const { envelope, mutate } = useEnvelope();

  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { register, handleSubmit } = useForm();

  const handleOpen = () => {
    setOpen(!open);
  };

  const successfulRequisition = () => {
    mutate();
    swal(
      "Tudo certo!",
      "Upload feito com sucesso!",
      "success"
    );
  };

  const unsuccessfulRequisition = () => {
    swal(
      "Ops!",
      "Não foi possível adicionar esse documento. Por favor, tente novamente mais tarde.",
      "error"
    );
  };

  const submitData = (data) => {
    const envelope = data;
    swal("Aguarde", "Estamos efetuando o upload do contrato assinado.", "info");
    formPost(`/v1/internship/envelopes/${id}/upload`, { envelope })
      .then(() => successfulRequisition())
      .catch(() => unsuccessfulRequisition());
    handleOpen();
  };

  return (
    <>
      <button
        className="button button-info"
        onClick={handleOpen}
        disabled={!envelope.can_upload}
      >
        {envelope.can_upload ? "Fazer upload" : "Upload feito"}
      </button>
      <Dialog open={open} onClose={handleOpen}>
        <form onSubmit={handleSubmit(submitData)}>
          <h3 style={{ padding: "20px 24px 0px" }}>
            Faça o upload do seu contrato assinado
          </h3>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: "15px" }}>
              Faça aqui o upload do contrato de estágio assinado por todas
              as partes (sendo <b>obrigatória</b> a assinatura da Instituição de Ensino).
              <br />
              <br />
              Lembrando: um contrato de estágio só é valido quando todos os
              envolvidos o assinaram.
            </DialogContentText>
            <File
              register={register}
              required
              multiple
              id="signed_document"
              name="signed_document"
              label="Formatos permitidos: PDF / PNG / JPG / JPEG"
              buttonLabel="Adicionar arquivos"
              accept="application/pdf, image/*"
            />
          </DialogContent>
          <DialogActions sx={{ padding: "20px 24px" }}>
            <button
              className="button button-secondary modal-button"
              type="button"
              onClick={handleOpen}
            >
              Cancelar
            </button>
            <button
              className="button button-primary modal-button"
              type="submit"
            >
              Salvar
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default AddDocument;
