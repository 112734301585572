import axios from "axios";
import { serialize } from "object-to-formdata";
import baseUrl from "./domain";

const api = axios.create({
  baseURL: baseUrl,
});

export const formPost = async (url, data) => {
  const formData = serialize(data);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return await api.post(url, formData, config);
};

export default api;
