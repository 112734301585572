import React from "react";
import styled from "styled-components";

const SocialMedia = styled.div`
  display: flex;

  a {
    font-size: 30px;
    margin-right: 6px;
    color: ${({theme}) => theme.mode === "light" ? "#7338c4" : theme.text.primary};
  }

  a:hover {
    color: ${({theme}) => theme.mode === "light" ? "#5f309f" : theme.text.secondary};
  }
`;

export default ({ list }) => {
  const items = Object.fromEntries(Object.entries(list).filter(([_, v]) => v));
  const keys = Object.keys(items);

  return (
    <SocialMedia>
      {keys.map((key, index) => (
        <a key={index} href={list[key]} target="_blank">
          <i className={`fab fa-${key}`}></i>
        </a>
      ))}
    </SocialMedia>
  );
};
