import React from "react";
import styled from "styled-components";
import walljobs from "assets/images/walljobs.png";

const Footer = styled.footer`
  margin-top: 100px;
  margin-bottom: 80px;
  height: 40px;
  padding: 15px;

  p {
    text-align: center;
    text-decoration: none;
    color: #898d94;
  }

  a {
    text-decoration: none;
    color: #898d94;
  }

  @media screen and (max-width: 767px) {
    & {
      background-image: url(${walljobs});
      background-repeat: no-repeat;
      background-position: top 20px right 20px;
      background-size: 80px auto;
      background-color: #7338c4;
      margin-top: 0px;
      margin-bottom: 0px;
      height: 140px;
      width: 100%;

      p {
        text-align: left;
        width: 200px;
        color: white;
      }

      a {
        color: white;
      }
    }
  }
`;

export default React.memo(() => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <Footer>
      <p>
        Walljobs™ {year} - Todos os direitos reservados -{" "}
        <a href="https://www.walljobs.com.br/terms.pdf">Termos de uso</a>
      </p>
    </Footer>
  );
});
