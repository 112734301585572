import React, { useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import useResetPassword from "./useResetPassword";
import Password from "./Password";
import PasswordConfirmation from "./PasswordConfirmation";

const ValidationMessages = styled.div`
  p {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    display: flex;
    align-items: center;
    gap: 3px;

    span {
      margin: 0;
      color: #ea484b;
    }
  }

  p:last-of-type {
    margin-bottom: 10px;
  }
`;

export default (props) => {
  const { state, handleChange } = useResetPassword();
  const { password, password_confirmation } = state;

  const showValidations = () => {
    const validations = _.flow([
      Object.entries,
      (arr) => arr.filter(([key, attrs]) => !attrs.value),
    ])(password.validations);

    return validations.map(([key, attrs]) => (
      <p key={key}>
        <span className="material-icons">close</span>
        {attrs.message}
      </p>
    ));
  };

  useEffect(() => {
    const errors = _.compact([password.error, password_confirmation.error]);

    if (!_.isEmpty(errors)) {
      props.setError?.("reset_password", { type: "custom" });
    } else {
      props.clearErrors?.("reset_password");
    }
  }, [password.error, password_confirmation.error]);

  return (
    <>
      <Password
        {...props}
        onChange={handleChange}
        error={!!password.error}
        helperText={password.error}
        prefix={props?.password}
      />
      {password.value && (
        <ValidationMessages>{showValidations()}</ValidationMessages>
      )}
      <PasswordConfirmation
        {...props}
        onChange={handleChange}
        error={!!password_confirmation.error}
        helperText={password_confirmation.error}
        color={password_confirmation.color}
        prefix={props?.password_confirmation}
      />
    </>
  );
};
