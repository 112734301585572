import React, { useEffect, useState } from "react";
import _ from "lodash";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

function Time(props) {
  const [value, setValue] = useState(sanitizeDefaultValue(props.defaultValue));

  const validation = { ...{ required: props.required }, ...props.validation };

  const messages = {
    ...{ required: "Não pode ficar em branco" },
    ...props.messages,
  };

  const error = _.get(props.errors, props.name);

  const inputRef = props.register?.(props.name, validation)

  const handleChange = () => {
    let { value } = document.getElementById(props.id);

    props.setValue?.(props.name, value);
    props.handleChange?.(value, props.name);
  }
  
  function sanitizeDefaultValue(time){
    let [hour, minute] = time.split(":");
    let formattedTime = new Date;
    formattedTime.setHours(hour, minute);
    
    return formattedTime;
  }

  useEffect(() => {
    handleChange();
  }, [value])

  useEffect(() => {
    let defaultValue = _.get(props.getValues?.(), props.name) || props.defaultValue
    setValue(sanitizeDefaultValue(defaultValue))
  }, [props.defaultValue, props.control?._formValues])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileTimePicker
        ampm={false}
        cancelText={<span>Cancelar</span>}
        label="Selecione o horário"
        value={value}
        disabled={props.disabled}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={props.id}
            name={props.name}
            inputRef={inputRef?.ref}
            variant="standard"
            label={props.label || ""}
            required={props.required}
            error={!_.isEmpty(error)}
            helperText={
              error?.message || messages[error?.type] || props.helperText
            }
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default Time;
