import React from "react";
import styled from "styled-components";

const Message = styled.div`
  grid-column: span 2;
  align-self: end;
  height: 60px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08));

  i {
    font-size: 32px;
    color: ${({theme}) => theme.info[700]};
    margin-right: 5px;
  }

  p {
    line-height: 18px;
    color: ${({theme}) => theme.info[700]};
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
    border-radius: 0;
    border: none;

    p {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export default (props) => (
  <Message className={props.className}>
    <i className="material-icons">description</i>
    <p
      dangerouslySetInnerHTML={{
        __html: props.message,
      }}
    ></p>
  </Message>
);
