import React from "react";
import { Link } from "react-router-dom";
import { BackButton } from "components/ui";
import { ATS } from "navigation/ROUTES";

function BackBreadcrumb() {
  return (
    <BackButton>
      <Link to={ATS.JOBS.INDEX}>
        <i className="material-icons">keyboard_backspace</i>ver outras vagas
      </Link>
    </BackButton>
  );
}

export default BackBreadcrumb;
