import React from "react";
import { ScatterChart } from "@mui/x-charts/ScatterChart";
import colors from "styles/colors.js";
import { useTheme } from "@mui/material";

const COLORS = [
  colors.dodgeBlue[400],
  colors.punch[500],
  colors.purpleHeart[400],
  colors.brightGray[600],
];

export default (props) => {
  const theme = useTheme();

  return (
    <ScatterChart
      colors={COLORS}
      slotProps={{
        legend: { labelStyle: theme.typography.overline },
      }}
      {...props}
    />
  );
};
