import styled from "styled-components";

export const BannerStyle = styled.img`
  width: 100%;
  height: 400px;
  display: flex;
  z-index: -1;
`

export const Content = styled.div`
  
  .program-info {
    position: relative;
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -198px;
    gap: 59px;
    align-self: stretch;
    background-color: ${({theme}) => theme.mode === "light" ? '#F7F7F7' : '#333B4D'};
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  }

  .content {
    display: -webkit-box;
    display: flex;

    @media screen and (max-width: 580px) {
      display: inline;
      .btn-company {
        padding-top: inherit;
      }
    }
  }

  .contant-header{
    display: flex;
  }


  .image {
    display: flex;
  }

  .content-info {
    padding-left: 20px;
  }

  .program_title {
    color: ${({theme}) => theme.mode === "light" ? '#454545' : '#FFF'};
    font-family: "Source Sans Pro";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;

    @media screen and (max-width: 766px) {
      font-size: 28px;
    }
  }

  .about-program {
    padding-top: 10px;
  }
  
  .about-program h2 {
    color: #367FA9;
    font-size: 32px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    line-height: 48px;
  }

  .about-program div{
    font-size: 18px;
    height: auto;
    width: 100%;
    overflow-wrap: anywhere;
  }

  .btn-company a{
    display: inline-block;
    border-radius: 8px;
    padding: 15px;
    background: #009BF6;
    color: #FFFFFF;
  }


  .job-content {
    padding: 30px;
    background-color: ${({theme}) => theme.mode === "light" ? '#F7F7F7' : '#333B4D'};
  }
  
  .card-program {
    margin-top: 40px;
    background-color: #F7F7F7;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  }

  .card-company {
    padding: 20px;
    background-color: ${({theme}) => theme.mode === "light" ? '#F7F7F7' : '#333B4D'};

  }

  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 5%;

    @media screen and (max-width: 991px) {
      display: inline;
    }

  }

  .text {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    max-width: 400px;

    @media screen and (max-width: 991px) {
      display: inline;
    }
  }
    
`

export const LogoStyle = styled.img`
  width: 200px;
  height: 200px;
`

export const Title = styled.h3`
  color: #367FA9;
  font-weight: 700;
  padding: 30px 0px 10px 0px;
`