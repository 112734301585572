import React from "react";
import { SelectInput } from "components/form";
import { useStaticCollection } from "utils/hooks";

function InternshipType(props){

  const prefix = props.prefix || {
    id: "internship_type",
    name: "internship_type",
  };
  const collection = useStaticCollection("/selects/internship_types.json");

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione a modalidade do estágio..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Modalidade do Estágio"}
      />
    </div>
  );

}

export default InternshipType;