import React from "react";
import { SelectInput } from "components/form";
import { useStaticCollection } from "utils/hooks";

function Gender(props) {
  const prefix = props.prefix || {
    id: "gender_id",
    name: "gender_id",
  };

  const collection = useStaticCollection("/selects/genders.json")

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione seu gênero..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Gênero"}
        handleChange={props.handleChange}
      />
    </div>
  );
}

export default Gender;
