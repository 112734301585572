import React from "react";
import { SelectInput } from "components/form";

function Level(props) {
  const { prefix, index } = props;

  const job_level = [
    { value: "partner", label: "Sócio" },
    { value: "trainee", label: "Trainee" },
    { value: "freelance", label: "Autônomo" },
    { value: "voluntary", label: "Voluntário" },
    { value: "intern", label: "Estágio" },
    { value: "junior_enterprise", label: "Empresa júnior" },
    { value: "effective_work", label: "Efetivo" },
  ];

  return (
    <SelectInput
      {...props}
      id={`${prefix}_${index}_level`}
      name={`${prefix}[${index}][level]`}
      collection={job_level}
      placeholder="Selecione..."
      label="Nível"
    />
  );
}

export default Level;
