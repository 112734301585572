import React from "react";
import { SearchableSelect } from "components/form";
import { Tip } from "components/ui";
import { getCollection } from "utils/getCollection";

function Course(props) {
  const prefix = props.prefix || {
    name: "course_id",
    id: "course_id",
  };

  let university_id = props.watch?.("university_id");

  const [path, setPath] = React.useState("/selects/all_courses.json");
  const [collection, setCollection] = React.useState([]);

  React.useEffect(() => {
    const setSelectPath = () => {
      if(!university_id){
        university_id = props.getValues?.("university_id")
      }

      if(university_id && !props.defaultValue && !props.getValues?.(prefix.name)){
        return setPath(`/selects/${university_id}/courses_react.json`);
      }
  
      setPath("/selects/all_courses.json");
    }

    setSelectPath();
  }, [university_id]);

  React.useEffect(() => {
    if (path) getCollection(path, setCollection)
  }, [path]);

  const renderTip = () => (
    <Tip>
      Não encontrou seu <b>curso</b>? <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=JG0Ku-RhXEeaKMWr_mgPSFGW5il2p45MgKp_-yqFCKxUNVFISDhWODVSNjREUTkwMVJGSkZQRUQ1UC4u" target="_blank">Clique aqui</a> e solicite o cadastramento dele em nossa plataforma! 😉
    </Tip>
  );

  return (
    <div className="form-group">
      <SearchableSelect
        {...props}
        placeholder="Selecione seu curso..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Curso"}
      />
      {renderTip()}
    </div>
  );
}

export default Course;
