import { useReducer, useEffect } from "react";
import produce from "immer";
import _ from "lodash";

let initialState = {
  password: {
    value: null,
    error: null,
    validations: {
      uppercase: {
        value: null,
        message: "uma letra maiúscula",
        test: (value) => /[A-Z]/.test(value),
      },
      lowercase: {
        value: null,
        message: "uma letra minúscula",
        test: (value) => /[a-z]/.test(value),
      },
      number: {
        value: null,
        message: "um número",
        test: (value) => /[0-9]/.test(value),
      },
      symbol: {
        value: null,
        message: "um símbolo ou caracter especial",
        test: (value) => /[#?!@$%^&*-]/.test(value),
      },
      length: {
        value: null,
        message: "no mínimo 8 caracteres",
        test: (value) => value.length >= 8,
      },
    },
  },
  password_confirmation: {
    value: null,
    error: null,
  },
};

let reducerActions = {
  updateValue: (state, payload) => {
    state[payload.field].value = payload.value;
  },
  validateValue: (state, payload) => {
    let field = state[payload.field];
    let keys = Object.keys(field.validations);
    let validations = {};

    keys.forEach((rule) => {
      let validation = { ...field.validations[rule] };
      validation.value = validation.test(field.value);
      validations[rule] = validation;
    });

    state[payload.field].validations = validations;
  },
  setError: (state, payload) => {
    state[payload.field].error = payload.error;
    state[payload.field].color = payload.color;
  },
};

function reducer(state, action) {
  let fn = reducerActions[action.type];

  if (fn) {
    return produce(state, (draftState) => fn(draftState, action.payload));
  }

  console.log("[WARNING] Action without reducer: ", action);
  return state;
}

function useResetPassword(){
  const [state, dispatch] = useReducer(reducer, initialState);
  const { password, password_confirmation } = state;

  const handleChange = (event) => {
    let { value, name } = event.target;
    let field = /(password_confirmation|password)/.exec(name)?.[0];
    dispatch({ type: "updateValue", payload: { value, field } });
  };

  useEffect(() => {
    const checkForEqualsPasswords = () => {
      let error = null;
      let color = null;
      
      if (password_confirmation.value != password.value) {
        error = "As senhas não coincidem. Por favor, tente novamente.";
        color = "red";
      }

      dispatch({
        type: "setError",
        payload: { error, color, field: "password_confirmation" },
      });
    };

    if (password_confirmation.value) checkForEqualsPasswords();
  }, [password.value, password_confirmation.value]);

  useEffect(() => {
    if (password.value) {
      dispatch({ type: "validateValue", payload: { field: "password" } })
    }
  }, [password.value]);

  useEffect(() => {
    const patternErrors = _.flow([
      Object.entries,
      (arr) => arr.filter(([key, props]) => props.value === false),
    ])(password.validations);

    let error = null;

    if(!_.isEmpty(patternErrors)){
      error = "A senha não segue o padrão"
    }

    dispatch({ type: "setError", payload: { error, field: "password" } });
  }, [password.validations]);

  return { state, handleChange }
}

export default useResetPassword;