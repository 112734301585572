import React from 'react';
import styled from 'styled-components';

const Paragraph = styled.p`
  color:#c13950;
  font-weight: bold;
`

function ErrorMessages(props){
  const { messages } = props;

  function renderMessages(){
    return messages?.map((message) => (
      <Paragraph key={new Date().getTime()}>{message}</Paragraph>
    ))
  }

  return (
    <div>
      {renderMessages()}
    </div>
  )
}

export default ErrorMessages;