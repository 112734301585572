import React from "react";
import { useNestedAttributes } from "utils/hooks";
import ExperienceAttributes from "./ExperienceAttributes";

function Experiences(props) {
  const prefix = props.prefix || "experiences_attributes";

  const { list, addItem, destroyItem } = useNestedAttributes({ ...props, prefix });

  const renderExperiences = () => {
    return list?.map((item, index) => {
      const properties = {
        ...props,
        prefix,
        item,
        index,
        destroyItem,
      };
      return <ExperienceAttributes {...properties} key={item?.id || item?.key} />;
    });
  };

  return (
    <div>
      {renderExperiences()}
      <div className="add-button">
        <button type="button" onClick={addItem}>
          adicionar <i className="fa fa-plus"></i>
        </button>
      </div>
    </div>
  );
}

export default Experiences;
