import { handleUnauth, handleInternalError } from "./handlers";
import store from "@root/redux/store";
import api from "./index";

export default {
  setupInterceptors: (navigate) => {
    api.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status === 401){
          handleUnauth(navigate)
        }

        if (error.response.status === 500){
          handleInternalError()
        }

        return Promise.reject(error);
      }
    );
    setAuthorizationToken();
  }
}

store.subscribe(setAuthorizationToken);

function select(state) {
  return state?.session?.access_token;
}

function setAuthorizationToken() {
  let access_token = select(store.getState());
  api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
}