import React from "react";
import { SelectInput } from "components/form";

function CurrentSemester(props) {
  
  const prefix = props.prefix || {
    id: "current_semester",
    name: "current_semester",
  };

  const semesters = mountSemester();

  function mountSemester(){
    let semesters = [];
    for (let i = 0; i <= 19; i++) {
      semesters.push({ value: i + 1, label: i + 1 });
    }
    return semesters
  }

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione o semestre..."
        name={prefix.name}
        id={prefix.id}
        collection={semesters}
        label={props.label || "Semestre atual"}
      />
    </div>
  );
}

export default CurrentSemester;
