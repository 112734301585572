import React from "react";
import styled from "styled-components";
import { Paper } from "@mui/material";

const CardBoxLabel = styled(Paper)`
  &.card-box-label {
    display: grid;
    grid-template-columns: 2fr 1fr;
    //width: fit-content;
    height: 63px;
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    align-items: center;
    background-color: transparent;
    background-image: linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08));
  }

  small {
    grid-column: span 2;
    color: ${({theme}) => (theme.mode === "light" ? "#536e6e" : "#959aad")};
    display: inline-flex;
    align-items: center;

    i {
      margin-right: 2px;
    }
  }

  span.label {
    align-self: center;
    justify-self: end;
  }

  @media screen and (max-width: 767px) {
    & {
      height: fit-content;
      row-gap: 5px;
    }

    span.label {
      margin-bottom: 5px;
      justify-self: center;
    }
  }
`;

export default (props) => (
  <CardBoxLabel elevation={0} className="card-box-label">
    {props.children}
  </CardBoxLabel>
);
