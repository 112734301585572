const importAll = (path) => {
  let images = {};
  path.keys().map((item) => {
    let key = item.replace("./wj-benefits-", "").replace(".png", "");
    images[key] = path(item);
  });

  return images;
};

const benefits = importAll(
  require.context("assets/images/benefits", false, /\.png/)
);

export default benefits;
