import React from "react";
import { styled } from "@mui/material/styles";
import { Stepper, Step, StepLabel } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { WalljobsIcon } from "components/ui";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SchoolIcon from "@mui/icons-material/School";
import CloseIcon from "@mui/icons-material/Close";
import { useEnvelope } from "../../../context";

const CustomStepLabel = styled(StepLabel)(() => ({
  ["&.MuiStepLabel-alternativeLabel"]: {
    gap: "6px",
  },
  ["& .MuiStepLabel-label.MuiStepLabel-alternativeLabel"]: {
    fontSize: "13px",
    marginTop: "0px",
  },
  ["& .MuiStepLabel-label.MuiStepLabel-alternativeLabel.Mui-active"]: {
    fontWeight: "600",
  },
  ["@media screen and (max-width: 767px)"]: {
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel.Mui-active": {
      display: "block",
    },
  },
}));

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 3,
  },
  [`&.Mui-active, &.Mui-completed`]: {
    [`.${stepConnectorClasses.line}`]: {
      borderColor: "#51F27E",
    },
  },
}));

const StepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#ccc",
  ...theme.applyStyles('dark', {
    backgroundColor: "#333b4d"
  }),
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "24px",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient(136deg, #D94636 0%, #c13950 100%)",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
  }),
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient(136deg, #51F27E 0%, #3ACF3A 100%)",
  }),
  ["& .MuiSvgIcon-root"]: {
    fontSize: "24px",
  },
}));

function StepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    walljobs: <WalljobsIcon />,
    company_sign: <BusinessCenterIcon />,
    internship_sign: <SchoolIcon />,
    university_sign: <AccountBalanceIcon />,
    declined: <CloseIcon />,
  };

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[props.type]}
    </StepIconRoot>
  );
}

function Steps(props) {
  const { groups } = props;
  const { envelope } = useEnvelope();
  const { current_state } = envelope;

  const renderSteps = () => {
    return groups.map((group, index) => (
      <Step key={index}>
        <CustomStepLabel
          StepIconComponent={StepIcon}
          StepIconProps={{
            type: group.rejected ? "declined" : group.id,
          }}
        >
          {group.label}
        </CustomStepLabel>
      </Step>
    ));
  };

  const findCurrentGroup = () => {
    let index = groups.findIndex((group) => group.current_group);

    if (current_state == "completed") {
      index = groups.length;
    } else if (current_state == "rejected") {
      index = groups.findIndex((group) => group.rejected);
    }

    return index;
  };

  return (
    <Stepper
      activeStep={findCurrentGroup()}
      alternativeLabel
      connector={<Connector />}
      sx={{ padding: "30px 0px" }}
    >
      {renderSteps()}
    </Stepper>
  );
}

export default Steps;
