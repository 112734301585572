import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "./styles";
import { pathWithParams } from 'utils/pathWithParams';
import PATHS from "navigation/ROUTES";
import CandidateMentoring from "./CandidateMentoring";

export default ({ user, toggleDrawer }) => (
  <Menu>
    <li>
      <Link to={pathWithParams(PATHS.USERS.SHOW, {id: user?.slug})} onClick={toggleDrawer}>
        <i className="material-icons">assignment_ind</i> Meu cadastro
      </Link>
    </li>
    <li>
      <Link to={PATHS.USERS.WIZARD} onClick={toggleDrawer}>
        <i className="material-icons">edit</i> Editar perfil
      </Link>
    </li>
    <li>
      <Link to={PATHS.USERS.CANDIDATURES.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">done</i> Candidaturas
      </Link>
    </li>
    <li>
      <Link to={PATHS.ATS.JOBS.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">group</i> Vagas
      </Link>
    </li>
    <li>
      <Link to={PATHS.BENEFITS.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">card_giftcard</i> Clube de Benefícios
      </Link>
    </li>
    <li>
      <Link to={PATHS.INTERNSHIP.ENVELOPES.OWNED} onClick={toggleDrawer}>
        <i className="material-icons">insert_drive_file</i> Meus Contratos
      </Link>
    </li>
    <li>
      <Link to={PATHS.PERSONAL_DOCUMENTS.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">document_scanner</i> Documentos Pessoais
      </Link>
    </li>
    <li>
      <Link to={PATHS.USERS.CERTIFICATES.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">more_time</i> Horas Complementares
      </Link>
    </li>
    <li>
      <Link to={PATHS.TESTS.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">quiz</i> Testes de habilidade
      </Link>
    </li>
    <li>
      <CandidateMentoring />
    </li>
    <li>
      <Link to={PATHS.COMPANIES.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">domain</i> Empresas
      </Link>
    </li>
    <li>
      <Link to={PATHS.ATTENDEES.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">calendar_today</i> Agenda
      </Link>
    </li>
    <li>
      <Link to={PATHS.USERS.SETTINGS} onClick={toggleDrawer}>
        <i className="material-icons">settings</i> Configurações
      </Link>
    </li>
  </Menu>
);
