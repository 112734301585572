import React from "react";
import { UncontrolledInput as Input } from "components/form";

function SocialName(props) {
  const prefix = props.prefix || {
    id: "social_name",
    name: "social_name",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Caso possua, informe seu nome social..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Nome Social"}
        helperText="Por favor, só preencha esse campo caso você seja trans e possua um nome social diferente do nome de registro. Não preencha com apelido ou com apenas o seu primeiro nome."
      />
    </div>
  );
}

export default SocialName;
