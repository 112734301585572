import React from "react";
import { useFilterController } from "utils/hooks";

export const FilterContext = React.createContext();

function FilterController({ children }) {
  const [infos, filters, updateInfos, filterData] = useFilterController();

  return (
    <FilterContext.Provider value={{ infos, filters, updateInfos, filterData }}>
      {children}
    </FilterContext.Provider>
  );
}

export default FilterController;
