import React from "react";
import { useEnvelope } from "../context";

export default () => {
  const { envelope } = useEnvelope();

  if (!envelope.university_instructions) {
    return;
  }

  return (
    <>
      <br />
      <p>
        <b>Instruções da Universidade quanto à assinatura:</b>
      </p>
      <p>{envelope.university_instructions}</p>
    </>
  );
};
