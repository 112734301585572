import React, { useState, useEffect } from 'react';

function useFilters(params, filterData){
  const [filters, setFilters] = useState(params);
  const [filtersLabels, setFiltersLabels] = useState(params);

  function handleChange(event){
    const {name, value} = event.target
    setFilters({...filters, [name]: value})
  }

  function handleSelect(event, collection){
    handleChange(event);
    const selectedOption = collection.find(option => option.value == event.target.value)   
    setFiltersLabels({...filtersLabels, [event.target.name]: selectedOption.label})
  }

  function createFilterLabel(event){
    const {name, value} = event.target
    setFiltersLabels({...filtersLabels, [name]: value})
  }

  function renderFilters(){
    const filters = filtersLabels
    const list = Object.keys(filters).filter((key) => filters[key]).map((key) => [key, filters[key]])
    return list.map((filter, index) => (
      <label key={index}>{filter[1]}<i className="material-icons" onClick={() => _removeFilter(filter[0])}>close</i></label>
    ))
  }

  function _removeFilter(key){
    setFilters({...filters, [key]: ""})
    setFiltersLabels({...filtersLabels, [key]: ""})
  }

  useEffect(() => {
    if(filters !== params){
      filterData(filters);
    }
  }, [filtersLabels])

  return {filters, handleChange, createFilterLabel, renderFilters, handleSelect}
}

export default useFilters;