import React, { useEffect } from "react";
import { UncontrolledInput as Input } from "components/form";
import VMasker from "vanilla-masker";

function DateOfBirth(props) {
  const prefix = props.prefix || {
    id: "date_of_birth",
    name: "date_of_birth",
  };

  const validation = {
    pattern: /([0][1-9]|[1-2][0-9]|[3][0-1])\/([0][1-9]|[1][0-2])\/((19)\d{2}|(20)[0-1]\d)/
  };

  const messages = {
    pattern: "Não segue o padrão DD/MM/AAAA ou não é uma data válida"
  };

  useEffect(() => {
    VMasker(document.querySelector(".date_of_birth")).maskPattern("99/99/9999");
  })

  return (
    <div className="form-group">
      <Input
        {...props}
        className={`date_of_birth ${props.className}`}
        placeholder="__/__/____"
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Data de Nascimento"}
        validation={validation}
        messages={messages}
      />
    </div>
  );
}

export default DateOfBirth;
