import { React, useEffect } from "react";
import Input from "components/form/NewLanding/Input";
import VMasker from "vanilla-masker";

function Cellphone(props) {
  const prefix = ((typeof props.prefix) === 'string') ? 
    {id: props.prefix, name: props.prefix}  : 
      props.prefix || {
        id: "phone",
        name: "phone",
      };

  useEffect(() => {
    VMasker(document.querySelector(".phone")).maskPattern('+99 (99) 99999-9999');
  }, [])

  return (
    <Input
      {...props}
      className={`phone ${props.className}`}
      placeholder="Telefone"
      name={prefix.name}
      id={prefix.id}
    />
  );
}

export default Cellphone;