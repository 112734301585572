import React from "react";
import { SearchableSelect } from "components/form";
import { useStaticCollection } from "utils/hooks";

function AcademicEntities(props) {
  const collection = useStaticCollection("/selects/academic_entities.json")

  const prefix = props.prefix || {
    id: "academic_entity_ids",
    name: "academic_entity_ids",
  };

  const sanitizeValue = (values) => {
    return values.map?.((option) => option?.value || option) || [];
  };

  return (
    <div className="form-group">
      <SearchableSelect
        {...props}
        id={prefix.id}
        name={prefix.name}
        collection={collection}
        placeholder="Selecione..."
        label={props.label || "Entidades acadêmicas"}
        sanitizeValue={sanitizeValue}
        multiple
      />
    </div>
  );
}
export default AcademicEntities;
