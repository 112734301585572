import user_info from "./models/user_info";
import contract_type from "./models/internship/contract_type";
import envelope from "./models/internship/envelope";
import update_form_answer from "./views/internship/update/update_form_answer";
import internship from "./views/internship/internship";
import admission from "./views/internship/admission";

export default {
  translations: {
    user_info,
    contract_type,
    envelope,
    update_form_answer,
    internship,
    admission
  }
}