import React, { useState, useCallback } from "react";
import Sidebar from "./header/sidebar/Sidebar";
import ProfileCompleteness from "./header/ProfileCompleteness";
import { Drawer } from "@mui/material";
import HeaderMenu from "./header/HeaderMenu";
import { connect } from "react-redux";

function Header(props) {
  const [sidebar, setSidebar] = useState(false);

  const { isLogged } = props;

  const toggleDrawer = useCallback(() => {
    setSidebar((state) => !state);
  });

  return (
    <>
      <HeaderMenu toggleDrawer={toggleDrawer} />
      {isLogged && <ProfileCompleteness />}
      {isLogged && (
        <Drawer
          anchor="right"
          open={sidebar}
          onClose={toggleDrawer}
          sx={[{
            '& .MuiDrawer-paper': {
              background: `linear-gradient(211.32deg, #7338c4 0%, #371b5e 100%)`,
              backgroundSize: "cover",
            }
          }]}
        >
          <Sidebar toggleDrawer={toggleDrawer} />
        </Drawer>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { isLogged } = state.session;
  return { isLogged };
}

export default connect(mapStateToProps)(Header);
