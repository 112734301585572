import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import React, { Children, useState } from 'react'
import { withStyles } from '@mui/styles';

const StyledMenu = withStyles({
  list:{
    padding: "6px"
  }
})(Menu)

const StyledMenuItem = withStyles({
  root:{
    height: "38px",
    padding: "0px 18px",
    gap: "18px",
    borderRadius: "3px",
    fontSize: "15px",
    transition: "0.2s linear all",
    '&:hover':{
      backgroundColor: "#7338c4",
      color: "white",
    },
    '& i': {
      fontSize: "15px"
    },
    '& a':{
      color: "inherit",
      display: "flex",
      gap: "18px",
      alignItems: "center",
      textDecoration: "none",
    },
  }
})(MenuItem)

function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItems = () => {
    const children = Children.toArray(props.children)

    return children.map((item, index) => (
      <StyledMenuItem key={index} onClick={handleClose}>{item}</StyledMenuItem>
    ))
  }

  return (
    <>
      <IconButton
        aria-controls={open && 'basic-menu'}
        aria-haspopup="true"
        aria-expanded={open && 'true'}
        onClick={handleClick}
      >
        <MoreIcon />
      </IconButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {renderMenuItems()}
      </StyledMenu>
    </>
  );
}

export default ActionMenu;