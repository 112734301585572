import React from "react";
import {
  Divider,
  Grid2 as Grid,
  List,
  ListItemButton,
  ListItemText,
  Button,
  Paper,
} from "@mui/material";

function not(a, b) {
  return a.filter((value) => !b.includes(value));
}

function intersection(a, b) {
  return a.filter((value) => b.includes(value));
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const buttonTransferStyle = {
  my: 1,
  width: "100%",
  height: "30px",
  fontSize: "13px",
  lineHeight: "22px",
  fontWeight: 500,
};

const CustomListItemText = (props) => (
  <ListItemText
    {...props}
    primaryTypographyProps={{ variant: "subtitle2" }} //will be removed in v7
    secondaryTypographyProps={{ variant: "caption" }} //will be removed in v7
    slotProps={{
      primary: { variant: "subtitle2" },
      secondary: { variant: "caption" },
    }} //not working yet
  />
);

export default (props) => {
  const [selected, setSelected] = React.useState([]);
  const [recentlyMoved, setRecentlyMoved] = React.useState([]);
  const [left, setLeft] = React.useState(props.left);
  const [right, setRight] = React.useState(props.right);

  const leftSelected = intersection(selected, left);
  const rightSelected = intersection(selected, right);

  const handleToggle = (value) => () => {
    const currentIndex = selected.indexOf(value);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    setRecentlyMoved(left);
  };

  const handleSelectedRight = () => {
    setRight(right.concat(leftSelected));
    setLeft(not(left, leftSelected));
    setSelected(not(selected, leftSelected));
    setRecentlyMoved(leftSelected);
  };

  const handleSelectedLeft = () => {
    setLeft(left.concat(rightSelected));
    setRight(not(right, rightSelected));
    setSelected(not(selected, rightSelected));
    setRecentlyMoved(rightSelected);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    setRecentlyMoved(right);
  };

  const numberOfSelected = (items) => intersection(selected, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfSelected(items) === items.length) {
      setSelected(not(selected, items));
    } else {
      setSelected(union(selected, items));
    }
  };

  const renderList = (items) => (
    <Paper sx={{ width: 200, height: 230, overflow: "auto" }}>
      <List component="div" role="list">
        {props.enhanced && (
          <>
            <ListItemButton role="listitem" onClick={handleToggleAll(items)}>
              <CustomListItemText primary="Selecionar todos" />
            </ListItemButton>
            <Divider />
          </>
        )}

        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}
              selected={selected.includes(value)}
              usage="transfer-list"
              recentlyMoved={recentlyMoved.includes(value)}
            >
              <CustomListItemText id={labelId} primary={value} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{
        my: 2,
        justifyContent: "center",
        alignItems: "center",
        "--Grid-columnSpacing": "16px",
      }}
    >
      <Grid item>{renderList(left)}</Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          sx={{ alignItems: "center", width: "64px" }}
        >
          {!props.enhanced && (
            <Button
              color="secondary"
              sx={buttonTransferStyle}
              variant="outlined"
              onClick={handleAllRight}
              disabled={left.length === 0}
            >
              &gt;&gt;
            </Button>
          )}
          <Button
            color="secondary"
            sx={buttonTransferStyle}
            variant="outlined"
            onClick={handleSelectedRight}
            disabled={leftSelected.length === 0}
          >
            &gt;
          </Button>
          <Button
            color="secondary"
            sx={buttonTransferStyle}
            variant="outlined"
            onClick={handleSelectedLeft}
            disabled={rightSelected.length === 0}
          >
            &lt;
          </Button>
          {!props.enhanced && (
            <Button
              color="secondary"
              sx={buttonTransferStyle}
              variant="outlined"
              onClick={handleAllLeft}
              disabled={right.length === 0}
            >
              &lt;&lt;
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item>{renderList(right)}</Grid>
    </Grid>
  );
};
