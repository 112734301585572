import axios from "axios";
import api from "services/api";
import store from "@root/redux/store";
import { USERS } from "navigation/ROUTES";

const defaultLogoutMessage = {
  title: "Sua sessão foi encerrada!",
  text: "Nos vemos na próxima!",
  icon: "success",
};

const logout = () => (dispatch) => {
  dispatch({ type: "sessions/logout" });
  return Promise.resolve();
};

export const updateAttributesInStore = () => {
  api.get("/v1/profile/user_infos/attributes_for_token").then(({ data }) => {
    store.dispatch({ type: "sessions/updateAttributes", payload: data });
  });
};

export const login = (data) => (dispatch) => {
  dispatch({ type: "sessions/login", payload: data });
  return Promise.resolve();
};

export const handleLogout = (navigate, message = defaultLogoutMessage) => {
  let state = store.getState();
  let token = state.session.access_token;

  const data = {
    token: token,
  };
  const tokenInfo = axios.create({ withCredentials: true });
  tokenInfo.post("/oauth/revoke", data).then(() => {
    store.dispatch(logout()).then(() => {
      swal(message.title, message.text, message.icon, {
        buttons: false,
        timer: 2200
      })
      .then(() => window.location.href = USERS.LOGIN)
      //navigate(USERS.LOGIN);
    });
  });
};

export const handleLogin = (data, navigate) => {
  const firstSignIn = data?.firstSignIn;
  const intern = data?.attributes?.intern;

  let message = "Seu login foi efetuado com sucesso! É bom ter você de volta!";
  let path = USERS.HOME;

  if (firstSignIn && !intern) {
    message =
      "Seja bem-vindo! Que tal preencher algumas informações básicas? É bem rapidinho!";
    path = USERS.ESSENTIAL_FIELDS;
  }

  swal("Olá!", message, "success");
  navigate(path);
};
