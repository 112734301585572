import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Collapse } from "@mui/material";

import StudyHour from "./StudyHour";
import { Checkbox, HiddenField } from "components/form";
import { useNestedAttributes } from "utils/hooks";

const day_names = {
  0: "De segunda a sexta",
  1: "Domingo",
  2: "Segunda",
  3: "Terça",
  4: "Quarta",
  5: "Quinta",
  6: "Sexta",
  7: "Sábado",
};

const StudyDayDiv = styled.div`
  width: fit-content;
`;

const AddButton = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  color: #333b4d;
  display: block;
  margin: 12px auto;

  &:hover {
    color: #7338c4;
  }
`;

function StudyDay(props) {
  const { day, prefix } = props;

  const [available, setAvailable] = useState(null);

  const hourPrefix = {
    id: `${prefix.id}_hours_attributes`,
    name: `${prefix.name}[hours_attributes]`,
  };

  const { list, addItem, destroyItem } = useNestedAttributes({
    ...props,
    defaultValues: day?.hours_attributes,
    prefix: hourPrefix,
  });

  const renderHours = () => {
    return list.map((hour, index) => {
      const properties = {
        ...props,
        hour,
        index,
        prefix: hourPrefix,
        destroyItem,
      };
      return (
        <StudyHour
          {...properties}
          key={hour?.id || hour?.key}
          disabled={!available}
        />
      );
    });
  };

  useEffect(() => {
    setAvailable(day?.available);
    props.setValue(`${prefix.name}[available]`, day?.available);
    props.setValue(`${prefix.name}[day_of_week]`, day?.day_of_week);
  }, [day]);

  return (
    <StudyDayDiv>
      {day?.id && (
        <HiddenField
          {...props}
          name={`${prefix.name}[id]`}
          id={`${prefix.id}_id`}
        />
      )}
      <HiddenField
        {...props}
        name={`${prefix.name}[day_of_week]`}
        id={`${prefix.id}_day_of_week`}
      />
      <Checkbox
        {...props}
        id={`${prefix.id}_available`}
        name={`${prefix.name}[available]`}
        required={false}
        label={`${day?.name || day_names[day?.day_of_week]}`}
        defaultChecked={available}
        handleChange={setAvailable}
      />
      <Collapse in={available} timeout="auto">
        {renderHours()}

        <AddButton type="button" onClick={addItem}>
          <i className="fa fa-plus"></i>
        </AddButton>
      </Collapse>
    </StudyDayDiv>
  );
}

export default StudyDay;
