import React, { Fragment } from 'react'
import CreatableSelect from 'react-select/lib/Creatable';

const textStyles = {
  color: "#222",
  fontSize: "14px",
  fontWeight: "400",
  fontFamily: '"Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif'
}

const customStyles = {
  input: (base) => ({
    ...base,
    ...textStyles
  }),
  placeholder: (base) => ({
    ...base,
    ...textStyles,
    color: "#999"
  }),
  menu: (base) => ({
    ...base,
    ...textStyles,
    color: "#555"
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#337ab7" : undefined,
    color: state.isFocused ? "white" : "#555"
  }),
  singleValue: (base) => ({
    ...base,
    ...textStyles
  })
}

function SelectField(props){
  
  const handleChange = (newValue) => {
    props.onChange?.(newValue)
  };

  return(
    <Fragment>
      <label 
        className={`select ${props.required ? 'required' : 'optional'}`}
        htmlFor={props.id}>
          {props.required ? <abbr title="Obrigatório">*</abbr> : ''} {props.label}
      </label>
      <CreatableSelect
        onChange={handleChange}
        id={props.id}
        name={props.name}
        className={`string ${props.required ? 'required' : 'optional'}`}
        required={props.required}
        options={props.collection}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        isSearchable={props.isSearchable}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        noOptionsMessage={() => <span>Sem opções disponíveis</span>}
        formatCreateLabel={(value) => <span>Adicionar "{`${value}`}"</span>}
        styles={customStyles}
      />
    </Fragment>
  )
}

export default SelectField;