import React, { Children } from "react";
import { Breadcrumbs } from "@mui/material";

export default (props) => {
  const renderLinks = () => {
    const children = Children.toArray(props.children);

    return children.map((item) =>
      React.cloneElement(
        item,
        { underline: "none", variant: "subtitle2" },
      )
    );
  };

  return (
    <Breadcrumbs
      {...(props.arrowSeparator && {
        separator: (
          <span className="material-symbols-rounded">arrow_right_alt</span>
        ),
      })}
      {...props}
    >
      {renderLinks()}
    </Breadcrumbs>
  );
};
