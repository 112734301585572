import useSWR from 'swr';
import api from "services/api";

const fetcher = (url) => api.get(url).then((res) => res.data);

function useFetch(url, options = {}) {
  const defaults = { focusThrottleInterval: 10000 }; 
  let opts = Object.assign({}, defaults, options); 
  const { data, error, mutate } = useSWR(url, fetcher, opts); 

  return {
    data,
    mutate,
    isLoading: !error & !data,
    isError: error,
  };
}

export default useFetch;
