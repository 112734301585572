import React from 'react';
import { UncontrolledInput as Input } from 'components/form';

function Company(props) {
  const { index, prefix } = props;

  return (
    <Input
      {...props}
      placeholder="Informe a empresa onde trabalhou..."
      type="text"
      name={`${prefix}[${index}][company]`}
      id={`${prefix}_${index}_company`}
      label="Empresa"
    />
  );
}

export default Company;