import React from 'react';
import { SelectInput } from 'components/form';

function Level(props) {
  const { index, prefix } = props;

  const levels = [
    { value: "beginner", label: "Iniciante" },
    { value: "intermediate", label: "Intermediário" },
    { value: "advanced", label: "Avançado" },
  ];

  return (
    <SelectInput
      {...props}
      placeholder="Selecione o nível..."
      name={`${prefix}[${index}][level]`}
      id={`${prefix}_${index}_level`}
      collection={levels}
      label={props.label || "Nível"}
    />
  );
}

export default Level;