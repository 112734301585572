import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { formPost } from "services/api";
import { updateAttributesInStore } from "@root/redux/features/sessions/actions";
import { EditModeContext } from "./context";
import styled from "styled-components";
import { HiddenField } from "components/form";
import { useUserInfo } from "pages/profile/users/Show/context";

const Buttons = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 30px;
  justify-content: flex-end;
`;

const Form = styled.form`
  div.form-group{
    margin-bottom: 1.5rem;
  }
`

export default (props) => {
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const { defaultValues } = props;

  const EditMode = React.useContext(EditModeContext);

  const { mutate: updateUserInfo } = useUserInfo();

  const {
    register,
    unregister,
    control,
    resetField,
    reset,
    setValue,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  function getData(collectedData) {
    setData(collectedData);
  }

  function submit() {
    setIsFetching(true);
    swal("Aguarde", "Estamos atualizando suas informações", "info");
    const user = { user_info_attributes: data };
    formPost(`/v1/profile/users`, { user })
      .then(() => successfulRequisition())
      .catch((error) => unsuccessfulRequisition(error.response.data))
      .finally(() => setIsFetching(false));
  }

  function successfulRequisition() {
    swal(
      "Tudo certo!",
      "As informações dessa seção foram atualizadas com sucesso! Obrigado por manter seu perfil atualizado!",
      "success"
    );
    updateAttributesInStore();
    EditMode.handleEditModal();
    updateUserInfo?.();
  }

  function unsuccessfulRequisition(data) {
    const { errors, messages } = data;
    for (let field in errors) {
      errors[field].forEach((message) => {
        let pattern = /\.(.*)(\[\d*\])+\.(.*)/i
        let match = field.match(pattern);
        let f = field;
        if(match?.length > 0) {
          f = match[1] + "_attributes" + match[2] + '[' + match[3] + ']';
        }
        setError(f, { type: "manual", message });
      });
    }

    swal(
      "Ops!",
      "Não foi possível atualizar as informações dessa seção. Por favor, corrija os itens solicitados e tente novamente!",
      "error"
    );
  }

  function renderFields() {
    return EditMode.fieldList.map((item) =>
      React.cloneElement(
        item,
        {
          resetField,
          setValue,
          register,
          unregister,
          control,
          getValues,
          errors,
          key: item?.type?.name,
        },
        null
      )
    );
  }

  function submitText() {
    if (props.origin == "candidatureRequired" || props.origin == "candidatureReview"){
      return "Me candidatar";
    } 
    
    return "Atualizar";
  }

  useEffect(() => {
    if (!_.isEmpty(data)) submit();
  }, [data]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <Form onSubmit={handleSubmit(getData)}>
      <HiddenField
        register={register}
        setValue={setValue}
        control={control}
        name="id"
        value={defaultValues.id}
      />
      {renderFields()}
      <Buttons>
        <button
          className="button button-secondary"
          type="button"
          onClick={EditMode.handleEditModal}
        >
          Cancelar
        </button>
        <button className="button button-primary" type="submit" disabled={isFetching}>
          {submitText()}
        </button>
      </Buttons>
    </Form>
  );
};
