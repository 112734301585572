import React from "react";
import styled from "styled-components";

const BenefitDiv = styled.div`
  width: 110px;

  img {
    width: 90px;
    height: 90px;
    margin: 0 auto;
    display: block;
    border-radius: 45px;
  }

  p {
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
  }
`;

const Benefit = props => (
  <BenefitDiv>
    <img src={props.path} alt={props.name} />
    <p>{props.name}</p>
  </BenefitDiv>
);

export default Benefit;
