import React from "react";
import styled from "styled-components";
import _ from "lodash";

const DocumentUpload = styled.div`
  input[type="file"] {
    display: none;
  }

  label,
  a {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  label {
    width: 100%;
    display: inline-block;
  }

  a {
    display: block;
    text-decoration: none;
  }

  a span {
    margin-right: 5px;
  }

  a:not(:last-of-type) {
    margin-bottom: 7px;
  }
`;

function File(props) {
  const validation = { ...{ required: props.required }, ...props.validation };

  const messages = {
    ...{ required: "Não pode ficar em branco" },
    ...props.messages,
  };

  const error = _.get(props.errors, props.name);

  const inputRef = props.register?.(props.name, validation);

  const formatUploadLabel = (event) => {
    let div = document.querySelector(`.document-upload.${props.id}`);
    let fileName;
    if (event.target.files && event.target.files.length > 1) {
      fileName = event.target.files.length + " arquivos";
    } else {
      fileName = event.target.value.split("\\").pop();
    }
    if (!fileName) {
      fileName = "Upload";
    }
    div.querySelector("span").innerHTML = fileName;
  };

  const handleChange = (event) => {
    formatUploadLabel(event);
    props.onChange?.(event);
  };

  return (
    <DocumentUpload className={`document-upload ${props.id}`}>
      <label>{`${props.label}`}</label>
      <br />
      <label
        className="lbl-upload button button-info modal-button"
        htmlFor={props.id}
      >
        <i className="fa fa-cloud-upload"></i>&nbsp;&nbsp;
        <span>{props.buttonLabel}</span>
      </label>
      <input
        accept={props.accept}
        id={props.id}
        name={props.name}
        type="file"
        required={props.required}
        onChange={(e) => {
          inputRef?.onChange?.(e);
          handleChange(e);
        }}
        multiple={props.multiple}
        ref={inputRef?.ref}
      />
    </DocumentUpload>
  );
}

export default File;
