import React from "react";
import { MenuItem, MenuList, Paper, styled, Tooltip } from "@mui/material";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import AccountTreeIcon from "@mui/icons-material/AccountTree";

const IconRoot = styled("div")(({ theme, active }) => ({
  color: "#ccc",
  ...theme.applyStyles('dark', {
    backgroundColor: "#333b4d"
  }),
  zIndex: 1,
  ...(active && {
    color: "#333b4d",
    ...theme.applyStyles('dark', {
      color: "#fff"
    })
  }),
}));

const CustomMenuItem = styled(MenuItem)(({ theme, active }) => ({
  minHeight: "fit-content",
  background: "rgba(0, 0, 0, .05)",
  ...theme.applyStyles('dark', {
    background: "rgba(255, 255, 255, .05)"
  }),
  ...(active && {
    background: theme.palette.background.paper,
  })
}));

export default (props) => {
  const { component, changeComponent } = props;

  return (
    <Paper sx={{ width: "fit-content", height: "fit-content" }}>
      <MenuList
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          padding: "0px",
        }}
      >
        <CustomMenuItem onClick={() => changeComponent("stepper")} active={component == "stepper"}>
          <Tooltip title="Ver como passo a passo">
            <IconRoot active={component == "stepper"}>
              <PlaylistAddCheckCircleIcon sx={{fontSize: "22px"}} />
            </IconRoot>
          </Tooltip>
        </CustomMenuItem>
        <CustomMenuItem onClick={() => changeComponent("flow")} active={component == "flow"}>
          <Tooltip title="Ver como diagrama">
            <IconRoot active={component == "flow"}>
              <AccountTreeIcon sx={{fontSize: "22px"}} />
            </IconRoot>
          </Tooltip>
        </CustomMenuItem>
      </MenuList>
    </Paper>
  );
};
