import React, { useEffect } from 'react';
import { UncontrolledInput as Input } from 'components/form';
import VMasker from "vanilla-masker";

function EndDate(props) {
  const { prefix, index } = props;

  const validation = {
    pattern: /([0][1-9]|[1][0-2])\/(19|20)\d{2}$/
  };

  const messages = {
    pattern: "Não segue o padrão MM/AAAA ou não é uma data válida",
  };

  useEffect(() => {
    VMasker(document.querySelectorAll(".end_date")).maskPattern("99/9999");
  }, []);

  return (
    <Input
      {...props}
      className="end_date"
      placeholder="MM/AAAA"
      type="text"
      id={`${prefix}_${index}_end_date`}
      name={`${prefix}[${index}][end_date]`}
      label="Término"
      validation={validation}
      messages={messages}
      disabled={props.disabled}
    />
  );
}

export default EndDate;