import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import VerifiedIcon from '@mui/icons-material/Verified';

const Text = styled.small`
  font-weight: 600;
  text-align: center;
  display: block;
  width: 100%;
  background-color: ${({theme}) => theme.mode === "light" ? '#ffd900' : '#ccad00'};
  color: ${({theme}) => theme.mode === "light" ? "rgba(0, 0, 0, .5)" : "rgba(0, 0, 0, .6)"};
  padding: 3px 4px 3px 4px;
  margin-bottom: -15px;
  border-radius: 4px 4px 0px 0px;
  z-index: 2;

  @media screen and (max-width: 767px) {
    border-radius: 0;
  }
`

function mapStateToProps(state) {
  const user = state.session?.attributes;

  return { user };
}

export default connect(mapStateToProps)((props) => {
  const { job, user } = props;

  if (!job.ies_exclusive || !user.talent_hub_ies_student) return;

  return (
    <Text><VerifiedIcon fontSize='small' sx={{marginRight: '5px'}} /> Exclusiva para a sua Instituição de Ensino</Text>
  )
});
