import React from "react";
import styled from "styled-components";
import loading from "assets/images/loading.gif";

const Loader = styled.div`
  ${(props) =>
    !props.displayLoader &&
    `
  height: calc(100vh - 110px); //tamanho do header
  display: flex;
  justify-content: center;
  align-items: center;
  `}

  img {
    width: ${props => props.displayLoader ? '90px' : '130px'};
    height: ${props => props.displayLoader ? '90px' : '130px'};
    position: relative;
    display: block;
    ${props => props.displayLoader && `margin: 20px auto`};
  }

`;

export default (props) => (
  <Loader className="loader" displayLoader={props.displayLoader}>
    <img src={loading} alt="Carregando" />
  </Loader>
);
