import React from "react";
import { UncontrolledInput as Input } from "components/form";

function Facebook(props) {
  const prefix = props.prefix || {
    name: "facebook",
    id: "facebook",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe seu Facebook..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Facebook"}
      />
    </div>
  );
}

export default Facebook;
