import React from "react";
import styled from "styled-components";

const CardBoxImage = styled.div`
  img {
    width: 67px;
    height: 67px;
    display: block;
    position: relative;
    margin: 0 auto;
  }
`;

export default (props) => (
  <CardBoxImage className="card-box-image">
    <img src={props.image} alt="Avatar" />
  </CardBoxImage>
);
