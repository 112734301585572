import React from "react";
import styled from "styled-components";

const Iframe = styled.iframe`
  width: 100%;
  height: 400px;
  padding: 2px 4px 2px 2px;
  margin-bottom: -6px;

  @media screen and (max-width: 767px) {
    height: 240px;
  }
`;

export default ({ url }) => (
  <Iframe
    src={url}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></Iframe>
);
