import React from "react";
import { Checkbox } from "components/form";

export default (props) => {
  const prefix = {
    id: "otp_required_for_login",
    name: "otp_required_for_login"
  };

  return(
    <Checkbox
      {...props}
      id={prefix.id}
      name={prefix.name}
      required={false}
      defaultChecked={props.defaultChecked}
      label="Utilizar duplo fator de autenticação"
    />
  )
}