import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: "all linear .3s",
    outline: "none!important",
    display: (props) => props.hideInDesktop ? "none" : "inline-flex",

    "@media screen and (max-width: 767px)": {
      display: "inline-flex!important"
    }
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
});

export default (props) => {
  const classes = useStyles({ hideInDesktop: props.hideInDesktop });
  const { expanded, setExpanded } = props;

  return (
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      onClick={() => setExpanded(!expanded)}
      aria-expanded={expanded}
      aria-label="Mostrar mais"
    >
      <ExpandMoreIcon />
    </IconButton>
  );
};
