import React from "react";
import { Header, Footer } from "components/ui";

export default ({ element }) => (
  <>
    <Header />
    {element}
    <Footer />
  </>
);
