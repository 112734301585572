import React from "react";
import styled from "styled-components";

const CardBoxFooter = styled.small`
  display: inline-block;
  margin-top: 3px;
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default (props) => (
  <CardBoxFooter className="card-box-footer">
    {props.children}
  </CardBoxFooter>
)