import React, { useEffect, useState } from 'react'
import _ from "lodash";

function HiddenField(props) {
  const [value, setValue] = useState("")
 
  const inputRef = props.register?.(props.name)

  useEffect(() => {
    setValue(props.value || _.get(props.getValues?.(), props.name) || "")
  }, [props.value, props.control._fields])

  useEffect(() => {
    props.handleChange?.(value);
  }, [value])

  return (
    <input
      type="hidden"
      id={props.id}
      name={props.name}
      value={value}
      ref={inputRef?.ref}
    />
  )
}

export default HiddenField
