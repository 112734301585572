import React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import colors from "styles/colors.js";
import { useTheme } from "@mui/material";

export default (props) => {
  const theme = useTheme();

  return (
    <LineChart
      colors={[colors.dodgeBlue[400]]}
      slotProps={{
        legend: { labelStyle: theme.typography.overline },
      }}
      {...props}
    />
  );
};
