export default {
  status: {
    not_initialized: 'Não criado',
    created: 'Criado',
    sent: 'Enviado',
    delivered: 'Entregue',
    declined: 'Rejeitado',
    voided: 'Anulado' ,
    signed: 'Assinado',
    completed: 'Assinado',
    review: 'Em análise IES',
    approved: 'Aprovado',
    not_defined: 'Não definido',
    finished: 'Concluído'
  },
  workflow: {
    actions: {
      download: {
        internship: "Ao clicar em 'Continuar' você fará o download do contrato assinado pela empresa e por você. Será necessário imprimir ou enviar esse documento para sua faculdade.\n\n Após a assinatura da faculdade, você poderá efetuar o upload do contrato assinado por TODOS clicando no botão 'Fazer upload' que se encontra nessa mesma página.\n\n Após isso, o contrato será validado pela empresa.",
        walljobs: "Ao clicar em 'Continuar' você fará o download do contrato com as assinaturas da empresa e do estagiário. Será necessário encaminhar esse documento ao estagiário para que ele possa imprimir o mesmo e enviá-lo à faculdade.\n\n Após a assinatura da faculdade, o estagiário ou você deverá efetuar o upload do contrato assinado por TODOS clicando no botão 'Fazer upload' que aparecerá nessa mesma página.",
        base_contract: "Ao baixar esse contrato, será necessária a realização do preenchimento dos dados faltantes e, em seguida, você deverá inserir o documento em 'Fazer upload' para que siga o processo de assinaturas, conforme configurado em nosso ambiente.",
      }
    }
  }
}