import React from "react";
import { UncontrolledInput as Input } from "components/form";

function Skype(props) {
  const prefix = props.prefix || {
    name: "skype",
    id: "skype",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe seu Skype..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Skype"}
      />
    </div>
  );
}

export default Skype;
