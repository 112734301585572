import React from "react";
import { SelectInput } from "components/form";

function DisabilityType(props) {
  const { prefix, index } = props;

  return (
    <div className="col-md-4">
      <div className="form-group">
        <SelectInput
          {...props}
          placeholder="Selecione o tipo de deficiência..."
          name={`${prefix}[${index}][disability_type_id]`}
          id={`${prefix}_${index}_disability_type_id`}
          collection={props.disabilityTypes}
          label={props.label || "Tipo de Deficiência"}
        />
      </div>
      <br />
    </div>
  );
}

export default DisabilityType;
