import React from "react";
import {
  Course,
  CurrentSemester,
  FormationDate,
  University,
  NotInUniversity
} from "components/users/fields/academic";

export default (props) => {
  const defaultValue = props.getValues?.("not_in_university")?.toString() || false;
  const [showAcademic, setShowAcademic] = React.useState(defaultValue !== "true");

  const handleChange = (value) => {
    setShowAcademic(value !== "true");
  } 

  return (
    <>
      <div className="col-md-12">
        <NotInUniversity {...props} handleChange={handleChange} />
      </div>
      {showAcademic && <UniversityFields {...props} />}
    </>
  );
};

function UniversityFields(props) {
  return (
    <>
      <div className="col-md-12">
        <University {...props} required />
      </div>
      <div className="col-md-12">
        <Course {...props} required />
      </div>
      <div className="col-md-12">
        <CurrentSemester {...props} required />
      </div>
      <div className="col-md-12">
        <FormationDate {...props} required />
      </div>
    </>
  );
}
