const getInitialState = () => {
  return JSON.parse(localStorage.getItem("sessions")) || {};
}

const initialState = getInitialState();

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case "sessions/login":
      localStorage.setItem("sessions", JSON.stringify({...state, ...action.payload}))
      return { ...state, ...action.payload };
    case "sessions/updateAttributes":
      return { ...state, attributes: action.payload };
    case "sessions/unauthUser":
      localStorage.removeItem("sessions");
      return { ...state, isLogged: false}
    case "sessions/logout":
      localStorage.removeItem("sessions");
      return {}
    default:
      return state;
  }
}
