export default {
  form_answer: {
    default: {
      title: "Formulário de Admissão",
      successfulRequisition: {
        title: "Cadastro realizado com sucesso!",
        text: "Em breve, retornaremos com o seu contrato de estágio. Não esqueça de verificar a sua caixa de e-mail, pois enviaremos um e-mail para a conclusão do seu cadastro na plataforma WallJobs, onde você poderá verificar todas as questões do seu estágio, além de ter acesso ao nosso clube de benefícios!"
      },
      unsuccessfulRequisition: {
        title: "Ops!",
        text: "Não foi possível atualizar seus dados! Por favor, tente novamente."
      },
    },
    stellantis: {
      title: "Atualização Cadastral",
      successfulRequisition: {
        title: "Cadastro atualizado com sucesso!",
        text: "Não esqueça de verificar a sua caixa de e-mail, pois enviaremos um e-mail para a conclusão do seu cadastro na plataforma WallJobs!"
      },
      unsuccessfulRequisition: {
        title: "Ops!",
        text: "Não foi possível atualizar seus dados! Por favor, tente novamente."
      }
    }
  }
}