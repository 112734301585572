import { palette } from "./palette";
import { components } from "./components";
import { typography } from "./typography";
import { shadows } from "./shadows";

export const getDesignTokens = (mode) => ({
  palette: palette(mode),
  typography,
  spacing: 4,
  shadows,
  components,
});
