import React from 'react';
import { UncontrolledInput as Input } from 'components/form';

function InstitutionName(props) {
  const { prefix, index } = props;
  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe a instituição de ensino..."
        type="text"
        name={`${prefix}[${index}][institution_name]`}
        id={`${prefix}_${index}_institution_name`}
        label="Instituição"
      />
    </div>
  );
}

export default InstitutionName;