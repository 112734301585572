import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import _ from "lodash";

export default (props) => {
  const defaultChecked = setDefaultChecked();
  const [checked, setChecked] = useState(defaultChecked);

  const validation = { ...{ required: props.required }, ...props.validation };

  const messages = {
    ...{ required: "Não pode ficar em branco" },
    ...props.messages,
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.handleChange?.(event.target.checked);
  };

  function setDefaultChecked() {
    return (
      props.defaultChecked || _.get(props.getValues?.(), props.name) || false
    );
  }

  useEffect(() => {
    setChecked(_.get(props.getValues?.(), props.name) || false);
  }, [props.control?._formValues]);

  useEffect(() => {
    setChecked(props.defaultChecked || false);
  }, [props.defaultChecked]);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={validation}
      defaultValue={checked}
      render={({ field: { onChange, ref } }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...props}
              checked={checked}
              inputRef={ref}
              onChange={(e) => {
                onChange(e);
                handleChange(e);
              }}
            />
          }
          label={props.label}
          labelPlacement={props.labelPlacement}
        />
      )}
    />
  );
}