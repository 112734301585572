import React, { useState, useCallback } from "react";
import Sidebar from "../header/sidebar/Sidebar";
import { Drawer } from "@mui/material";
import { connect } from "react-redux";
import HeaderMenu from "./HeaderMenu";

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);

  const { isLogged } = props;

  const toggleDrawer = useCallback(() => {
    setIsOpen(state => !state);
  }, [])

    return (
      <>
        <HeaderMenu toggleDrawer={toggleDrawer} isLogged={isLogged} />
        {isLogged && <Drawer anchor='right' open={isOpen} onClose={toggleDrawer}>
          <Sidebar toggleDrawer={toggleDrawer} />
        </Drawer>}
      </>
  );
}

function mapStateToProps(state){
  const { isLogged } = state.session
  return { isLogged }
}

export default connect(mapStateToProps)(Header);
