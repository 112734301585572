import React from "react";
import styled from "styled-components";

const Paragraph = styled.p`
  @media screen and (max-width: 767px) {
    & {
      text-align: center;
    }
  }
`;

export default () => (
  <Paragraph>
    <b>Não informado.</b> &#128531;
  </Paragraph>
);
