import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const CandidateMentoring = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <a href="#" onClick={handleOpen}>
        <i className="material-icons">school</i> Mentoria de Carreira Walljobs
      </a>
      <Dialog open={open} onClose={handleOpen}>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "15px" }}>
            <h1>Mentoria de Carreira Walljobs</h1>
            <p>
              Todas as <b>quintas-feiras</b>, às 14:00 horas, a especialista de carreiras
              do WallJobs te atenderá de forma coletiva, dando dicas de
              carreira e currículo!
            </p>
          </DialogContentText>
          <DialogActions sx={{ padding: "20px 24px", gap: "10px" }}>
            <button
              className="button button-secondary modal-button"
              type="button"
              onClick={handleOpen}
            >
              Voltar
            </button>
            <a
              className="button button-primary modal-button"
              href="https://meet.google.com/tuo-gxsq-tne?hs=224s"
              target="_blank"
              onClick={handleOpen}
            >
              Acessar
            </a>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CandidateMentoring;