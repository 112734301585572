import React from "react";
import { SelectInput } from "components/form";

function Period(props) {
  const prefix = props.prefix || {
    id: "period",
    name: "period",
  };

  const collection = [
    { value: "matutinal", label: "Matutino" },
    { value: "vespertine", label: "Vespertino" },
    { value: "nocturnal", label: "Noturno" },
    { value: "integral", label: "Integral" },
    { value: "ead", label: "EAD" },
  ];

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Período de estudo"}
      />
    </div>
  );
}
export default Period;
