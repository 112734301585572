import React from "react";
import styled from "styled-components";
import { Paper } from "@mui/material";

const CardBox = styled(Paper)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${(props) =>
    props.disabled && (props.theme.mode === "light"
      ? `{
            filter: grayscale(100%);
            .card-box-body {
              opacity: 0.75;
            }
          }`
      : `{
        .card-box-body {
          filter: grayscale(100%);
          opacity: 0.65;
        }
      }`)}

  .card-box-body {
    width: 100%;
    text-decoration: none;
    display: grid;
    grid-template-columns: 67px calc(100% - 87px);
    grid-gap: 20px;
    padding: 30px 20px 20px;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => theme.text.primary};
  }

  .card-box-body:hover h3 {
    color: ${({theme}) => theme.mode === "light" ? "#7338c4" : theme.text.secondary};
  }

  @media screen and (max-width: 767px) {
    &.card-box {
      width: 100%;
      height: 100%;
      margin: 0;
      box-shadow: none;
      border-radius: 0px;
      border-bottom: 1px solid
        ${({theme}) => (theme.mode === "light" ? "#ececec" : "#333b4d")};

      .card-box-body {
        grid-gap: 20px;
      }
    }
  }
`;

export default (props) => (
  <CardBox className="card-box" disabled={props.disabled}>
    {props.children}
  </CardBox>
);
