import React from "react";
import styled from "styled-components";

const Tip = styled.p`
  font-size: 0.9rem;
  margin-top: 15px;
  color: ${({ theme }) => theme.text.secondary};

  a{
    color: ${({ theme }) => theme.info[700] };
  }
`;

export default ({children}) => (
  <Tip>{children}</Tip>
)