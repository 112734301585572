import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import styled from 'styled-components';

const Footer = styled.span`
  font-weight: 600;
  font-size: 18px;
`

function PhotoGallery({ photos }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const CustomModalFooter = ({ currentIndex, views }) => {
    const activeView = currentIndex + 1
    const totalViews = views.length
  
    if (!activeView || !totalViews) return;
    
    return (
      <Footer>
        {activeView} de {totalViews}
      </Footer>
    )
  }

  return (
    <>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen && (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              components={{ FooterCount: CustomModalFooter }}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </>
  );
}

export default PhotoGallery;
