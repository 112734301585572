import Avatar from "./Avatar";
import Facebook from "./Facebook";
import Hobbies from "./Hobbies";
import Instagram from "./Instagram";
import InterestingFact from "./InterestingFact";
import Linkedin from "./Linkedin";
import MessagePhone from "./MessagePhone";
import MotherName from './MotherName';
import Motivations from "./Motivations";
import Phone from "./Phone";
import RG from './RG';
import RGState from './RGState';
import SexualOrientation from "./SexualOrientation";
import Skype from "./Skype";

export {
  Avatar,
  Facebook,
  Hobbies,
  Instagram,
  InterestingFact,
  Linkedin,
  MessagePhone,
  MotherName,
  Motivations,
  Phone,
  RG,
  RGState,
  SexualOrientation,
  Skype
}