export default {
  title: "Dados Acadêmicos e de Estágio",
  questions: {
    activity: {
      question: "Houve alguma mudança nas suas atividades de estágio?",
      placeholder: "Descreva muito bem no mínimo três atividades...",
      label: "Atividades",
      radio: {
        yes: "Sim",
        no: "Não"
      }
    },
    internship_type: {
      question: "Vai haver mudança na sua modalidade de estágio?",
      radio: {
        yes: "Sim",
        no: "Não"
      }
    },
    schedule: {
      question: "Vai haver mudança no seu horário de estágio?",
      schedule_from: "Insira o horário de inicio",
      schedule_to: "Insira o horário de saída",
      from: "De",
      to: "Até",
      radio: {
        yes: "Sim",
        no: "Não"
      }
    },
    sub_area: {
      question: "Houve alguma mudança na sua área de atuação do Estágio?",
      placeholder: "Selecione sua área de atuação...",
      label: "Área de atuação",
      radio: {
        yes: "Sim",
        no: "Não"
      }
    },
    university: {
      question: "Você mudou de universidade?",
      radio: {
        yes: "Sim",
        no: "Não"
      }
    },
    personal_documents: {
      title: "Comprovante de Matrícula",
      label: "Faça upload do seu comprovante de mátricula"
    },
    categories: {     
      label: "Faça o upload do seu "
    },
    company_manager: {
      supervisor_infos: "Dados do novo supervisor",
      question: "Houve mudança de supervisor?",
      radio: {
        yes: "Sim",
        no: "Não"
      },
      name: {
        placeholder: "Selecione ou digite o nome do supervisor",
        label: "Nome"
      }, 
      email: {
        placeholder: "Digite o email do supervisor",
        label: "Email"
      },
      job_title: {
        placeholder: "Digite o cargo do supervisor",
        label: "Cargo"
      }
    },
    question_answer: {
      placeholder: "Digite aqui sua resposta..."
    },
    observation: {
      placeholder: "Escreva aqui sua dúvida ou observação caso você precise...",
      label: "Tem alguma dúvida ou observação? Deixe aqui:"
    }
  },
  form: {
    successfulRequisition: {
      title: "Respostas enviadas com sucesso!",
      text: "Suas informações foram atualizadas.",
      icon: "success"
    },
    unsuccessfulRequisition: {
      title: "Ops!",
      text: "Não foi possível atualizar seus dados! Por favor, tente novamente.",
      icon: "error"
    },
    submit: "Enviar"
  },
  header: {
    title: "Formulário de Atualização de Cadastro"
  }
}