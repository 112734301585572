import React from "react";
import { UncontrolledInput as Input } from "components/form";
import styled from "styled-components";

const Tip = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.text.secondary};
`

function InstitutionalEmail(props) {
  const prefix = props.prefix || {
    id: "institutional_email",
    name: "institutional_email",
  };

  const validation = { 
    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i 
  };

  const messages = { 
    pattern: "E-mail inválido",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe seu e-mail"
        type="email"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "E-mail institucional"}
        validation={validation}
        messages={messages}
      />
      <Tip>
        Caso na instituição de ensino em que você estuda não haja um email institucional por estudante, preencha esse campo com o seu e-mail de contato, novamente, por favor.
      </Tip>
    </div>
  );
}

export default InstitutionalEmail;
