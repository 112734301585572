import styled from "styled-components";

export const MainDiv = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;

  @media(max-width: 820px){
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 24px;
    height: auto;
  }
`

export const LeftDiv = styled.div`
  display: flex;
  width: 638px;
  height: 100vh;
  padding: 92px 40px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: #367FA9;

  .board_ies{
    width: 130px;
    height: 118px;
    flex-shrink: 0; 
  }

  .company{
    width: 533.99px;
    height: 446.66px;
    top: 29.7px;
    left: 8.6px;
  }

  @media(max-width: 820px){
    .board_ies{
      display: flex;
      width: 100%;
      padding: 24px;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      height: auto;
    }
  }
`

export const InfoDiv = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.10);

  @media(max-width: 820px){
    padding: 12.038px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8.025px;
    border-radius: 12.038px;
  }
`

export const IesLogo = styled.img`
  width: 70px;
  height: 70px; 
  background: #D9D9D9;

  @media(max-width: 820px){
    width: 35.11px;
    height: 35.11px;
  }
`

export const InfoText = styled.p`
  align-self: stretch;
  color: #FFF;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 52px; /* 162.5% */

  @media(max-width: 820px){
    display: none;
  }
`

export const RightDiv = styled.div`
  display: flex;
  padding: 52px 40px 52px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 37px;
  flex: 1 0 0;
  align-self: stretch;
  background-color: #FDFBFB;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 52px;
  align-self: stretch;
`

export const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
`

export const WelcomeText = styled.h2`
  align-self: stretch;
  color: #666;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Source Sans Pro";
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 52px; /* 108.333% */
  margin-bottom: 0;
`

export const AlreadyRegistered = styled.p`
  color: #666;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px; /* 133.333% */
`

export const LoginHere = styled.a`
  color:  var(--Secondary---Dodge-blue-06, #009BF6);
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-decoration: none;
`

export const BoxForm = styled.div`
  display: flex;
  max-width: 909px;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: center;
  border-radius: 24px;
  border: 1px solid #DDD;
  background: #F9F9F9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  
  @media(max-width: 820px){
    min-width: 272px;
    align-self: stretch;
  }
`

export const DivTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  div {
    color: #367FA9;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 228.571% */
  }
`

export const LoginData = styled.p`
  width: 376px;
  color: #367FA9;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px; /* 160% */
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

export const BoxFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 32px;
  align-self: stretch;
  flex-wrap: wrap;

  a {
    text-decoration: none;
  }

  p {
    display: ${(props) => props.paragraphDisplay || "none"};
  }
`

export const Button = styled.button`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 0;
  background: ${(props) => props.disabled ? "#7F7F7F" : "#5CB85C"};
  color: #FDFBFB;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 250% */
`