import React from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Badge } from "@mui/material";

const StyledBadge = styled(Badge)(({theme, size}) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom: "10%",
    right: "10%",
    ...(size === "small" && {
      bottom: "5%",
      right: "5%"
    }),
    ...(size === "xs" && {
      bottom: "0",
      right: "0"
    })
  },
}));

export default (props) => {
  if (props.badge) {
    return (
      <StyledBadge
        color="success"
        variant="dot"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        size={props.size}
      >
        <Avatar {...props} />
      </StyledBadge>
    );
  }

  return <Avatar {...props} />;
};
