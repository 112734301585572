import React from "react";
import { connect } from "react-redux";
import Intern from "./Intern";
import Candidate from "./Candidate";
import Logout from "./Logout";
import { ThemeSwitcher } from "components/ui";
import { Button, HeaderSidebar, Icon, Info, ProfilePhoto, BottomButtons } from "../styles";

function Sidebar(props) {
  const { toggleDrawer, user } = props;

  return (
    <HeaderSidebar>
      <Button onClick={toggleDrawer}>
        <Icon className="material-icons closer">close</Icon>
      </Button>

      <Info>
        <ProfilePhoto>
          <img src={user?.avatar} alt={`Avatar ${user?.first_name}`} />
        </ProfilePhoto>
        <h3>{user?.first_name}</h3>
        <h4>{user?.email}</h4>
      </Info>
      {user?.intern ? (
        <Intern user={user} toggleDrawer={toggleDrawer} />
      ) : (
        <Candidate user={user} toggleDrawer={toggleDrawer} />
      )}

      <BottomButtons>
        <ThemeSwitcher onChange={toggleDrawer} />
        <Logout toggleDrawer={toggleDrawer} />
      </BottomButtons>
    </HeaderSidebar>
  );
}

function mapStateToProps(state) {
  const user = state.session?.attributes;

  return { user };
}

export default React.memo(connect(mapStateToProps)(Sidebar));
