import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { USERS } from 'navigation/ROUTES';

const MainDiv = styled.div`
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 0;
  display: flex;
  background-color: #5f309f;

  @media screen and (max-width: 767px) {
    & {
      display: none;
      position: fixed;
      bottom: 0;
      z-index: 1001;
    }
  }
`;

const ProfileStatus = styled.span`
  float: left;
  margin: 15px 10px 0px 20px;
  color: white;
  font-size: 0.8rem;
`;

const Progress = styled.div`
  margin: 0 10px;
  position: relative;
  top: 20px;
  height: 10px;
  width: 350px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 15px;

  div.progress-bar {
    height: 10px;
    border-radius: 15px;
    background: white;
  }

  @media screen and (max-width: 767px) {
    & {
      width: 100%;
    }
  }
`;

const Percentage = styled.div`
  width: 38px;
  height: 38px;
  margin: 0 10px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 38px;
  position: relative;
  top: 6px;

  span {
    font-size: 11px;
    color: white;
    text-align: center;
    line-height: 38px;
    display: block;
    font-weight: 600;
    width: 38px;
    height: 38px;
  }
`;

const EditProfile = styled.span`
  color: rgba(255, 255, 255, .6);
  font-size: 0.8rem;
  margin-right: 20px;
  margin-left: auto;
  align-self: center;

  a {
    font-weight: bold;
    color: rgba(255, 255, 255, .7);
    text-decoration: none;
  }

  i {
    vertical-align: middle;
    margin-left: 5px;
  }

  @media screen and (max-width: 767px) {
    & {
      display: none;
    }
  }
`;

function ProfileCompleteness(props) {
  const { user } = props;

  return (
    <MainDiv>
      <ProfileStatus>
        <strong>Perfil</strong>
      </ProfileStatus>
      <Progress>
        <div
          className="progress-bar"
          style={{ width: user?.profile_completeness_percentage }}
          role="progressbar"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </Progress>
      <Percentage>
        <span>{user?.profile_completeness_percentage}</span>
      </Percentage>
      <EditProfile>
        {!user?.intern && "Melhore as chances de ser encontrado, "}
        <Link to={USERS.WIZARD}>edite seu perfil aqui!</Link>
        <i className="material-icons">arrow_forward</i>
      </EditProfile>
    </MainDiv>
  );
}

function mapStateToProps(state) {
  const user = state.session?.attributes;

  return { user };
}

export default React.memo(connect(mapStateToProps)(ProfileCompleteness));
