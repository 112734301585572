import React from "react";
import { Handle, Position } from 'reactflow';

import {
  BoxStyleType,
  State,
  StateName,
  StateUpdatedAt,
  StateStatus,
} from "./styles";

import { formatStatusText } from "./utils";

export const BoxType = ({data,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom}) => {
  const { label, statuses } = data;

  const renderStates = () => {
    return statuses.map((status, index) => {
      let settings = status.settings;
      let name = settings.name;
      let updated_at =
        status.updated_at &&
        `Atualizado em ${new Date(status.updated_at).toLocaleDateString()}`;
      let status_text = formatStatusText(settings, status);

      return (
        <State key={`${status.state_name} - ${name} - ${index}`}>
          <StateName>
            <b>{name}</b>
            <StateStatus className={status.status}>{status_text}</StateStatus>
          </StateName>
          <StateUpdatedAt>{updated_at}</StateUpdatedAt>
        </State>
      );
    });
  };

  return (
    <div>
      <Handle type="target" position={targetPosition} />
      <div>
        <BoxStyleType elevation={7} >
          <h4>{label}</h4>
          {renderStates()}
        </BoxStyleType>
      </div>
      <Handle type="source" position={sourcePosition} />
    </div>
  );
};

