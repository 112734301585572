import React, { useEffect } from "react";
import { UncontrolledInput as Input } from "components/form";
import VMasker from "vanilla-masker";

function MessagePhone(props) {
  const prefix = props.prefix || {
    id: "message_phone",
    name: "message_phone",
  };

  useEffect(() => {
    VMasker(document.querySelector(".phone")).maskPattern("(99) 9999-99999");
  }, []);

  return (
    <div className="form-group">
      <Input
        {...props}
        className={`phone ${props.className}`}
        placeholder="(__) ____-_____"
        type="tel"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Telefone para recado"}
      />
    </div>
  );
}

export default MessagePhone;
