import React, { Children, Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { USERS } from "navigation/ROUTES";

const Breadcrumb = styled.nav`
  margin-top: 30px;
  margin-bottom: 1rem;
  background: none;
  padding: 0;
  display: flex;

  @media screen and (max-width: 767px) {
    & {
      background-color: ${({theme}) => theme.mode === "light" ? "#f8f8f8" : theme.background.paper};
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      border-radius: 0;
      border-top: 1px solid ${({theme}) => theme.mode === "light" ? "#ececec" : "#333b4d"};
      border-bottom: 1px solid ${({theme}) => theme.mode === "light" ? "#ececec" : "#333b4d"};
    }

    & + div.filters {
      margin-top: -2px;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 2px;
    }
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;

  @media screen and (max-width: 767px) {
    & {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0 0 0 15px;
    }
  }
`;

const ListItem = styled.li`
  display: inline;

  a {
    font-family: "Open Sans";
    font-size: 12px;
    line-height: 17px;
    color: #959aad!important;
    font-weight: 600;
    text-decoration: none;
  }

  i {
    color: #959aad;
    font-size: 12px;
    line-height: 16px;
    vertical-align: middle;
    margin: 0 6px;
  }

  i.folder {
    font-size: 16px;
    color: #c13950;
    margin: 0;
  }

  &.active {
    display: block;
    margin-top: 6px;

    a {
      font-size: 24px;
      color: inherit!important;
      line-height: 16px;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 767px) {
    &.active {
      width: 100%;
      padding-right: 10px;

      a {
        display: block;
        height: 30px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
      }
    }
  }
`;

export default React.memo((props) => {
  const { Actions } = props;

  const renderLinks = () => {
    const children = Children.toArray(props.children);

    return children.map((link, index) =>
      index + 1 == children.length ? (
        <ListItem key={index} className="active">
          {link}
        </ListItem>
      ) : (
        <Fragment key={index}>
          <ListItem>
            <i className="material-icons">trending_flat</i>
          </ListItem>
          <ListItem>{link}</ListItem>
        </Fragment>
      )
    );
  };

  return (
    <Breadcrumb>
      <List>
        <ListItem>
          <i className="material-icons folder">folder_shared</i>
        </ListItem>
        <ListItem>
          <i className="material-icons">trending_flat</i>
        </ListItem>
        <ListItem>
          <Link to={USERS.HOME}>Início</Link>
        </ListItem>
        {renderLinks()}
      </List>
      {Actions && <Actions />}
    </Breadcrumb>
  );
});
