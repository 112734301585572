import React from "react";
import { AvatarGroup } from "@mui/material";

const SPACINGS = {
  small: -16,
  medium: -8,
};

export default (props) => {
  const marginValue =
    props.spacing && SPACINGS[props.spacing] !== undefined
      ? SPACINGS[props.spacing]
      : -props.spacing || -8;

  const children = props.children.map((item) => {
    return React.cloneElement(item, { size: props.size });
  });

  return (
    <AvatarGroup
      {...props}
      style={{
        ...props.style,
        "--AvatarGroup-spacing": marginValue ? `${marginValue}px` : undefined,
      }}
      children={children}
      slotProps={{ surplus: { size: props.size } }}
    />
  );
};
