import React from "react";
import Input from "components/form/NewLanding/Input";

function Password(props) {

  const prefix = ((typeof props.prefix) === 'string') ? 
    {id: props.prefix, name: props.prefix}  : 
      props.prefix || {
        id: "password",
        name: "password",
    };


  return (
    <Input
      {...props}
      placeholder={"Senha"}
      type="password"
      id={prefix.id}
      name={prefix.name}
      requiredText={"Este campo é obrigatório. Sua senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, um número e um caractere especial."}
      onChange={(e) => {
        props.onChange?.(e);
      }}
    />
  );
}

export default Password;
