import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Time } from "components/form";
import IdAndDestroyFields from "components/users/fields/shared/IdAndDestroyFields";

const StudyHourDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;

  .MuiFormControl-root {
    margin: 3px !important;
  }

  span {
    font-weight: 600;
  }

  span.disabled-true {
    color: rgba(0, 0, 0, 0.38);
  }

  input {
    width: 55px;
    text-align: center;
  }
`;

const DeleteButton = styled.button`
  padding: 0;
  margin-right: 3px;
  background: transparent;
  border: none;
  color: #333b4d;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &:hover {
    color: #c13950;
  }
`;

function StudyHour(props) {
  const { hour, index, prefix } = props;

  const [destroy, setDestroy] = useState(false);

  const removeFromList = () => {
    setDestroy(true);
  };

  useEffect(() => {
    if (destroy) props.destroyItem(hour.id, index);
  }, [destroy]);

  return (
    <StudyHourDiv>
      <span className={`disabled-${props.disabled}`}>das</span>
      <Time
        {...props}
        name={`${prefix.name}[${index}][from]`}
        id={`${prefix.id}_${index}_from`}
        defaultValue={hour.from || "08:00"}
      />
      <span className={`disabled-${props.disabled}`}>até</span>
      <Time
        {...props}
        name={`${prefix.name}[${index}][to]`}
        id={`${prefix.id}_${index}_to`}
        defaultValue={hour.to || "12:00"}
      />

      <DeleteButton type="button" onClick={removeFromList}>
        <i className="fa fa-trash"></i>
      </DeleteButton>

      <IdAndDestroyFields {...props} item={hour} destroy={destroy} />
    </StudyHourDiv>
  );
}

export default React.memo(StudyHour);
