import React, { useCallback, useEffect } from "react";
import _ from 'lodash';

import ReactFlow, {
  Background,
  MiniMap,
  MarkerType,
  useNodesState,
  useEdgesState,
  addEdge,
  Panel,
  Controls,
} from 'reactflow';

import 'reactflow/dist/style.css';

import { useEnvelope } from "../../context";

import { BoxType } from "./Template/BoxType";
import { GroupBox } from "./Template/GroupBox";

const nodeTypes = { boxType: BoxType, groupBox: GroupBox };

const labelColor = {
  walljobs: 'rgb(240, 29, 57, 1)',
  company_sign: 'rgb(	30, 33, 41, 1)',
  internship_sign: 'rgb(115, 56, 196, 1)',
  university_sign: 'rgb(255, 217, 0, 1)'
};

const edgeColor = {
  completed: '#139237',
  declined: '#bf213c',
  target: '#3aafe6',
  default: '#bbb'
}

export default () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const { envelope } = useEnvelope();

  const { current_state, current_state_events, workflow } = envelope;

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  useEffect(() => {
    let workflowNodes = [];
    let workflowEdges = [];

    //criar grupos maiores
    const createGroups = () => {
      let groups = [];

      workflow.flow.forEach(item => {
        let key = Object.keys(item)[0];
        let group = item[key].meta.group;
  
        if(!groups.find(e => e.id === group)){
          groups.push({id: item[key].meta.group, label: item[key].label_group});
        } 
      });
  
      groups.forEach((group, index) => {
        workflowNodes.push({
          id: `group-${group.id}`,
          type: 'groupBox',
          data: { label: group.label, color: labelColor[group.id] },
          position: {x: workflowNodes.length * 505, y: 1},
          style: {
            width: 500,
            height: 800,
            borderRight: (index + 1) < groups.length && '3px dashed #bbb',
          },
          className: 'light',
        });
      });
    }

    //criar boxes
    const createBoxes = () => {
      workflow.flow.forEach((item, index) => {
        let key = Object.keys(item)[0];
        let group = 'group-' + item[key].meta.group;
        let quantity = (workflowNodes.filter(e => e.parentNode === group).length + 1);
    
        if (workflowNodes.find(e => e.parentNode === group)?.data?.statuses?.length > 1){
          quantity++;
        }
        
        let previous = index > 0 && Object.keys(workflow.flow[index - 1])[0];

        let data = { 
          type: workflow.type,
          label: item[key].label,
          previous: previous,
          target: item[key].meta.target,
          statuses: item[key].statuses
        };

        if(current_state == key)
          data['events'] = current_state_events;
  
        workflowNodes.push({
          id: key,
          type: 'boxType',
          data: data,
          position: { x: 100, y: quantity * 180 },
          parentNode: group,
          extent: 'parent',
        });
      });
    }

    //create links from nodes
    const createLinks = () => {
      workflowNodes.forEach((item) => {
        if(item.data.target){
          let nodes = workflowNodes.filter(e => e.id === item.data.target);
          let targetGroup = nodes.length > 0 ? nodes[0].parentNode : null;

          let status = item.data.statuses[0]?.status;
          let targetStatus = _.get(nodes[0], 'data.statuses[0].status');

          let completedEdge = status == 'completed' && targetStatus == 'completed';
          let declinedEdge = targetStatus == 'declined';
          let currentEdgeState = current_state === item.data.target && status != "declined";

          let arrowColor;

          if(declinedEdge) { arrowColor = edgeColor['declined'] }
          else if(completedEdge) { arrowColor = edgeColor['completed'] }
          else if(currentEdgeState) { arrowColor = edgeColor['target'] }
          else { arrowColor = edgeColor['default'] }

          let parentGroup = workflowNodes.find(e => e.type == 'groupBox' && e.id == item.parentNode);
          let previous = workflowNodes.find(e => e.id === item.data.previous);
          let previousGroup = workflowNodes.find(e => e.id === previous?.parentNode);
          let nextGroup = workflowNodes.find(e => e.id == targetGroup);

          if(parentGroup?.id == previousGroup?.id){
            item.targetPosition = 'top';

            if(parentGroup?.id == nextGroup?.id){
              item.sourcePosition = 'bottom';
            } else {
              if(parentGroup?.position?.x < nextGroup?.position?.x){
                item.sourcePosition = 'right';
              } else {
                item.sourcePosition = 'left';
              }
            }
          } else {
            if (parentGroup?.position?.x > previousGroup?.position?.x){
              item.targetPosition = 'left';
            } else {
              item.targetPosition = 'right';
            }

            if (!previousGroup){
              item.targetPosition = null;
            }

            if(parentGroup?.id == nextGroup?.id){
              item.sourcePosition = 'bottom';
            } else {
              if(parentGroup?.position?.x < nextGroup?.position?.x){
                item.sourcePosition = item.targetPosition == 'right' ? 'bottom' : 'right';
              } else {
                item.sourcePosition = item.targetPosition == 'left' ? 'bottom' : 'left';
              }

              if(!nextGroup){
                item.sourcePosition = null;
              }
            }
          }
  
          workflowEdges.push({
            id: 'node-' + item.id + '-' + item.data.target, 
            source: item.id, 
            target: item.data.target, 
            type: targetGroup == item.parentNode ? 'default' : 'smoothstep',
            animated: !completedEdge && !declinedEdge && true,
            markerEnd: { 
              type: MarkerType.ArrowClosed,
              width: currentEdgeState ? 20 : completedEdge ? 17 : 0,
              height: currentEdgeState ? 20 : completedEdge ? 17 : 0,
              color: arrowColor,
            },
            style: { 
              stroke: arrowColor,
              strokeWidth: 2,
            }
          });
        }
      });
    }

    createGroups();
    createBoxes();
    createLinks();

    setNodes(workflowNodes);
    setEdges(workflowEdges);
  }, [workflow])
  
  return (
    <div style={{height: "100vh", marginBottom: "70px"}}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        nodesConnectable={false}
        nodesDraggable={false}
        elementsSelectable={false}
        panOnDrag={true}
        fitView>
        <Controls showInteractive={false} />
        <Panel />
        <Background />
        <MiniMap />
      </ReactFlow>
    </div>
  );
}


