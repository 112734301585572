import React from "react";
import {UncontrolledInput as Input} from 'components/form';

function FullName(props) {
  const prefix = props.prefix || {
    id: "full_name",
    name: "full_name",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe seu nome completo..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Nome Completo"}
      />
    </div>
  );
}

export default FullName;
