import React from "react";
import { UncontrolledInput as Input } from "components/form";

function Linkedin(props) {
  const prefix = props.prefix || {
    name: "linkedin",
    id: "linkedin",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe seu Linkedin..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "Linkedin"}
      />
    </div>
  );
}

export default Linkedin;
