import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import _ from "lodash";

function SearchableSelect(props) {
  const defaultValue = setDefaultValue();
  const baseValueByType = props.multiple ? [] : null;
  const [value, setValue] = useState(baseValueByType);

  const validation = { ...{ required: props.required }, ...props.validation };

  const messages = {
    ...{ required: "Não pode ficar em branco" },
    ...props.messages,
  };

  const error = _.get(props.errors, props.name);

  function setDefaultValue() {
    return (
      props.defaultValue?.value ||
      props.defaultValue ||
      _.get(props.getValues?.(), props.name) ||
      ""
    );
  }

  function findDefaultValues() {
    let value;
    if (props.multiple) {
      value = defaultValue.map?.((value) => {
        return props.collection.find(
          (option) => option?.value == (value?.value || value) ||
          option?.label == (value?.value || value)
        );
      });
    } else {
      value = props.collection.find(
        (option) => option?.value == (defaultValue?.value || defaultValue) ||
        option?.label == (defaultValue?.value || defaultValue)
      );
    }

    return value || defaultValue;
  }

  useEffect(() => {
    if (!_.isEmpty(props.collection)) setValue(findDefaultValues() || baseValueByType);
  }, [props.collection, props.defaultValue, props.control._fields]);

  useEffect(() => {
    props.onChange?.(value);
  }, [value]);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={validation}
      defaultValue={props.sanitizeValue?.(defaultValue) || defaultValue}
      render={({ field: { onChange } }) => (
        <Autocomplete
          autoHighlight
          filterSelectedOptions
          required={props.required}
          multiple={props.multiple}
          id={props.id}
          options={props.collection}
          groupBy={props.groupBy || ((option) => option?.group)}
          onChange={(event, value) => {
            onChange(props.sanitizeValue?.(value) || value?.value || value);
            setValue(value);
          }}
          getOptionLabel={(option) => option.label || ""}
          noOptionsText="Nenhuma opção foi encontrada"
          value={value}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus={props.autoFocus}
              variant="standard"
              required={props.useChildrenSelects || props.required}
              label={props.label}
              placeholder={props.placeholder}
              error={!_.isEmpty(error)}
              helperText={
                error?.message || messages[error?.type] || props.helperText
              }
            />
          )}
        />
      )}
    />
  );
}

export default SearchableSelect;
