import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "../styles";
import PATHS from "navigation/ROUTES";

export default ({ user, toggleDrawer }) => (
  <Menu>
    <li className="mobile">
      <Link to={PATHS.ACADEMY.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">insert_chart</i> Início
      </Link>
    </li>
    <li>
      <Link to={PATHS.USERS.HOME} onClick={toggleDrawer}>
        <i className="material-icons">assignment_ind</i> Meu Perfil
      </Link>
    </li>
    <li>
      <Link to={PATHS.ACADEMY.COURSE.OWNED} onClick={toggleDrawer}>
        <i className="material-icons">school</i> Meus Cursos
      </Link>
    </li>
    <li>
      <Link to={PATHS.ACADEMY.COURSE.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">account_balance</i> Todos os Cursos
      </Link>
    </li>
    <li>
      <Link to={PATHS.ACADEMY.STUDY_TRACK.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">leaderboard</i> Trilhas de Estudos
      </Link>
    </li>
    <li className="mobile">
      <a href="https://carreiras.walljobs.com.br/" target="_blank" onClick={toggleDrawer}>
        <i className="material-icons">edit_note</i> Blog
      </a>
    </li>
    <li>
      <Link to={PATHS.ACADEMY.COURSE.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">question_mark</i> FAQ
      </Link>
    </li>
  </Menu>
);
