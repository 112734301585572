import React from "react";
import { UncontrolledInput as Input } from "components/form";

function Email(props) {
  const prefix = props.prefix || {
    id: "email",
    name: "email",
  };

  const validation = { pattern: /^[a-zA-Z0-9._:$!%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/i };

  const messages = { pattern: "E-mail inválido" };

  return (
    <div className="form-group">
      <Input
        {...props}
        placeholder="Informe seu e-mail"
        type="email"
        name={prefix.name}
        id={prefix.id}
        label={props.label || "E-mail de contato"}
        validation={validation}
        messages={messages}
      />
    </div>
  );
}

export default Email;
