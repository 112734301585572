import React from 'react';
import { SelectInput } from "components/form";

function Duration(props){
  const { prefix, index } = props;

  const collection = [
    { value: "short", label: "até 40 horas" },
    { value: "medium", label: "acima de 40 horas, até 360 horas" },
    { value: "long", label: "acima de 360 horas" },
  ];

  return(
    <div className="form-group">
    <SelectInput
      {...props}
      id={`${prefix}_${index}_duration`}
      name={`${prefix}[${index}][duration]`}
      collection={collection}
      placeholder="Selecione a duração..."
      label="Duração"
    />
  </div>
  )
}

export default Duration;