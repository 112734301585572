import React from "react";
import swal from "sweetalert";
import api from "services/api";
import { connect } from "react-redux";

import { useParams } from "react-router-dom";
import { useEnvelope } from "pages/internship/envelopes/Show/context";

const SignCommon = (props) => {
  const { user } = props;
  const { id } = useParams();
  const { mutate } = useEnvelope();

  const updateSign = (action) => {
    const payload = { event: action, status: { signer: user.email, status: "completed" } };
    api
      .patch(`/v1/internship/envelopes/${id}`, payload)
      .then(() => {
        swal(
          "Tudo certo!",
          "Assinatura confirmada com sucesso!",
          "success"
        );
        mutate();
      })
      .catch(() => {
        swal(
          "Ops!",
          "Não foi possível confirmar a assinatura no momento... tente novamente, por favor!",
          "error"
        );
      });
  };

  const handleUpdate = () => {
    swal(
      "Atenção",
      "Deseja confirmar a assinatura nesse contrato?",
      "warning",
      {
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Confirmar",
            value: true,
            closeModal: true,
          },
        },
      }
    ).then((value) => {
      if (value) updateSign(props.action);
    });
  };

  return (
    <button className="button button-info" onClick={handleUpdate}>
      Confirmar assinatura
    </button>
  );
};



function mapStateToProps(state) {
  const user = state.session?.attributes;

  return { user };
}

function mergeProps(stateProps, dispatchProps, ownProps){
  return { ...stateProps, ...ownProps };
}

export default React.memo(connect(mapStateToProps, null, mergeProps)(SignCommon));

