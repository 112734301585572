import React from "react";
import { SelectInput } from "components/form";
import { useStaticCollection } from "utils/hooks";

function Sex(props){
  const prefix = props.prefix || {
    id: "sex",
    name: "sex",
  };
  
  const collection = useStaticCollection("/selects/sexes.json")

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        placeholder="Selecione seu sexo biológico..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Sexo biológico"}
      />
    </div>
  );
}

export default Sex;