import React from "react";
import {UncontrolledInput as Input} from 'components/form';

function Summary(props) {
  const prefix = props.prefix || {
    name: "summary",
    id: "summary",
  };

  const validation = { minLength: 100 };

  const messages = {
    minLength: "Precisa ter ao menos 100 caracteres",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        name={prefix.name}
        id={prefix.id}
        placeholder="Nos conte mais sobre você!"
        type="text"
        label={props.label || "Carta de apresentação"}
        rows="5"
        multiline
        validation={validation}
        messages={messages}
      />
    </div>
  );
}

export default Summary;
