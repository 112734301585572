import styled from "styled-components";

export const FooterAcademy = styled.footer` 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #FAFAFA;
  padding-top: 20px;
  text-align: center;

  .social_medias a{
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin: 0 10px 10px 0;
    border-radius: 50%;
    border: 1px solid #87839D;
    text-decoration: none;
    font-size: 18px;
  }

  .terms_of_use {
    p {
      text-decoration: none;
      text-align: center;
      white-space: nowrap;
      color: #898d94;
      width: 450px;
      color: #898D94;
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
  }

  a {
    text-decoration: none;
    color: #898D94;
  }

  @media screen and (max-width: 767px) {
    & {
      .terms_of_use {
        p {
          font-size: 14px;
        }

        a {
          font-size: 14px;
        }
      }
    }
  }
`;