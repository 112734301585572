import React from "react";
import colors from "styles/colors.js";
import {
  buttonClasses,
  buttonGroupClasses,
  iconButtonClasses,
  fabClasses,
  svgIconClasses,
  touchRippleClasses,
  tabsClasses,
  toggleButtonClasses,
  radioClasses,
  badgeClasses,
  sliderClasses,
  linearProgressClasses,
  circularProgressClasses,
  switchClasses,
  formControlLabelClasses,
  listItemButtonClasses,
  listItemTextClasses,
  avatarClasses,
  linkClasses,
  breadcrumbsClasses,
  buttonBaseClasses,
  alertClasses,
  alertTitleClasses,
  ratingClasses,
  chipClasses,
  Typography,
  tabClasses,
  accordionClasses,
  paginationItemClasses,
  menuItemClasses,
  stepIconClasses,
  stepLabelClasses,
  stepConnectorClasses,
  stepperClasses,
  mobileStepperClasses,
  inputLabelClasses,
  outlinedInputClasses,
  inputBaseClasses,
  filledInputClasses,
  formHelperTextClasses,
  formLabelClasses,
  listItemClasses,
  checkboxClasses,
  tabScrollButtonClasses,
  typographyClasses,
  tableRowClasses,
  tablePaginationClasses,
  autocompleteClasses,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { timelineDotClasses } from "@mui/lab";

const LinkBehavior = React.forwardRef((props, ref) => {
  return <RouterLink ref={ref} to={props.href || props.to} {...props} />;
});

const TabLabel = React.forwardRef((props, ref) => {
  return <Typography variant="subtitle2" ref={ref} {...props} />;
});

const CustomFormLabel = React.forwardRef((props, ref) => {
  return <Typography variant="subtitle2" ref={ref} {...props} />;
});

export const components = {
  // MuiTextField: {
  //   styleOverrides: {
  //     root: ({ ownerState }) => ({
  //       ...(ownerState.filter
  //         ? {
  //             margin: "3px 10px 3px 0px",
  //             "& label.Mui-focused": {
  //               color: "#639DA2",
  //             },
  //             "& .MuiInput-underline:after": {
  //               borderBottomColor: "#639DA2",
  //             },
  //           }
  //         : {
  //             width: "100%",
  //             margin: "3px 10px 3px 0px",
  //             "& label.Mui-focused": {
  //               fontWeight: "bold",
  //             },
  //           }),
  //     }),
  //   },
  // },
  MuiTextField: {
    defaultProps: {
      color: "secondary"
    },
    styleOverrides: {
      root: {
        width: "100%",
        '--TextField-brandBorderColor': colors.brightGray[600],
        '--TextField-brandBorderHoverColor': colors.brightGray[800],
        [`& .${inputBaseClasses.input}`]: {
          fontSize: "16px",
        },
        [`& label`]: {
          fontSize: "16px",
          fontWeight: "bold",
          color: colors.brightGray[700],
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "157%",
          letterSpacing: "0.1px",
        },
        [`& label.${inputLabelClasses.focused}`]: {
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "166%",
          letterSpacing: "0.4px",
        },
        [`& label.${inputLabelClasses.shrink}`]: {
          fontWeight: 400,

          [`&:not(.${inputLabelClasses.focused}, .${inputLabelClasses.error})`]: {
            color: colors.brightGray[600],
          }
        },
        [`& label.${inputLabelClasses.disabled}`]: {
          color: colors.white[950]
        },
        [`& label.${inputLabelClasses.error}:not(.${inputLabelClasses.shrink})`]: {
          color: colors.brightGray[700]
        },
        [`& .${formHelperTextClasses.root}`]: {
          color: colors.brightGray[500],

          [`&.${formHelperTextClasses.disabled}`]: {
            color: colors.white[950]
          }
        }
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: 'var(--TextField-brandBorderColor)',
        ["legend"]: {
          fontSize: "0.67em"
        }
      },
      root: {
        [`&.${outlinedInputClasses.disabled}`]:{
          color: colors.white[950],

          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: colors.white[950]
          }
        }
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: colors.brightGray[100],
        [`&:hover`]: {
          backgroundColor: colors.brightGray[200]
        },
        [`&.${filledInputClasses.disabled}`]: {
          backgroundColor: colors.white[100]
        },
        ['&::before']: {
          borderColor: 'var(--TextField-brandBorderColor)',
        },
        ['&:hover:not(.Mui-disabled):before']: {
          borderBottomWidth: "2px",
          borderColor: 'var(--TextField-brandBorderHoverColor)',
        },
        [`&.${filledInputClasses.error}::before, &.${filledInputClasses.error}::after`]: {
          borderBottomWidth: '2px',
        }
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        ['&::before']: {
          borderBottomColor: 'var(--TextField-brandBorderColor)',
        }, 
        ['&:hover:not(.Mui-disabled, .Mui-error):before']: {
          borderBottomColor: 'var(--TextField-brandBorderHoverColor)',
        },
        [`&.${inputBaseClasses.error}::before`]: {
          borderBottomWidth: '2px',
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      nativeInput: {
        border: 0
      }
    }
  },
  MuiStepper: {
    styleOverrides: {
      root: {
        [`&.${stepperClasses.alternativeLabel}.${stepperClasses.vertical}`]: {
          alignItems: "center"
        }
      }
    }
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: colors.white[950],
        [`&.${stepIconClasses.active}`]: {
          color: colors.punch[500]
        },
        [`&.${stepIconClasses.completed}`]: {
          color: colors.apple[400]
        }
      }
    }
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        [`&.${stepConnectorClasses.horizontal}`]: {
          left: 'calc(-50% + 40px)',
          right: 'calc(50% + 40px)',
        },
        [`&.${stepConnectorClasses.vertical}.${stepConnectorClasses.alternativeLabel}`]: {
          position: "relative",
          marginLeft: 0,
          top: 0,
          left: "50%",
          right: "50%"
        }
      },
      line: {
        borderColor: colors.brightGray[600]
      }
    }
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        [`&.${stepLabelClasses.horizontal}.${stepLabelClasses.alternativeLabel}`]: {
          marginTop: "-20px",
        },
      },
      
      labelContainer: {
        color: "unset",

        [`& .${stepLabelClasses.label}`]: {
          fontFamily: "Source Sans Pro",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "143%",
          letterSpacing: "0.17px",
          color: colors.brightGray[400],

          [`&.${stepLabelClasses.active}, &.${stepLabelClasses.completed}`]:{
            color: colors.brightGray[600],
            fontWeight: 600,
            lineHeight: "157%",
            letterSpacing: "0.1px",
          },
        }
      }
    }
  },
  MuiMobileStepper: {
    styleOverrides: {
      root: {
        fontFamily: "Source Sans Pro",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "157%",
        letterSpacing: "0.1px",
      },
      dot: {
        [`&:not(.${mobileStepperClasses.dotActive})`]: {
          backgroundColor: colors.brightGray[400]
        }
      },
      progress: {
        backgroundColor: colors.punch[300]
      }
    }
  },
  // MuiStepLabel: {
  //   styleOverrides: {
  //     iconContainer: {
  //       cursor: "pointer",
  //     },
  //     labelContainer: {
  //       cursor: "pointer",
  //       "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
  //         marginTop: "0px",
  //       },
  //       ["@media screen and (max-width: 767px)"]: {
  //         "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
  //           display: "none",
  //         },
  //       },
  //     },
  //   },
  // },
  // MuiStepIcon: {
  //   styleOverrides: {
  //     root: {
  //       color: "rgba(0,0,0,.22)",
  //       "&.Mui-active": {
  //         color: "#c13950",
  //       },
  //       "&.Mui-completed": {
  //         color: "#51F27E",
  //       },
  //     },
  //   },
  // },
  // MuiStepConnector: {
  //   styleOverrides: {
  //     root: ({ theme }) => ({
  //       "&.Mui-active, &.Mui-completed": {
  //         ".MuiStepConnector-line": {
  //           borderColor: "#51F27E",
  //         },
  //       },
  //     }),
  //     line: {
  //       borderTopWidth: "2px",
  //       borderColor: "rgba(0,0,0,.22)",
  //     },
  //   },
  // },
  MuiChip: {
    styleOverrides: {
      root: {
        [`& .${chipClasses.avatar}`]: {
          color: colors.brightGray[50],
        },
        [`&.${chipClasses.colorDefault}`]: {
          [`& .${chipClasses.avatar}`]: {
            backgroundColor: colors.brightGray[500],
          },
          [`& .${chipClasses.deleteIcon}`]: {
            color: colors.brightGray[800],
            opacity: 0.26,

            ["&:hover"]: {
              color: colors.brightGray[800],
              opacity: 0.8,
            },
          },
        },
        [`&.${chipClasses.colorPrimary}`]: {
          [`& .${chipClasses.avatar}`]: {
            backgroundColor: colors.punch[600],
          },
        },
        [`&.${chipClasses.colorSecondary}`]: {
          [`& .${chipClasses.avatar}`]: {
            backgroundColor: colors.dodgeBlue[600],
          },
        },
        [`&.${chipClasses.colorError}`]: {
          [`& .${chipClasses.avatar}`]: {
            backgroundColor: colors.redRibbon[800],
          },
        },
        [`&.${chipClasses.colorWarning}`]: {
          [`& .${chipClasses.avatar}`]: {
            backgroundColor: colors.amber[700],
          },
        },
        [`&.${chipClasses.colorInfo}`]: {
          [`& .${chipClasses.avatar}`]: {
            backgroundColor: colors.purpleHeart[800],
          },
        },
        [`&.${chipClasses.colorSuccess}`]: {
          [`& .${chipClasses.avatar}`]: {
            backgroundColor: colors.apple[700],
          },
        },
      },
      label: {
        marginBottom: "0",
      },
      filled: {
        color: colors.brightGray[50],
        [`& .${chipClasses.deleteIcon}`]: {
          color: colors.brightGray[50],
          opacity: 0.7,

          ["&:hover"]: {
            color: colors.brightGray[50],
            opacity: 1,
          },
        },

        [`&.${chipClasses.colorDefault}`]: {
          color: colors.brightGray[700],
          backgroundColor: colors.white[200],
          border: `1px solid ${colors.white[950]}`,

          ["&:hover"]: {
            backgroundColor: colors.white[400],
          },
        },
        [`&.${chipClasses.colorPrimary}`]: {
          ["&:hover"]: {
            backgroundColor: colors.punch[600],
          },
        },
        [`&.${chipClasses.colorSecondary}`]: {
          ["&:hover"]: {
            backgroundColor: colors.dodgeBlue[600],
          },
        },
        [`&.${chipClasses.colorError}`]: {
          ["&:hover"]: {
            backgroundColor: colors.redRibbon[800],
          },
        },
        [`&.${chipClasses.colorWarning}`]: {
          ["&:hover"]: {
            backgroundColor: colors.amber[700],
          },
        },
        [`&.${chipClasses.colorInfo}`]: {
          ["&:hover"]: {
            backgroundColor: colors.purpleHeart[800],
          },
        },
        [`&.${chipClasses.colorSuccess}`]: {
          ["&:hover"]: {
            backgroundColor: colors.apple[700],
          },
        },
      },
      outlined: {
        [`&.${chipClasses.colorDefault}`]: {
          borderColor: colors.white[950],
          ["&:hover"]: {
            backgroundColor: colors.brightGray[50],
            borderColor: colors.brightGray[300],
          },
        },
        [`&.${chipClasses.colorPrimary}`]: {
          borderColor: colors.punch[500],
          ["&:hover"]: {
            backgroundColor: colors.punch[50],
          },
        },
        [`&.${chipClasses.colorSecondary}`]: {
          borderColor: colors.dodgeBlue[500],
          ["&:hover"]: {
            backgroundColor: colors.dodgeBlue[50],
          },
        },
        [`&.${chipClasses.colorError}`]: {
          borderColor: colors.redRibbon[800],
          ["&:hover"]: {
            backgroundColor: colors.redRibbon[100],
          },
        },
        [`&.${chipClasses.colorWarning}`]: {
          borderColor: colors.amber[600],
          ["&:hover"]: {
            backgroundColor: colors.amber[50],
          },
        },
        [`&.${chipClasses.colorInfo}`]: {
          borderColor: colors.purpleHeart[600],
          ["&:hover"]: {
            backgroundColor: colors.purpleHeart[50],
          },
        },
        [`&.${chipClasses.colorSuccess}`]: {
          borderColor: colors.apple[600],
          ["&:hover"]: {
            backgroundColor: colors.apple[50],
          },
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...(ownerState.label &&
          theme.palette.mode === "light" && {
            backgroundColor: "#e8e8e8",
          }),
      }),
    },
  },
  // MuiFormLabel: {
  //   styleOverrides: {
  //     root: {
  //       ["&.Mui-focused"]: {
  //         fontWeight: "bold",
  //       },
  //     },
  //   },
  // },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: "16px"
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        ["::-webkit-scrollbar"]: {
          width: "4px",
        },

        /* Handle */
        ["::-webkit-scrollbar-thumb"]: {
          background: "rgba(0, 0, 0, .25)",
          ...theme.applyStyles("dark", {
            background: "rgba(255, 255, 255, .1)",
          }),
        },

        /* Handle on hover */
        ["::-webkit-scrollbar-thumb:hover"]: {
          background: "rgba(0, 0, 0, .35)",
          ...theme.applyStyles("dark", {
            background: "rgba(255, 255, 255, .3)",
          }),
        },
      }),
    },
  },
  // MuiTimelineItem: {
  //   styleOverrides: {
  //     root: {
  //       ["&.MuiTimelineItem-missingOppositeContent"]: {
  //         ["&:before"]: {
  //           display: "none",
  //         },
  //       },
  //     },
  //   },
  // },
  // MuiTimelineDot: {
  //   styleOverrides: {
  //     root: ({ theme }) => ({
  //       padding: "6px",
  //       marginTop: "13px",
  //       marginBottom: "13px",
  //       backgroundColor: theme.palette.primary.main,
  //       ...theme.applyStyles("dark", {
  //         backgroundColor: "#D8D8D8",
  //       }),
  //       boxShadow: "none",
  //     }),
  //   },
  // },
  // MuiTimelineConnector: {
  //   styleOverrides: {
  //     root: {
  //       width: "5px",
  //       backgroundColor: "#D8D8D8",
  //     },
  //   },
  // },
  MuiTimelineItem: {
    styleOverrides: {
      root: {
        color: colors.brightGray[700],
      }
    }
  },
  MuiTimelineDot: {
    styleOverrides: {
      filled: {
        [`&.${timelineDotClasses.filledGrey}`]: {
          backgroundColor: colors.brightGray[400]
        }
      },
      outlined: {
        [`&.${timelineDotClasses.outlinedGrey}`]: {
          borderColor: colors.brightGray[400]
        }
      }
    },
  },
  MuiTimelineContent: {
    defaultProps: {
      variant: "subtitle1"
    },
    styleOverrides: {
      root: {
        padding: "2px 16px"
      }
    }
  },
  MuiTimelineConnector: {
    styleOverrides: {
      root: {
        backgroundColor: colors.brightGray[400]
      }
    }
  },
  MuiTimelineOppositeContent: {
    defaultProps: {
      variant: "body2"
    },
    styleOverrides: {
      root: {
        color: colors.brightGray[600]
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "4px",
        textTransform: "none",
        fontFamily: "Source Sans Pro",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "25.12px",
        letterSpacing: "0.1px",
        minWidth: "fit-content",
        boxSizing: "content-box",
        height: "fit-content",

        [`&.${buttonGroupClasses.grouped}`]: {
          height: "inherit",
        },
        [`&.${buttonClasses.sizeSmall}`]: {
          padding: "4px 10px",
        },
        [`&.${buttonClasses.sizeMedium}`]: {
          padding: "6px 16px",
        },
        [`&.${buttonClasses.sizeLarge}`]: {
          padding: "8px 22px",
        },
        [`&.${buttonClasses.contained}`]: {
          color: colors.brightGray[50],
          [`&.${buttonClasses.colorPrimary}:hover`]: {
            backgroundColor: colors.punch[600],
          },
          [`&.${buttonClasses.colorSecondary}:hover`]: {
            backgroundColor: colors.dodgeBlue[600],
          },
          [`&.${buttonClasses.colorError}:hover`]: {
            backgroundColor: colors.redRibbon[800],
          },
          [`&.${buttonClasses.colorWarning}:hover`]: {
            backgroundColor: colors.amber[700],
          },
          [`&.${buttonClasses.colorInfo}:hover`]: {
            backgroundColor: colors.purpleHeart[800],
          },
          [`&.${buttonClasses.colorSuccess}:hover`]: {
            backgroundColor: colors.apple[700],
          },
          [`&.${buttonClasses.disabled}`]: {
            backgroundColor: colors.white[950],
            color: colors.brightGray[600],
          },
        },

        [`&.${buttonClasses.outlined}`]: {
          boxSizing: "border-box",
          [`&.${buttonClasses.colorPrimary}`]: {
            borderColor: colors.punch[500],
            ["&:hover"]: {
              backgroundColor: colors.punch[50],
              borderColor: colors.punch[600],
              color: colors.punch[600],
            },
          },
          [`&.${buttonClasses.colorSecondary}`]: {
            borderColor: colors.dodgeBlue[500],
            ["&:hover"]: {
              backgroundColor: colors.dodgeBlue[50],
              borderColor: colors.dodgeBlue[600],
              color: colors.dodgeBlue[600],
            },
          },
          [`&.${buttonClasses.colorError}`]: {
            borderColor: colors.redRibbon[700],
            ["&:hover"]: {
              backgroundColor: colors.redRibbon[200],
              borderColor: colors.redRibbon[800],
              color: colors.redRibbon[800],
            },
          },
          [`&.${buttonClasses.colorWarning}`]: {
            borderColor: colors.amber[600],
            ["&:hover"]: {
              backgroundColor: colors.amber[50],
              borderColor: colors.amber[700],
              color: colors.amber[700],
            },
          },
          [`&.${buttonClasses.colorInfo}`]: {
            borderColor: colors.purpleHeart[600],
            ["&:hover"]: {
              backgroundColor: colors.purpleHeart[50],
              borderColor: colors.purpleHeart[700],
              color: colors.purpleHeart[700],
            },
          },
          [`&.${buttonClasses.colorSuccess}`]: {
            borderColor: colors.apple[600],
            ["&:hover"]: {
              backgroundColor: colors.apple[50],
              borderColor: colors.apple[700],
              color: colors.apple[700],
            },
          },
          [`&.${buttonClasses.disabled}`]: {
            borderColor: colors.white[950],
            color: colors.brightGray[400],
          },
        },

        [`&.${buttonClasses.text}`]: {
          [`&.${buttonClasses.colorPrimary}:hover`]: {
            backgroundColor: colors.punch[50],
            color: colors.punch[600],
          },
          [`&.${buttonClasses.colorSecondary}:hover`]: {
            backgroundColor: colors.dodgeBlue[50],
            color: colors.dodgeBlue[600],
          },
          [`&.${buttonClasses.colorError}:hover`]: {
            backgroundColor: colors.redRibbon[50],
            color: colors.redRibbon[800],
          },
          [`&.${buttonClasses.colorWarning}:hover`]: {
            backgroundColor: colors.amber[50],
            color: colors.amber[700],
          },
          [`&.${buttonClasses.colorInfo}:hover`]: {
            backgroundColor: colors.purpleHeart[50],
            color: colors.purpleHeart[700],
          },
          [`&.${buttonClasses.colorSuccess}:hover`]: {
            backgroundColor: colors.apple[50],
            color: colors.apple[700],
          },
          [`&.${buttonClasses.disabled}`]: {
            color: colors.brightGray[400],
          },
        },
      },
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      disableElevation: true
    },
    styleOverrides: {
      root: {
        [`& .${buttonGroupClasses.grouped}`]: {
          [`&.${buttonClasses.contained}`]: {
            [`&.${buttonClasses.colorPrimary}`]: {
              borderColor: colors.punch[700],
            },
            [`&.${buttonClasses.colorSecondary}`]: {
              borderColor: colors.dodgeBlue[800],
            },
            [`&.${buttonClasses.colorError}`]: {
              borderColor: colors.redRibbon[800],
            },
            [`&.${buttonClasses.colorWarning}`]: {
              borderColor: colors.amber[700],
            },
            [`&.${buttonClasses.colorInfo}`]: {
              borderColor: colors.purpleHeart[800],
            },
            [`&.${buttonClasses.colorSuccess}`]: {
              borderColor: colors.apple[700],
            },
          },
          [`&.${buttonClasses.text}`]: {
            [`&.${buttonClasses.colorPrimary}`]: {
              borderColor: colors.punch[500],
            },
            [`&.${buttonClasses.colorSecondary}`]: {
              borderColor: colors.dodgeBlue[500],
            },
            [`&.${buttonClasses.colorError}`]: {
              borderColor: colors.redRibbon[700],
            },
            [`&.${buttonClasses.colorWarning}`]: {
              borderColor: colors.amber[600],
            },
            [`&.${buttonClasses.colorInfo}`]: {
              borderColor: colors.purpleHeart[600],
            },
            [`&.${buttonClasses.colorSuccess}`]: {
              borderColor: colors.apple[600],
            },
          },
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        boxSizing: "content-box",
        height: "fit-content",
        color: colors.brightGray[700],

        [`&:not(.${iconButtonClasses.colorInherit}):hover`]: {
          backgroundColor: colors.brightGray[200],
          color: colors.brightGray[900],
        },
        [`& .material-symbols-rounded`]: {
          marginBottom: 0,
        },
        [`&.${iconButtonClasses.sizeSmall}`]: {
          padding: "5px",
        },
        [`&.${iconButtonClasses.sizeMedium}`]: {
          padding: "8px",
        },
        [`&.${iconButtonClasses.sizeLarge}`]: {
          padding: "12px",
        },
        [`&.${iconButtonClasses.colorPrimary}`]: {
          color: colors.punch[500],
          ["&:hover"]: {
            backgroundColor: colors.punch[50],
            color: colors.punch[600],
          },
        },
        [`&.${iconButtonClasses.colorSecondary}`]: {
          color: colors.dodgeBlue[500],
          ["&:hover"]: {
            backgroundColor: colors.dodgeBlue[50],
            color: colors.dodgeBlue[600],
          },
        },
        [`&.${iconButtonClasses.colorError}`]: {
          color: colors.redRibbon[700],
          ["&:hover"]: {
            backgroundColor: colors.redRibbon[50],
            color: colors.redRibbon[800],
          },
        },
        [`&.${iconButtonClasses.colorWarning}`]: {
          color: colors.amber[600],
          ["&:hover"]: {
            backgroundColor: colors.amber[50],
            color: colors.amber[700],
          },
        },
        [`&.${iconButtonClasses.colorInfo}`]: {
          color: colors.purpleHeart[600],
          ["&:hover"]: {
            backgroundColor: colors.purpleHeart[50],
            color: colors.purpleHeart[800],
          },
        },
        [`&.${iconButtonClasses.colorSuccess}`]: {
          color: colors.apple[600],
          ["&:hover"]: {
            backgroundColor: colors.apple[50],
            color: colors.apple[700],
          },
        },
        [`&.${iconButtonClasses.disabled}`]: {
          color: colors.white[900],
        },
        [`&.${iconButtonClasses.colorInherit}`]: {
          color: "inherit",
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: colors.brightGray[600],
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: colors.brightGray[600],
        padding: 0,
        height: "fit-content",

        [`& .${svgIconClasses.root}`]: {
          margin: "9px",
        },

        [`& .${svgIconClasses.fontSizeSmall}`]: {
          fontSize: "20px",
        },
        [`& .${svgIconClasses.fontSizeMedium}`]: {
          fontSize: "24px",
        },
        [`& .${svgIconClasses.fontSizeLarge}`]: {
          fontSize: "28px",
        },
        [`& .${touchRippleClasses.root}`]: {
          marginBottom: 0,
        },
        ["&.MuiCheckbox-colorDefault"]: {
          [`&.${checkboxClasses.checked}`]: {
            ["&:hover"]: {
              color: colors.brightGray[900],
            },
          },
          [`&.${checkboxClasses.indeterminate}`]: {
            ["&:hover"]: {
              color: colors.brightGray[900],
            },
          },
          ["&:hover"]: {
            backgroundColor: colors.brightGray[50],
          },
        },
        ["&.MuiCheckbox-colorPrimary"]: {
          [`&.${checkboxClasses.checked}`]: {
            ["&:hover"]: {
              color: colors.punch[600],
            },
          },
          [`&.${checkboxClasses.indeterminate}`]: {
            ["&:hover"]: {
              color: colors.punch[600],
            },
          },
          ["&:hover"]: {
            backgroundColor: colors.punch[50],
          },
        },
        ["&.MuiCheckbox-colorSecondary"]: {
          [`&.${checkboxClasses.checked}`]: {
            ["&:hover"]: {
              color: colors.dodgeBlue[600],
            },
          },
          [`&.${checkboxClasses.indeterminate}`]: {
            ["&:hover"]: {
              color: colors.dodgeBlue[600],
            },
          },
          ["&:hover"]: {
            backgroundColor: colors.dodgeBlue[50],
          },
        },
        ["&.MuiCheckbox-colorError"]: {
          [`&.${checkboxClasses.checked}`]: {
            ["&:hover"]: {
              color: colors.redRibbon[800],
            },
          },
          [`&.${checkboxClasses.indeterminate}`]: {
            ["&:hover"]: {
              color: colors.redRibbon[800],
            },
          },
          ["&:hover"]: {
            backgroundColor: colors.redRibbon[50],
          },
        },
        ["&.MuiCheckbox-colorWarning"]: {
          [`&.${checkboxClasses.checked}`]: {
            ["&:hover"]: {
              color: colors.amber[700],
            },
          },
          [`&.${checkboxClasses.indeterminate}`]: {
            ["&:hover"]: {
              color: colors.amber[700],
            },
          },
          ["&:hover"]: {
            backgroundColor: colors.amber[50],
          },
        },
        ["&.MuiCheckbox-colorInfo"]: {
          [`&.${checkboxClasses.checked}`]: {
            ["&:hover"]: {
              color: colors.purpleHeart[800],
            },
          },
          [`&.${checkboxClasses.indeterminate}`]: {
            ["&:hover"]: {
              color: colors.purpleHeart[800],
            },
          },
          ["&:hover"]: {
            backgroundColor: colors.purpleHeart[50],
          },
        },
        ["&.MuiCheckbox-colorSuccess"]: {
          [`&.${checkboxClasses.checked}`]: {
            ["&:hover"]: {
              color: colors.apple[700],
            },
          },
          [`&.${checkboxClasses.indeterminate}`]: {
            ["&:hover"]: {
              color: colors.apple[700],
            },
          },
          ["&:hover"]: {
            backgroundColor: colors.apple[50],
          },
        },
        [`&.${checkboxClasses.root}.${checkboxClasses.disabled}`]: {
          color: colors.white[950],
          [`&.${checkboxClasses.checked}`]: {
            color: colors.white[950],
          },
          [`&.${checkboxClasses.indeterminate}`]: {
            color: colors.white[950],
          },
        },
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        color: colors.brightGray[100],
        ["&:hover"]: {
          color: colors.brightGray[50],
        },
        [`&.MuiFab-default`]: {
          backgroundColor: colors.brightGray[700],
          ["&:hover"]: {
            backgroundColor: colors.brightGray[900],
          },
        },
        ["& .material-symbols-rounded"]: {
          marginBottom: 0,
        },
        [`&.${fabClasses.extended}`]: {
          letterSpacing: "0.46px",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "26px",
          gap: "8px",
        },
        [`&.${fabClasses.extended}.${fabClasses.sizeLarge}`]: {
          padding: "12px 16px",
        },
        [`&.${fabClasses.extended}.${fabClasses.sizeMedium}`]: {
          padding: "8px 16px",
        },
        [`&.${fabClasses.extended}.${fabClasses.sizeSmall}`]: {
          padding: "5px 8px",
        },
        [`&.${fabClasses.primary}`]: {
          color: colors.brightGray[50],
          backgroundColor: colors.punch[600],
          ["&:hover"]: {
            backgroundColor: colors.punch[800],
          },
        },
        [`&.${fabClasses.secondary}`]: {
          color: colors.brightGray[50],
          ["&:hover"]: {
            backgroundColor: colors.dodgeBlue[700],
          },
        },
        [`&.${fabClasses.disabled}`]: {
          color: colors.brightGray[600],
          backgroundColor: colors.white[950],
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        color: colors.brightGray[700],
        [`&.${tabsClasses.indicator}`]: {
          marginBottom: 0,
        },
        [`& .${tabScrollButtonClasses.root}`]: {
          opacity: 1,
        },
      },
    },
  },
  MuiTab: {
    defaultProps: {
      component: TabLabel,
    },
    styleOverrides: {
      root: {
        textTransform: "none",
        fontFamily: "Source Sans Pro",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "157%",
        letterSpacing: "0.1px",

        [`&:not(.${tabClasses.selected})`]: {
          color: colors.brightGray[700],
        },

        [`&.${tabClasses.disabled}`]: {
          color: colors.white[950],
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        color: colors.brightGray[700],
        borderColor: colors.white[950],

        ["&:hover"]: {
          backgroundColor: colors.brightGray[50],
        },
        [`&.${toggleButtonClasses.selected}`]: {
          backgroundColor: colors.brightGray[200],
        },
        [`&.${toggleButtonClasses.disabled}`]: {
          color: colors.white[950],
          borderColor: colors.white[950],
        },
        [`& .material-symbols-rounded`]: {
          marginBottom: 0,
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: colors.brightGray[600],
        boxSizing: "content-box",
        height: "fit-content",

        variants: [
          {
            props: { color: "primary" },
            style: {
              [`&.${radioClasses.checked}:hover`]: {
                color: colors.punch[600],
              },
              ["&:hover"]: {
                backgroundColor: `${colors.punch[100]}80`,
              },
            }
          }, {
            props: { color: "secondary" },
            style: {
              [`&.${radioClasses.checked}:hover`]: {
                color: colors.dodgeBlue[600],
              },
              ["&:hover"]: {
                backgroundColor: `${colors.dodgeBlue[200]}80`,
              },
            }
          }, {
            props: { color: "default" },
            style: {
              [`&.${radioClasses.checked}:hover`]: {
                color: colors.brightGray[900],
              },
              ["&:hover"]: {
                backgroundColor: `${colors.brightGray[200]}80`,
              },
            }
          }, {
            props: { color: "error" },
            style: {
              [`&.${radioClasses.checked}:hover`]: {
                color: colors.redRibbon[800],
              },
              ["&:hover"]: {
                backgroundColor: `${colors.redRibbon[200]}80`,
              },
            }
          }, {
            props: { color: "warning" },
            style: {
              [`&.${radioClasses.checked}:hover`]: {
                color: colors.amber[700],
              },
              ["&:hover"]: {
                backgroundColor: `${colors.amber[100]}80`,
              },
            }
          }, {
            props: { color: "info" },
            style: {
              [`&.${radioClasses.checked}:hover`]: {
                color: colors.purpleHeart[800],
              },
              ["&:hover"]: {
                backgroundColor: `${colors.purpleHeart[200]}80`,
              },
            }
          }, {
            props: { color: "success" },
            style: {
              [`&.${radioClasses.checked}:hover`]: {
                color: colors.apple[700],
              },
              ["&:hover"]: {
                backgroundColor: `${colors.apple[200]}80`,
              },
            }
          }, {
            props: { size: "small" },
            style: {
              [`& .${svgIconClasses.fontSizeSmall}`]: {
                fontSize: "20px",
              },
            }
          }, {
            props: { size: "medium" },
            style: {
              [`& .${svgIconClasses.fontSizeMedium}`]: {
                fontSize: "24px",
              }
            }
          }, {
            props: { size: "large" },
            style: {
              [`& .${svgIconClasses.fontSizeLarge}`]: {
                fontSize: "28px",
              }
            }
          },
        ],





        [`&.${radioClasses.disabled}`]: {
          color: colors.white[950],
        },
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: { color: "default" },
            style: {
              [`.${badgeClasses.standard}`]: {
                backgroundColor: colors.brightGray[700],
              },
            },
          },
        ],
        [`.${badgeClasses.standard}`]: {
          color: colors.brightGray[50],
          fontFamily: "Roboto",
        },
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: {
        [`.${sliderClasses.thumbColorPrimary}:hover`]: {
          boxShadow: `0px 0px 0px 12px ${colors.punch[200]}80`,
        },
        [`.${sliderClasses.thumbColorSecondary}:hover`]: {
          boxShadow: `0px 0px 0px 12px ${colors.dodgeBlue[200]}80`,
        },
        [`.${sliderClasses.valueLabel}`]: {
          color: colors.brightGray[600],
          backgroundColor: colors.white[100],
          fontWeight: "600",
        },
        [`.${sliderClasses.markLabel}`]: {
          color: colors.brightGray[600],
          fontWeight: "600",
        },
        [`.${sliderClasses.markActive}`]: {
          backgroundColor: "inherit",
        },
        [`&:not(.${sliderClasses.vertical}) .${sliderClasses.markLabel}`]: {
          top: "36px",
        },
        [`.${sliderClasses.vertical} .${sliderClasses.markLabel}`]: {
          left: "40px",
        },
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: { color: "inherit" },
            style: {
              [".MuiLinearProgress-barColorInherit"]: {
                backgroundColor: colors.brightGray[600],
              },
              [".MuiLinearProgress-dashedColorInherit"]: {
                backgroundImage: `radial-gradient(${colors.brightGray[300]} 0%, ${colors.brightGray[300]} 16%, transparent 42%)`,
              },
              [`.${linearProgressClasses.bar2Buffer}`]: {
                opacity: 1,
                backgroundColor: colors.brightGray[300],
              },
            },
          },
        ],
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: { color: "inherit" },
            style: {
              [`.${circularProgressClasses.circle}`]: {
                stroke: colors.brightGray[700],
              },
            },
          },
        ],
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        color: colors.brightGray[950],
        backgroundColor: colors.brightGray[200],
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
      },
      arrow: {
        color: colors.brightGray[200],
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        [`.${switchClasses.switchBase}:not(.${switchClasses.checked}) .${switchClasses.thumb}`]:{
          backgroundColor: colors.white[100],
        },
        [`.${switchClasses.switchBase}:hover`]: {
          backgroundColor: `${colors.white[200]}80`,
        },
        [`.${switchClasses.checked}`]: {
          [`.${switchClasses.colorPrimary}:hover`]: {
            backgroundColor: `${colors.punch[100]}80`,
          },
          [`.${switchClasses.colorSecondary}:hover`]: {
            backgroundColor: `${colors.dodgeBlue[100]}80`,
          },
          [`.MuiSwitch-colorDefault`]: {
            [`.${switchClasses.thumb}`]: {
              backgroundColor: colors.brightGray[600],
            },
            [`&+.${switchClasses.track}`]: {
              backgroundColor: colors.brightGray[600],
            },
            [`&:hover`]: {
              backgroundColor: `${colors.brightGray[50]}80`,
            },
          },
          [`.MuiSwitch-colorError:hover`]: {
            backgroundColor: `${colors.redRibbon[100]}80`,
          },
          [`.MuiSwitch-colorWarning:hover`]: {
            backgroundColor: `${colors.amber[100]}80`,
          },
          [`.MuiSwitch-colorInfo:hover`]: {
            backgroundColor: `${colors.purpleHeart[100]}80`,
          },
          [`.MuiSwitch-colorSuccess:hover`]: {
            backgroundColor: `${colors.apple[100]}80`,
          },
        },
        [`.${switchClasses.disabled}`]: {
          [`.${switchClasses.thumb}`]: {
            backgroundColor: `${colors.white[400]}!important`,
          },
          [`&+.${switchClasses.track}`]: {
            opacity: "1!important",
            backgroundColor: `${colors.white[950]}!important`,
          },
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        [`& .${formControlLabelClasses.label}.${formControlLabelClasses.disabled}`]: {
          color: colors.white[950],
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        fontSize: "16px",
        color: colors.brightGray[700],
        borderColor: colors.white[950],

        [`&:has(.${listItemClasses.disabled})`]: {
          color: colors.white[950],
        },
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        ["&:hover"]: {
          backgroundColor: `${colors.white[200]}80`,
        },
        [`&.${listItemButtonClasses.selected}`]: {
          backgroundColor: `${colors.brightGray[300]}80`,
        },
        [`&.${listItemButtonClasses.selected}:hover`]: {
          backgroundColor: `${colors.brightGray[400]}80`,
        },
        [`&.${listItemButtonClasses.disabled}`]: {
          opacity: 1,
          color: colors.white[950],
        },
        [`&.${listItemButtonClasses.disabled}.${listItemButtonClasses.selected}`]: {
          backgroundColor: `${colors.brightGray[200]}80`,
        },

        variants: [
          {
            props: { usage: "transfer-list" },
            style: {
              [`&.${listItemButtonClasses.selected}`]: {
                backgroundColor: `${colors.dodgeBlue[100]}80`,
              },
              [`&.${listItemButtonClasses.selected}:hover`]: {
                backgroundColor: `${colors.dodgeBlue[100]}`,
              },
            }
          },{
            props: { usage: "transfer-list", recentlyMoved: true },
            style: {
              ["&"]: {
                backgroundColor: colors.white[100]
              }
            }
          }
        ]
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        [`.${listItemTextClasses.secondary}`]: {
          color: colors.brightGray[600],
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: "inherit",
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: { color: "primary" },
            style: {
              [`&.${avatarClasses.colorDefault}`]: {
                backgroundColor: colors.punch[600],
              },
            },
          },
          {
            props: { color: "secondary" },
            style: {
              [`&.${avatarClasses.colorDefault}`]: {
                backgroundColor: colors.dodgeBlue[600],
              },
            },
          },
          {
            props: { color: "error" },
            style: {
              [`&.${avatarClasses.colorDefault}`]: {
                backgroundColor: colors.redRibbon[800],
              },
            },
          },
          {
            props: { color: "warning" },
            style: {
              [`&.${avatarClasses.colorDefault}`]: {
                backgroundColor: colors.amber[700],
              },
            },
          },
          {
            props: { color: "info" },
            style: {
              [`&.${avatarClasses.colorDefault}`]: {
                backgroundColor: colors.purpleHeart[700],
              },
            },
          },
          {
            props: { color: "success" },
            style: {
              [`&.${avatarClasses.colorDefault}`]: {
                backgroundColor: colors.apple[700],
              },
            },
          },
          {
            props: { color: "default" },
            style: {
              [`&.${avatarClasses.colorDefault}`]: {
                backgroundColor: colors.brightGray[500],
              },
            },
          },
          {
            props: { size: "large" },
            style: {
              width: "40px",
              height: "40px",
            },
          },
          {
            props: { size: "medium" },
            style: {
              width: "32px",
              height: "32px",
            },
          },
          {
            props: { size: "small" },
            style: {
              width: "24px",
              height: "24px",
              fontSize: "12px",
              lineHeight: "12px",
              [`& .material-symbols-rounded`]: {
                fontSize: "20px",
              },
            },
          },
          {
            props: { size: "xs" },
            style: {
              width: "18px",
              height: "18px",
              fontSize: "10px",
              lineHeight: "10px",
              [`& .material-symbols-rounded`]: {
                fontSize: "18px",
              },
            },
          },
        ],
        [`&.${avatarClasses.colorDefault}`]: {
          backgroundColor: colors.brightGray[500],
        },
      },
    },
  },
  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
      color: "secondary",
    },
    styleOverrides: {
      root: {
        variants: [
          {
            props: { color: "secondary" },
            style: {
              ["&:hover"]: {
                color: colors.dodgeBlue[600],
              },
            },
          },
        ],
        ["&:visited"]: {
          color: colors.purpleHeart[800],
          textDecorationColor: colors.purpleHeart[800],
        },
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        [`& .${linkClasses.root}`]: {
          color: colors.brightGray[500],
          [`&:has(.material-symbols-rounded)`]: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
          },
        },
        [`& .${linkClasses.root}:visited`]: {
          color: colors.brightGray[500],
        },
        [`.${breadcrumbsClasses.li}:last-of-type .${linkClasses.root}`]: {
          color: colors.brightGray[900],
        },
        [`& .${breadcrumbsClasses.separator}`]: {
          color: colors.brightGray[500],
        },
        [`& li button.${buttonBaseClasses.root}`]: {
          backgroundColor: "transparent",
          color: "inherit",
        },
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: `${colors.brightGray[950]}80`,
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: { variant: "filled" },
            style: {
              color: colors.brightGray[50],
            },
          },
          {
            props: { variant: "outlined", severity: "error" },
            style: {
              [`& .${alertClasses.message}`]: {
                color: colors.redRibbon[800],
              },
              [`& .${alertClasses.action}`]: {
                color: colors.redRibbon[700],
              },
              borderColor: colors.redRibbon[700],
            },
          },
          {
            props: { variant: "outlined", severity: "warning" },
            style: {
              [`& .${alertClasses.message}`]: {
                color: colors.amber[800],
              },
              [`& .${alertClasses.action}`]: {
                color: colors.amber[600],
              },
              borderColor: colors.amber[600],
            },
          },
          {
            props: { variant: "outlined", severity: "info" },
            style: {
              [`& .${alertClasses.message}`]: {
                color: colors.purpleHeart[700],
              },
              [`& .${alertClasses.action}`]: {
                color: colors.purpleHeart[600],
              },
              borderColor: colors.purpleHeart[600],
            },
          },
          {
            props: { variant: "outlined", severity: "success" },
            style: {
              [`& .${alertClasses.message}`]: {
                color: colors.apple[800],
              },
              [`& .${alertClasses.action}`]: {
                color: colors.apple[600],
              },
              borderColor: colors.apple[600],
            },
          },
          {
            props: { variant: "standard", severity: "error" },
            style: {
              [`& .${alertClasses.message}`]: {
                color: colors.redRibbon[800],
              },
              [`& .${alertClasses.action}`]: {
                color: colors.redRibbon[700],
              },
              backgroundColor: colors.redRibbon[50],
            },
          },
          {
            props: { variant: "standard", severity: "warning" },
            style: {
              [`& .${alertClasses.message}`]: {
                color: colors.amber[800],
              },
              [`& .${alertClasses.action}`]: {
                color: colors.amber[600],
              },
              backgroundColor: colors.amber[50],
            },
          },
          {
            props: { variant: "standard", severity: "info" },
            style: {
              [`& .${alertClasses.message}`]: {
                color: colors.purpleHeart[700],
              },
              [`& .${alertClasses.action}`]: {
                color: colors.purpleHeart[600],
              },
              backgroundColor: colors.purpleHeart[50],
            },
          },
          {
            props: { variant: "standard", severity: "success" },
            style: {
              [`& .${alertClasses.message}`]: {
                color: colors.apple[800],
              },
              [`& .${alertClasses.action}`]: {
                color: colors.apple[600],
              },
              backgroundColor: colors.apple[50],
            },
          },
        ],
        [`.${alertTitleClasses.root}`]: {
          marginBottom: "4px",
          
          [`&.${typographyClasses.subtitle2}`]: {
            fontWeight: 600
          }
        },
      },
    },
  },
  MuiRating: {
    styleOverrides: {
      root: {
        color: colors.amber[400],
        [`& .${ratingClasses.iconHover}`]: {
          marginTop: "3px",
        },
        [`& .${ratingClasses.iconEmpty}`]: {
          color: colors.brightGray[300],
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        [`& .${accordionClasses.heading}`]: {
          color: colors.brightGray[700],
        },
        [`&.${accordionClasses.disabled}`]: {
          color: colors.white[950],
          backgroundColor: colors.white[100],
        },
        ["&::before"]: {
          backgroundColor: colors.brightGray[600],
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      expandIconWrapper: {
        color: colors.brightGray[700],
      },
    },
  },
  MuiPagination: {
    defaultProps: {
      showFirstButton: true,
      showLastButton: true
    }
  },
  MuiPaginationItem: {
    defaultProps: {
      slots: {
        first: SkipPreviousIcon,
        last: SkipNextIcon
      }
    },
    styleOverrides: {
      root: {
        fontFamily: "Source Sans Pro",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",

        [`&.${paginationItemClasses.text}.${paginationItemClasses.selected}`]: {
          [`&:not(.${paginationItemClasses.colorPrimary}, .${paginationItemClasses.colorSecondary})`]:
            {
              backgroundColor: colors.brightGray[400],
              color: colors.brightGray[50],
              ["&:hover"]: {
                backgroundColor: colors.brightGray[600],
              },
            },
          [`&.${paginationItemClasses.colorSecondary}`]: {
            color: colors.brightGray[50],
          },
          [`&.${paginationItemClasses.disabled}`]: {
            backgroundColor: "transparent",
            color: colors.brightGray[600],
            opacity: 0.38,
          },
        },
        [`&.${paginationItemClasses.outlined}`]: {
          borderColor: colors.brightGray[400],
          [`&.${paginationItemClasses.selected}`]: {
            [`&:not(.${paginationItemClasses.colorPrimary}, .${paginationItemClasses.colorSecondary})`]: {
              backgroundColor: colors.brightGray[200],
              ["&:hover"]: {
                backgroundColor: colors.brightGray[300],
              },
            },
            [`&.${paginationItemClasses.colorPrimary}`]: {
              color: colors.punch[500],
              borderColor: colors.punch[500],
            },
            [`&.${paginationItemClasses.colorSecondary}`]: {
              color: colors.dodgeBlue[600],
              borderColor: colors.dodgeBlue[600],
            },
            [`&.${paginationItemClasses.disabled}`]: {
              color: colors.brightGray[600],
              borderColor: colors.brightGray[400],
              backgroundColor: "transparent",
              opacity: 0.38,
            },
          },
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontFamily: "Source Sans Pro",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "157%",
        letterSpacing: "0.1px",

        ["@media (min-width: 600px)"]: {
          ["&:not(.MuiMenuItem-dense)"]: {
            minHeight: "48px",
          }
        },

        ["&:hover"]: {
          backgroundColor: `${colors.white[300]}80`
        },
        [`&.${menuItemClasses.selected}`]: {
          backgroundColor: `${colors.dodgeBlue[100]}80!important`,
          ["&:hover"]: {
            backgroundColor: `${colors.dodgeBlue[100]}!important`,
          }
        },
        [`&.${menuItemClasses.divider}`]: {
          borderBottom: `1px solid ${colors.brightGray[600]}80` 
        }
      }
    }
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        [`& .${formLabelClasses.root}`]: {
          fontWeight: 600,

          [`&:not(.${formLabelClasses.focused})`]: {
            color: colors.brightGray[600],
          },

          [`&.${formLabelClasses.disabled}`]: {
            color: colors.white[950]
          }
        },
        [`& .${formHelperTextClasses.root}`]: {
          color: colors.brightGray[600]
        }
      }
    }
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        [`&.${tableRowClasses.hover}:hover`]: {
          backgroundColor: `${colors.white[100]}80`
        },
        [`&.${tableRowClasses.selected}`]: {
          backgroundColor: `${colors.dodgeBlue[100]}80`,

          [`&.${tableRowClasses.hover}:hover`]: {
            backgroundColor: `${colors.dodgeBlue[100]}BF`
          }
        }
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        color: colors.brightGray[800],
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "175%",
        letterSpacing: "0.15px",
      }
    }
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        ["p"]: {
          marginBottom: 0
        },
        [`& .${tablePaginationClasses.input}`]: {
          fontSize: "16px"
        }
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        [`& .MuiInput-root .MuiInput-input`]: {
          padding: "4px 0 7px"
        }
      },
      listbox: {
        [`& .${autocompleteClasses.option}`]: {
          ["&[aria-selected='true']"]: {
            backgroundColor: `${colors.dodgeBlue[100]}80`,

            [`&.${autocompleteClasses.focused}`]: {
              backgroundColor: colors.dodgeBlue[100]
            },

            ["&:hover"]: {
              backgroundColor: colors.dodgeBlue[100]
            }
          },
          
          ["@media (min-width: 600px)"]: {
            minHeight: "48px",
          }
        }
      }
    }
  }
};