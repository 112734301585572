import React from "react";
import Input from "components/form/NewLanding/Input";

function Name(props) {
  const prefix = ((typeof props.prefix) === 'string') ? 
    {id: props.prefix, name: props.prefix}  : 
      props.prefix || {
        id: "name",
        name: "name",
      };

  return (
    <Input
      {...props}
      placeholder="Nome do Gestor"
      name={prefix.name}
      id={prefix.id}
    />
  );
}

export default Name;