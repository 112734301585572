import React from "react";
import { SelectInput } from "components/form";
import { useStaticCollection } from "utils/hooks";

export default (props) => {
  const prefix = props.prefix || {
    id: "rejection_cause",
    name: "rejection_cause",
  };

  const collection = useStaticCollection(`/selects/envelope_rejection_causes.json`);

  return (
    <div className="form-group">
      <SelectInput
        {...props}
        required
        placeholder="Selecione a causa da rejeição..."
        name={prefix.name}
        id={prefix.id}
        collection={collection}
        label={props.label || "Causa da rejeição"}
      />
    </div>
  );
};
