import React from 'react';
import styled from 'styled-components';

const Label = styled.span`
  font-size: 12px;
  font-weight: bolder;
  color: white;
  text-align: center;
  padding: 2px 6px;
  border-radius: 3px;
  margin-bottom: 0;
  background-color: #00e7c0;
  display: block;
  width: fit-content;
  height: fit-content;
`

export default ({children}) => (
  <Label className="label">{children}</Label>
)