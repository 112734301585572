import React from "react";
import { BasePassword as Input } from "components/form";

function PasswordConfirmation(props) {
  const prefix = props.prefix || {
    id: "password_confirmation",
    name: "password_confirmation",
  };

  return (
    <Input
      {...props}
      id={prefix.id}
      name={prefix.name}
      label="Confirme sua senha"
    />
  );
}

export default PasswordConfirmation;
