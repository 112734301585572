import React, { useState, useEffect } from "react";
import IdAndDestroyFields from "components/users/fields/shared/IdAndDestroyFields";
import Company from "./Company";
import CurrentJob from "./CurrentJob";
import Description from "./Description";
import EndDate from "./EndDate";
import Job from "./Job";
import Level from "./Level";
import StartDate from "./StartDate";

function ExperienceAttributes(props) {
  const { item, index } = props;

  const [disabled, setDisabled] = useState(false);
  const [destroy, setDestroy] = useState(false);

  const removeFromList = () => {
    setDestroy(true);
  };

  useEffect(() => {
    if (destroy) props.destroyItem(item.id, index);
  }, [destroy]);

  const handleCurrentJob = (value) => {
    setDisabled(value);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <br />
        <h4>{`Experiência #${index + 1}`}</h4>
      </div>
      <div className="col-md-4">
        <Company {...props} />
      </div>
      <div className="col-md-4">
        <Job {...props} />
      </div>
      <div className="col-md-4">
        <Level {...props} />
      </div>
      <div className="col-md-12">
        <Description {...props} />
      </div>
      <div className="col-md-4" style={{ display: "flex" }}>
        <CurrentJob {...props} handleChange={handleCurrentJob} />
      </div>
      <div className="col-md-4">
        <StartDate {...props} />
      </div>
      <div className="col-md-4">
        <EndDate {...props} disabled={disabled} />
      </div>
      <div className="col-md-1 col-button">
        <button
          type="button"
          className="button-delete"
          onClick={removeFromList}
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
      <IdAndDestroyFields {...props} destroy={destroy} />
    </div>
  );
}

export default React.memo(ExperienceAttributes);
