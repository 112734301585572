import React from "react";
import styled from "styled-components";

const ContentBoxHeaderButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .button {
    width: 180px;
    background: rgba(81, 242, 126, 1.0);
    margin-bottom: 10px;
  }

  .button-started {
    background: rgba(81, 242, 126, 1.0);
  }
  .button-closed {
    background: rgba(81, 242, 126, 0.5);
  }

  .button-secondary {
    background: rgba(0, 0, 0, 0.5);
  }
  
  .button-program {
    background: #3aafe6;
  }
  
  @media screen and (max-width: 767px) {
    & {
      width: 100%;
      height: 141px;
      position: fixed;
      bottom: 0;
      padding: 20px 30px;
      background-color: ${({theme}) => theme.mode === "light" ? "#fff" : theme.background.paper};
      background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
      z-index: 2;
      align-items: center;

      .button {
        width: 80%;
      }
    }
  }
`;

export default (props) => (
  <ContentBoxHeaderButtons className="content-box-header-buttons">{props.children}</ContentBoxHeaderButtons>
);
