import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "./styles";
import { pathWithParams } from 'utils/pathWithParams';
import PATHS from "navigation/ROUTES";

export default ({ user, toggleDrawer }) => (
  <Menu>
    <li>
      <Link to={PATHS.USERS.HOME} onClick={toggleDrawer}>
        <i className="material-icons">insert_chart</i> Início
      </Link>
    </li>
    <li>
      <Link to={pathWithParams(PATHS.USERS.SHOW, {id: user?.slug})} onClick={toggleDrawer}>
        <i className="material-icons">assignment_ind</i> Meu cadastro
      </Link>
    </li>
    <li>
      <Link to={PATHS.USERS.WIZARD} onClick={toggleDrawer}>
        <i className="material-icons">edit</i> Editar perfil
      </Link>
    </li>
    <li>
      <Link to={PATHS.BENEFITS.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">card_giftcard</i> Clube de Benefícios
      </Link>
    </li>
    <li>
      <Link to={PATHS.CAREER_MENTORS.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">school</i> Mentores WallJobs
      </Link>
    </li>
    <li>
      <Link to={PATHS.INTERNSHIP.ENVELOPES.OWNED} onClick={toggleDrawer}>
        <i className="material-icons">insert_drive_file</i> Meus Contratos
      </Link>
    </li>
    <li>
      <Link to={PATHS.PERSONAL_DOCUMENTS.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">document_scanner</i> Documentos Pessoais
      </Link>
    </li>
    <li>
      <Link to={PATHS.USERS.CERTIFICATES.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">more_time</i> Horas Complementares
      </Link>
    </li>
    <li>
      <Link to={PATHS.TESTS.INDEX} onClick={toggleDrawer}>
        <i className="material-icons">quiz</i> Testes de habilidade
      </Link>
    </li>
    <li>
      <Link
        to={PATHS.INTERNSHIP.COMPANY_EVALUATION.INDEX}
        onClick={toggleDrawer}
      >
        <i className="material-icons">domain</i> Avaliar estágio/empresa
      </Link>
    </li>
    <li>
      <Link to={PATHS.USERS.SETTINGS} onClick={toggleDrawer}>
        <i className="material-icons">settings</i> Configurações
      </Link>
    </li>
  </Menu>
);
