import _ from "lodash";
import { emptyValue } from "utils/emptyValue";

export function pathWithParams(path, params) {
  if(_.isEmpty(params)) return path;

  return formatPath(path, params);
}

const formatPath = (path, params) => {
  if(path.indexOf(":") !== -1){
    path.match(/:([0-9_a-z]+)/gi).forEach((match)=>{
      var key = match.replace(":", "");
      path = path.replace(match, params[key]);
      params = _.omit(params, key);
    })
  }

  let keys = _.keys(_.pickBy(params, (value, key) => !emptyValue(value)));

  if(!_.isEmpty(keys)){
    keys.forEach((key, index) => {     
      let separator = index == 0 ? '?' : '&';
      path = `${path}${separator}${key}=${params[key]}`
    })
  }

  return path;
}