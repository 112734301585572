import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 100px;
  border: none;
  border-radius: 60px;
  width: 60px;
  height: 60px;
  z-index: 1000;
  font-weight: 500;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
  rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  background-color: #c13950;
  color: white;
  outline: none;

  &:focus{
    outline: none;
  }
  
  @media screen and (max-width: 767px) {
    width: 50px;
    height: 50px;
    bottom: 10px;
    right: 80px;
  }
`

export default () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(window.pageYOffset > 300)
  }

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, [])

  return isVisible && (
    <Button onClick={scrollToTop} title="Topo">
      <i className="material-icons">keyboard_arrow_up</i>
    </Button>
  );
}