import React from "react";
import styled from "styled-components";
import { EditModeContext } from "./context";

const EditIconButton = styled.button`
  opacity: 0.6;
  color: inherit;
  background-color: transparent;
  border: none;
  text-decoration: none !important;
  outline: none;
  cursor: pointer;
  float: right;

  & span {
    font-size: inherit;
  }

  &:hover {
    opacity: 1;
  }
`;

export default (props) => {
  const { type, attributes } = props;
  const EditMode = React.useContext(EditModeContext);

  const handleClick = () => {
    EditMode.handleEditModal({ type, attributes });
  };

  if(!EditMode.enabled){
    return;
  }

  return (
    <EditIconButton onClick={handleClick}>
      <span className="material-icons">edit</span>
    </EditIconButton>
  );
};
