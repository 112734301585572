import React, { useState } from "react";
import Zipcode from "./Zipcode";
import Street from "./Street";
import Neighborhood from "./Neighborhood";
import Number from "./Number";
import Complement from "./Complement";
import StateAndCity from "./StateAndCity";
import Country from "./Country";
import { HiddenField } from "components/form";
import zipcodeSearch from "utils/zipcodeSearch";

function Address(props) {
  const [address, setAddress] = useState({});

  const getAddress = (event) => {
    const cep = event.target.value;
    if (cep) zipcodeSearch(cep).then(setAddress);
  };

  return (
    <div className="row">
      <AddressId {...props} />
      <div className="col-md-3">
        <Zipcode {...props} onBlur={getAddress} />
      </div>
      <div className="col-md-5">
        <Street address={address} {...props} />
      </div>
      <div className="col-md-4">
        <Neighborhood address={address} {...props} />
      </div>
      <div className="col-md-1">
        <Number {...props} />
      </div>
      <div className="col-md-2">
        <Complement {...props} required={false} />
      </div>
      <StateAndCity address={address} {...props} />
      <div className="col-md-3">
        <Country address={address} {...props} />
      </div>
    </div>
  );
}

function AddressId(props) {
  const prefix = props.prefix || {
    name: "address_attributes[id]",
    id: "address_attributes_id",
  };

  return <HiddenField {...props} name={prefix.name} id={prefix.id} />;
}

export default Address;
