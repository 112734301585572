import React from 'react';
import { SelectInput } from 'components/form';

function LanguageId(props) {
  const { prefix, index } = props;
  return (
    <SelectInput
      {...props}
      placeholder="Selecione o idioma..."
      name={`${prefix}[${index}][language_id]`}
      id={`${prefix}_${index}_language_id`}
      collection={props.languages}
      label={props.label || "Idioma"}
    />
  );
}

export default LanguageId;