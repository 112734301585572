import api from "services/api";
import _ from "lodash";

export const getCollection = (request, setCollection) => {
  api
    .get(request)
    .then((resp) => {
      let list = resp.data;
      if (!_.isEmpty(list)) setCollection(list);
    })
    .catch((error) => {
      console.log(error.response);
    });
};