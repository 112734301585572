import React from "react";
import { Link } from "react-router-dom";
import JobLabel from "components/ats/JobLabel";
import IesExclusivitySeal from "components/ats/IesExclusivitySeal";
import JobProgramExclusivity from "components/internship_program/JobProgramExclusivity"
import { pathWithParams } from "utils/pathWithParams";
import { ATS } from "navigation/ROUTES";
import {
  CardBox,
  CardBoxFooter,
  CardBoxImage,
  CardBoxInfo,
} from "components/ui";

function Job(props) {
  const job = props.item.attributes || props.item;

  if (!job) {
    return;
  }

  return (
    <CardBox disabled={!job.available}>
      <IesExclusivitySeal job={job} />
      <JobProgramExclusivity job={job} />
      <Link
        to={pathWithParams(ATS.JOBS.SHOW, { id: job.slug })}
        className="card-box-body"
      >
        <CardBoxImage image={job.avatar} />
        <CardBoxInfo title={job.title} subtitle={job.author_name}>
          <JobLabel job={job} />
          <CardBoxFooter>
            <i className="material-icons">place</i> {job.address}
          </CardBoxFooter>
        </CardBoxInfo>
        {props.children}
      </Link>
    </CardBox>
  );
}

export default React.memo(Job);
